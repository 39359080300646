<template>
  <div>
    <b-card>
      <div slot="header">
        <i class='fa fa-align-justify'></i><strong class='header-spacing'>Voucher Info</strong>
      </div>
      <p><strong>Code</strong>: {{ order.coupon.id }}</p>
      <p><strong>Percentage</strong>: {{ order.coupon.percentage * 100 }}%</p>
      <p><strong>Expiration date</strong>: {{ expirationDate(order.coupon.expiration) }}</p>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "voucher-info",
  props: ['order'],
  methods: {
    expirationDate: function (dateString) {
      return moment(dateString).local().format('Do MMMM YYYY');
    },
  }
}
</script>
