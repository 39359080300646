<template>
  <b-card>

    <div slot="header">
      <i class='fa fa-align-justify'></i> Packages
    </div>

    <b-row class="form-group">
      <b-col sm="12" md="6">
        <b-pagination align="left" size="sm" :total-rows="packages.totalElements" v-model="currentPage" :per-page="perPage"></b-pagination>
      </b-col>
    </b-row>

    <b-table :items="packages.content" :fields="columnNames" :current-page="1" :per-page="perPage" hover outlined show-empty responsive>
      
      <template v-slot:cell(code)="row">
        <span>
          <b-button v-if="!row.item.toBeUpdated" style="width: 10em;" variant="dark" @click="copyToClipboard(row.value)" v-b-tooltip.hover title="Copy Package Code">
            {{row.value}} <i class="fa fa-copy fa-copy-hover"></i>
          </b-button>
          <input data-vv-scope="editPkg" v-if="row.item.toBeUpdated" v-validate="{required: true }" v-model="row.item.code" v-bind:name="'code' + row.item.id" type="text"/>
          <span class="red-text"><br/>{{ errors.first('editPkg.code' + row.item.id) }}</span>
        </span>
      </template>

      <template v-slot:cell(name)="row">
        <span style="display: inline-block; max-width: 250px;" v-if="!row.item.toBeUpdated">{{row.value}}</span>
        <input data-vv-scope="editPkg" v-if="row.item.toBeUpdated" v-validate="{ required: true }" v-model="row.item.name" v-bind:name="'name' + row.item.id" type="text"/>
        <span class="red-text"><br/>{{ errors.first('editPkg.name' + row.item.id) }}</span>
      </template>


      <template v-slot:cell(charge)="row">
        <span v-if="!row.item.toBeUpdated">{{row.value}}</span>
        <input data-vv-scope="editPkg" v-if="row.item.toBeUpdated" v-validate="{ required: true, min_value: 0 }" v-model="row.item.charge" v-bind:name="'charge' + row.item.id" v-on:keypress="preventNumberExponential($event)" type="number" min="0"/>
        <span class="red-text"><br/>{{ errors.first('editPkg.charge' + row.item.id) }}</span>
      </template>

      <template v-slot:cell(vat)="row">
        <span v-if="!row.item.toBeUpdated">{{row.value}}</span>
        <input data-vv-scope="editPkg" v-if="row.item.toBeUpdated" v-validate="{ required: true, min_value: 0 }" v-model="row.item.vat" v-bind:name="'vat' + row.item.id" v-on:keypress="preventNumberExponential($event)" type="number" min="0"/>
        <span class="red-text"><br/>{{ errors.first('editPkg.vat' + row.item.id) }}</span>
      </template>

      <template v-slot:cell(usages)="row">
        <span v-if="!row.item.toBeUpdated">{{row.value}}</span>
        <input data-vv-scope="editPkg" v-if="row.item.toBeUpdated" v-validate="{ required: true, min_value: 1 }" v-model="row.item.usages" v-bind:name="'usages' + row.item.id" v-on:keypress="preventNumberExponential($event)" type="number" min="0"/>
        <span class="red-text"><br/>{{ errors.first('editPkg.usages' + row.item.id) }}</span>
      </template>

      <template v-slot:cell(quota)="row">
        <span v-if="!row.item.toBeUpdated">{{row.value}}</span>
        <input data-vv-scope="editPkg" v-if="row.item.toBeUpdated" v-validate="{ required: true, min_value: 1 }" v-model="row.item.quota" v-bind:name="'quota' + row.item.id" v-on:keypress="preventNumberExponential($event)" type="number" min="0"/>
        <span class="red-text"><br/>{{ errors.first('editPkg.quota' + row.item.id) }}</span>
      </template>


      <template v-slot:cell(months)="row">
        <span v-if="!row.item.toBeUpdated">{{row.value}}</span>
        <input data-vv-scope="editPkg" v-if="row.item.toBeUpdated" v-validate="{ required: true, min_value: 1 }" v-model="row.item.months" v-bind:name="'months' + row.item.id" v-on:keypress="preventNumberExponential($event)" type="number" min="0"/>
        <span class="red-text"><br/>{{ errors.first('editPkg.months' + row.item.id) }}</span>
      </template>

      <template v-slot:head(actions)>
        <b-button variant="primary" @click="openAddPackagesModal()">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Add&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </b-button>
      </template>
      
      <template v-slot:cell(actions)="data">
        <b-button v-if="!data.item.toBeUpdated" variant="primary" @click="editItem(data.item)">
          Update
        </b-button>
        <b-button v-if="data.item.toBeUpdated" variant="warning" @click="confirmPackageUpdate(data.item)">
          Confirm
        </b-button>
        <b-button v-if="data.item._rowVariant || data.item.toBeUpdated" variant="danger" @click="resetToOriginalState(data.item)">
          &nbsp;Reset
        </b-button>
        <b-button v-if="data.item.active && !data.item._rowVariant && !data.item.toBeUpdated" variant="danger" @click="openDisablePackageModal(data.item)">
          Disable
        </b-button>
        <b-button v-if="!data.item.active && !data.item._rowVariant && !data.item.toBeUpdated" variant="primary" @click="openEnablePackageModal(data.item)">
          Enable
        </b-button>
      </template>
    
    </b-table>

    <b-row class="form-group">
      <b-col sm="12" md="6">
        <b-pagination align="left" size="sm" :total-rows="packages.totalElements" v-model="currentPage" :per-page="perPage"></b-pagination>
      </b-col>
      <b-col sm="12" md="4" offset-md="2">
        <b-button variant="primary" block @click="openupdatePackagesModal()" :disabled="packagesToUpdate.length < 1"> Confirm </b-button>
      </b-col>
    </b-row>

    <br>

    <b-modal v-model="addPackagesModal" centered size="lg" hide-header ok-title="Add Package" ok-variant="primary" @ok="confirmAllChanges(true)">
      <div class="table-responsive reduced-margin-table">
        <table class="table table-striped table-nowrap">
          <thead>
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th>Charge</th>
              <th>VAT</th>
              <th>Usages</th>
              <th>Months</th>
              <th>Quota</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(pkg, index) in packagesToAdd" :key="index">
              <td>
                <input data-vv-scope="addPkg" type="text" v-validate="{ required: true }" v-model="pkg.code" v-bind:name="'new_code' + index"/>
                <span class="red-text"><br/>{{ errors.first('addPkg.code' + index) }}</span>
              </td>
              <td>
                <input data-vv-scope="addPkg" type="text" v-validate="{ required: true }" v-model="pkg.name" v-bind:name="'new_name' + index"/>
                <span class="red-text"><br/>{{ errors.first('addPkg.name' + index) }}</span>
              </td>
              <td>
                <input data-vv-scope="addPkg" class="small-input" v-validate="{ required: true, min_value: 1 }" v-model="pkg.charge" v-bind:name="'new_charge' + index" v-on:keypress="preventNumberExponential($event)" type="number" min="0"/>
                <span class="red-text"><br/>{{ errors.first('addPkg.charge' + index) }}</span>
              </td>
               <td>
                <input data-vv-scope="addPkg" class="small-input" v-validate="{ required: true, min_value: 1 }" v-model="pkg.vat" v-bind:name="'new_vat' + index" v-on:keypress="preventNumberExponential($event)" type="number" min="0"/>
                <span class="red-text"><br/>{{ errors.first('addPkg.vat' + index) }}</span>
              </td>
              <td>
                <input data-vv-scope="addPkg" class="small-input" v-validate="{ required: true, min_value: 1 }" v-model="pkg.usages" v-bind:name="'new_usages' + index" v-on:keypress="preventNumberExponential($event)" type="number" min="0"/>
                <span class="red-text"><br/>{{ errors.first('addPkg.usages' + index) }}</span>
              </td>
              <td>
                <input data-vv-scope="addPkg" class="small-input" v-validate="{ required: true, min_value: 1 }" v-model="pkg.months" v-bind:name="'new_months' + index" v-on:keypress="preventNumberExponential($event)" type="number" min="0"/>
                <span class="red-text"><br/>{{ errors.first('addPkg.months' + index) }}</span>
              </td>
              <td>
                <input data-vv-scope="addPkg" class="small-input" v-validate="{ required: true, min_value: 1 }" v-model="pkg.quota" v-bind:name="'new_quota' + index" v-on:keypress="preventNumberExponential($event)" type="number" min="0"/>
                <span class="red-text"><br/>{{ errors.first('addPkg.quota' + index) }}</span>
              </td>
              <td><i class="huge-icon danger fa fa-times" @click="removePackage(pkg)"></i></td>
            </tr>
          </tbody>
          <tfoot align="center">
            <tr><td colspan="5">
             <i class="huge-icon success fa fa-plus-circle" @click="addPackage()"></i>
            </td></tr>
          </tfoot>
        </table>
      </div>
    </b-modal>
  
    <b-modal v-model="disablePackageModal" hide-footer centered size="md" title="Confirm Disable">
      <div>
        <b-row>
          <b-col cols="5"><strong><label label-for="packageId">Id</label></strong></b-col>
          <b-col><span name="packageId">{{packageToDisable.id}}</span></b-col>
        </b-row>
        <b-row>
          <b-col cols="5"><strong><label label-for="packageCode">Code</label></strong></b-col>
          <b-col><span name="packageCode">{{packageToDisable.code}}</span></b-col>
        </b-row>
        <b-row>
          <b-col cols="5"><strong><label label-for="packageName">Name</label></strong></b-col>
          <b-col><span name="packageName">{{packageToDisable.name}}</span></b-col>
        </b-row>
        <b-row>
          <b-col cols="5"><strong><label label-for="packageCharge">Charge</label></strong></b-col>
          <b-col><span name="packageCharge">{{packageToDisable.charge}}</span></b-col>
        </b-row>
        <b-row>
          <b-col cols="5"><strong><label label-for="packageVat">VAT</label></strong></b-col>
          <b-col><span name="packageVat">{{packageToDisable.vat}}</span></b-col>
        </b-row>
        <b-row>
          <b-col cols="5"><strong><label label-for="packageUsages">Usages</label></strong></b-col>
          <b-col><span name="packageUsages">{{packageToDisable.usages}}</span></b-col>
        </b-row>
        <b-row>
          <b-col cols="5"><strong><label label-for="packageMonths">Months</label></strong></b-col>
          <b-col><span name="packageMonths">{{packageToDisable.months}}</span></b-col>
        </b-row>
        <b-row>
          <b-col cols="5"><strong><label label-for="packageQuota">Quota</label></strong></b-col>
          <b-col><span name="packageQuota">{{packageToDisable.quota}}</span></b-col>
        </b-row>
      </div>
      <b-alert variant="danger" show>
        <p><strong>Please note:</strong></p> This package will be disabled as soon as you click on the 'Disable Package' button.
      </b-alert>
      <hr/>
      <div>
        <b-button variant="danger" @click="confirmPackageDisable()">
          Disable Package
        </b-button>
        <b-button variant="secondary" @click="disablePackageModal = false">Cancel</b-button>
      </div>
    </b-modal>

    <b-modal v-model="enablePackageModal" hide-footer centered size="md" title="Confirm Enable">
      <div>
        <b-row>
          <b-col cols="5"><strong><label label-for="packageId">Id</label></strong></b-col>
          <b-col><span name="packageId">{{packageToEnable.id}}</span></b-col>
        </b-row>
        <b-row>
          <b-col cols="5"><strong><label label-for="packageCode">Code</label></strong></b-col>
          <b-col><span name="packageCode">{{packageToEnable.code}}</span></b-col>
        </b-row>
        <b-row>
          <b-col cols="5"><strong><label label-for="packageName">Name</label></strong></b-col>
          <b-col><span name="packageName">{{packageToEnable.name}}</span></b-col>
        </b-row>
        <b-row>
          <b-col cols="5"><strong><label label-for="packageCharge">Charge</label></strong></b-col>
          <b-col><span name="packageCharge">{{packageToEnable.charge}}</span></b-col>
        </b-row>
        <b-row>
          <b-col cols="5"><strong><label label-for="packageVat">VAT</label></strong></b-col>
          <b-col><span name="packageVat">{{packageToEnable.vat}}</span></b-col>
        </b-row>
        <b-row>
          <b-col cols="5"><strong><label label-for="packageUsages">Usages</label></strong></b-col>
          <b-col><span name="packageUsages">{{packageToEnable.usages}}</span></b-col>
        </b-row>
        <b-row>
          <b-col cols="5"><strong><label label-for="packageMonths">Months</label></strong></b-col>
          <b-col><span name="packageMonths">{{packageToEnable.months}}</span></b-col>
        </b-row>
        <b-row>
          <b-col cols="5"><strong><label label-for="packageQuota">Quota</label></strong></b-col>
          <b-col><span name="packageQuota">{{packageToEnable.quota}}</span></b-col>
        </b-row>
      </div>
      <b-alert variant="info" show>
        <p><strong>Please note:</strong></p> This package will be enabled as soon as you click on the 'Enable Package' button.
      </b-alert>
      <hr/>
      <div>
        <b-button variant="primary" @click="confirmPackageEnable()">
          Enable Package
        </b-button>
        <b-button variant="secondary" @click="enablePackageModal = false">Cancel</b-button>
      </div>
    </b-modal>

    <b-modal v-model="updatePackagesModal" centered size="lg" title="Confirm package change" ok-title="Confirm" ok-variant="primary" @ok="confirmAllChanges(false)">
      <div class="table-responsive">
        <table class="table table-striped table-nowrap">
          <thead>
            <tr>
              <th colspan="2">Code</th>
              <th colspan="2">Name</th>
              <th colspan="2">Charge</th>
              <th colspan="2">VAT</th>
              <th colspan="2">Usages</th>
              <th colspan="2">Months</th>
              <th colspan="2">Quota</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="pkg in packagesToUpdate" :key="pkg.id" @click="searchForThisPackage(pkg.id)">
              
              <td v-bind:class="{ 'isDifferentNew': isDifferent(pkg, 'code') }">{{ pkg.code }}</td>
              <td v-bind:class="{ 'isDifferentOld': isDifferent(pkg, 'code') }">{{ isDifferent(pkg, 'code') ? pkg.originalCode : ''}}</td>

              <td v-bind:class="{ 'isDifferentNew': isDifferent(pkg, 'name') }">{{ pkg.name }}</td>
              <td v-bind:class="{ 'isDifferentOld': isDifferent(pkg, 'name') }">{{ isDifferent(pkg, 'name') ? pkg.originalName : ''}}</td>

              <td v-bind:class="{ 'isDifferentNew': isDifferent(pkg, 'charge') }">{{ pkg.charge }}</td>
              <td v-bind:class="{ 'isDifferentOld': isDifferent(pkg, 'charge') }">{{ isDifferent(pkg, 'charge') ? pkg.originalCharge: ''}}</td>

              <td v-bind:class="{ 'isDifferentNew': isDifferent(pkg, 'vat') }">{{ pkg.vat }}</td>
              <td v-bind:class="{ 'isDifferentOld': isDifferent(pkg, 'vat') }">{{ isDifferent(pkg, 'vat') ? pkg.originalVat : ''}}</td>

              <td v-bind:class="{ 'isDifferentNew': isDifferent(pkg, 'usages') }">{{ pkg.usages }}</td>
              <td v-bind:class="{ 'isDifferentOld': isDifferent(pkg, 'usages') }">{{ isDifferent(pkg, 'usages') ? pkg.originalUsages : ''}}</td>

              <td v-bind:class="{ 'isDifferentNew': isDifferent(pkg, 'months') }">{{ pkg.months }}</td>
              <td v-bind:class="{ 'isDifferentOld': isDifferent(pkg, 'months') }">{{ isDifferent(pkg, 'months') ? pkg.originalMonths : ''}}</td>

              <td v-bind:class="{ 'isDifferentNew': isDifferent(pkg, 'quota') }">{{ pkg.quota }}</td>
              <td v-bind:class="{ 'isDifferentOld': isDifferent(pkg, 'quota') }">{{ isDifferent(pkg, 'quota') ? pkg.originalQuota : ''}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
    

  </b-card>
</template>

<script>

import moment from 'moment'

export default {
  components: { },
  data: function () {
    return {
      searchQuery: '',
      packages: [],
      columnNames: ['code', 'name',
        {key: 'charge', label: 'Charge (£)', formatter: 'currencyFormat'},
        {key: 'vat', label: 'VAT (£)', formatter: 'currencyFormat'},
        'usages',
        'months',
        'quota',
        'issued',
        'consumed',
        'actions'
      ],
      currentPage: 1,
      perPage: 10,
      packagesToAdd: [],
      packagesToUpdate: [],
      packageToDisable: {},
      packageToEnable: {},
      addPackagesModal: false,
      updatePackagesModal: false,
      disablePackageModal: false,
      enablePackageModal: false,
      lang: {
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
        placeholder: {
          date: 'Select Date',
          dateRange: 'Select Date Range'
        }
      }
    }
  },
  filters: {
    dateTime: function (date) {
      return moment(date).local().format('HH:mm - DD/MM/YY')
    }
  },
  created () {
    this.getPackages()
  },
  watch: {
    currentPage: function () {
      this.getPackages()
    }
  },
  methods: {
    getPackages () {
      this.$packageService.search(this.searchQuery, this.currentPage - 1, this.perPage).then(packages => {
        this.setupPackages(packages)
      }, error => {
        this.$awn.alert(error, 'An error occurred')
      })
    },
    setupPackages (packages) {
      packages.content.forEach(element => {
        this.$set(element, 'originalCode', element.code)
        this.$set(element, 'originalName', element.name)
        this.$set(element, 'originalCharge', element.charge)
        this.$set(element, 'originalVat', element.vat)
        this.$set(element, 'originalUsages', element.usages)
        this.$set(element, 'originalMonths', element.months)
        this.$set(element, 'originalQuota', element.quota)
        this.$set(element, 'originalActive', element.active)
        this.packagesToUpdate.forEach(pkg => {
          if (element.id === pkg.id) {
            element._rowVariant = 'warning'
            element.id = pkg.id
            element.code = pkg.code
            element.name = pkg.name
            element.charge = pkg.charge
            element.vat = pkg.vat
            element.usages = pkg.usages
            element.months = pkg.months
            element.quota = pkg.quota
            element.active = pkg.active
          }
        })
      })
      this.packages = packages
    },
    editItem (item) {
      this.$set(item, 'toBeUpdated', true)
    },
    resetToOriginalState (pkg) {
      pkg.code = pkg.originalCode
      pkg.name = pkg.originalName
      pkg.charge = pkg.originalCharge
      pkg.vat = pkg.originalVat
      pkg.usages = pkg.originalUsages
      pkg.months = pkg.originalMonths
      pkg.quota = pkg.originalQuota
      pkg.active = pkg.originalActive
      this.$delete(pkg, 'toBeUpdated')
      this.$delete(pkg, '_rowVariant')
      this.$delete(pkg, 'invalid')
      this.packagesToUpdate = this.packagesToUpdate.filter(element => element.id !== pkg.id)
    },
    confirmPackageUpdate (pkg) {
      this.$validator.validateAll("editPkg").then(result => {
        if (!result) {
          return
        }
        this.$delete(pkg, 'invalid')
        this.$delete(pkg, 'toBeUpdated')
        if (pkg.code !== pkg.originalCode ||
            pkg.originalName !== pkg.name ||
            pkg.charge !== pkg.originalCharge ||
            pkg.vat !== pkg.originalVat ||
            pkg.usages !== pkg.originalUsages ||
            pkg.months !== pkg.originalMonths ||
            pkg.quota !== pkg.originalQuota ||
            pkg.active !== pkg.originalActive) {
          if (this.packagesToUpdate.length === 0 || !this.packagesToUpdate.some(element => element.id === pkg.id)) {
            this.packagesToUpdate.push(pkg)
          }
          this.$set(pkg, '_rowVariant', 'warning')
        } else {
          this.$delete(pkg, '_rowVariant')
          this.packagesToUpdate = this.packagesToUpdate.filter(element => element.id !== pkg.id)
        }
      })
    },
    openDisablePackageModal (pkg) {
      this.packageToDisable = pkg
      this.disablePackageModal = true
    },
    confirmPackageDisable () {
      this.$packageService.disablePackage(this.packageToDisable.id).then((result) => {
        console.log(result)
        this.$awn.success('Package ' + this.packageToDisable.code + ' disabled successfully', 'Disable successful')
        this.getPackages()
      }, (error) => {
        this.$awn.alert(error, 'Error when disabling package')
      })
      this.disablePackageModal = false
    },
    openEnablePackageModal (pkg) {
      this.packageToEnable = pkg
      this.enablePackageModal = true
    },
    confirmPackageEnable () {
      this.$packageService.enablePackage(this.packageToEnable.id).then((result) => {
        console.log(result)
        this.$awn.success('Package ' + this.packageToEnable.code + ' enabled successfully', 'Enable successful')
        this.getPackages()
      }, (error) => {
        this.$awn.alert(error, 'Error when enabling package')
      })
      this.enablePackageModal = false
    },
    searchForThisPackage (packageId) {
      this.updatePackagesModal = false
      this.searchQuery = packageId
      this.getPackages()
    },
    preventNumberExponential (event) {
      var iKeyCode = (event.which) ? event.which : event.keyCode
      if (iKeyCode === 101 || iKeyCode === 43 || iKeyCode === 45) { event.preventDefault() }
    },
    preventNonNumbersOrLetters (event) {
      const pattern = /^[a-z0-9]+$/i
      let inputChar = String.fromCharCode(event.charCode)
      if (!pattern.test(inputChar)) {
        event.preventDefault()
      }
    },
    isDifferent (element, property) {
      let originalProperty = 'original' + property.replace(/^\w/, prop => prop.toUpperCase())
      if (element[property] !== element[originalProperty]) {
        if (element[property] instanceof moment && element[property].isSame(element[originalProperty])) {
          return false
        }
        return true
      }
      return false
    },
    openupdatePackagesModal () {
      this.updatePackagesModal = true
    },
    confirmAllChanges (create) {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return
        }
        if (create) {
          const finalList = this.packagesToAdd
          this.$packageService.addPackage(finalList).then(pkgs => {
            console.log(pkgs)
            this.$awn.success('Packages added', 'Packages added successfully')
            this.packagesToAdd = []
            this.addPackagesModal = false
            this.getPackages()
            if (finalList.length === 1) {
              this.searchQuery = finalList[0].id
            }
          }, error => {
            console.log(error)
            this.updatePackagesModal = false
            this.$awn.alert('Error', error)
            this.getPackages()
            this.currentPage = 1
          })
        } else {
          const finalList = this.packagesToUpdate

          this.$packageService.updatePackage(finalList).then(() => {
            this.$awn.success('Packages updated', 'Packages updated successfully')
            this.packagesToUpdate = []
            this.updatePackagesModal = false
            this.getPackages()
            this.currentPage = 1
          }, (error) => {
            this.addPackagesModal = false
            this.$awn.alert('Error', error)
            this.getPackages()
            this.currentPage = 1
          })
        }
      })
    },

    openAddPackagesModal () {
      this.addPackagesModal = true
      this.packagesToAdd = []
      this.addPackage()
    },
    addPackage () {
      // this.packagesToAdd.push({expirationDate: moment().add(2, 'days'), percentage: 100, usages: 1});
      this.packagesToAdd.push({id: '', code: '', name: '', charge: 1, vat: 1, usages: 1, quota: 10, months: 12})
      this.$validator.validateAll("addPkg")
    },
    removePackage (pkg) {
      this.packagesToAdd.pop(pkg)
    },
    copyToClipboard (text) {
      if (window.clipboardData && window.clipboardData.setData) {
        // IE specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData('Text', text)
      } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
        var textarea = document.createElement('textarea')
        textarea.textContent = text
        textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in MS Edge.
        document.body.appendChild(textarea)
        textarea.select()
        try {
          return document.execCommand('copy') // Security exception may be thrown by some browsers.
        } catch (ex) {
          console.warn('Copy to clipboard failed.', ex)
          return false
        } finally {
          document.body.removeChild(textarea)
        }
      }
    },
    currencyFormat (number) {
      return number.toFixed(2)
    }

  }
}
</script>

<style lang="scss">
.huge-icon {
  font-size:30px;
  cursor: pointer;
}
.success {
  color: #009344;
}
.success:hover {
  color: #006d32;
}
.danger {
  color: #f86c6b;
}
.danger:hover {
  color: #f64846;
}
.reduced-margin-table {
  margin-bottom: -20px;
  min-height: 20rem;
}
.table-nowrap th, .table-nowrap td {
  white-space: nowrap;
}
.mx-panel thead tr th, .mx-panel tbody tr td {
  padding: 0 !important;
}
.isDifferentNew {
  color: #009344;
  font-weight: bold;
}
.isDifferentOld {
  color: tomato;
  text-decoration: line-through; 
}
.mx-input { 
  max-height: 27px;
  border-radius: 0;
  color: #151b1e;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.mx-calendar-icon {
  height: 18px;
}
#percentage:invalid {
  color: #FFF;
  border-color: red;
  background-color: #f97776;
}
.red-text {
  color: red;
  font-size: small;
}
.small-input {
  width:70%;
}
.table th, .table td {
  vertical-align: middle;
}
</style>

