<template>
  <b-card>

    <div slot="header">
      <i class='fa fa-align-justify'></i> Subscriptions
    </div>

    <b-row class="form-group">
      <b-col sm="12" md="9">
        <b-pagination align="left" size="sm" :total-rows="subscriptions.totalElements" v-model="currentPage" :per-page="perPage"></b-pagination>
      </b-col>
      <b-col sm="12" md="3">
        <b-input-group size="md">
          <input type="search" class="form-control" id="searchQuery" v-model="searchQuery" @search="getSubscriptions()">
          <b-input-group-append>
            <b-button block variant="primary" class="btn--2" @click="getSubscriptions()"><i class="fa fa-search"></i></b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <b-table :items="subscriptions.content" :fields="columnNames" :current-page="1" :per-page="perPage" hover outlined show-empty responsive>
      <template v-slot:cell(actions)="data">
        <b-button v-if="!data.item.toBeUpdated" variant="primary" :to="{ path: '/patient/' + data.item.patient.id}">
          View / Edit
        </b-button>
      </template>
      <template v-slot:cell(recurring)="data">
        <b-form-checkbox style="text-align: center;" block variant="primary" v-model="data.item.recurring" :disabled="true" />
      </template>
    </b-table>
    <br>
  </b-card>
</template>

<script>

import moment from 'moment'

export default {
  components: { },
  data: function () {
    return {
      searchQuery: '',
      subscriptions: [],
      columnNames: [
        { key: 'patient.id', label: 'Id' },
        { key: 'patient.displayName', label: 'Patient Name' },
        { key: 'patient.email', label: 'Email' },
        'code',
        'couponCode',
        { key: 'createdOn', label: 'Created On', formatter: 'formatDate' },
        'recurring',
        'usagesRemaining',
        'actions'
      ],
      currentPage: 1,
      perPage: 10,
      confirmAllChangesModal: false,
      subscriptionsToUpdate: [],
      addSubscriptionsModal: false,
      subscriptionsToAdd: [],
      deleteSubscriptionsModal: false,
      subscriptionToDelete: {},
      lang: {
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
        placeholder: {
          date: 'Select Date',
          dateRange: 'Select Date Range'
        }
      }
    }
  },
  filters: {
    dateTime: function (date) {
      return moment(date).local().format('HH:mm - DD/MM/YY')
    }
  },
  created () {
    this.getSubscriptions()
  },
  watch: {
    currentPage: function () {
      this.getSubscriptions()
    }
  },
  methods: {
    getSubscriptions () {
      this.$subscriptionService.search(this.searchQuery, this.currentPage - 1, this.perPage).then(subscriptions => {
        this.subscriptions = subscriptions
      }, error => {
        this.$awn.alert(error, 'An error occurred')
      })
    },

    formatDate (value) {
      return moment(value).local().format('MMMM Do YYYY, h:mm A')
    },

    copyToClipboard (text) {
      if (window.clipboardData && window.clipboardData.setData) {
        // IE specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData('Text', text)
      } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
        var textarea = document.createElement('textarea')
        textarea.textContent = text
        textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in MS Edge.
        document.body.appendChild(textarea)
        textarea.select()
        try {
          return document.execCommand('copy') // Security exception may be thrown by some browsers.
        } catch (ex) {
          console.warn('Copy to clipboard failed.', ex)
          return false
        } finally {
          document.body.removeChild(textarea)
        }
      }
    }

  }
}
</script>

<style lang="scss">
.huge-icon {
  font-size:30px;
  cursor: pointer;
}
.success {
  color: #009344;
}
.success:hover {
  color: #006d32;
}
.danger {
  color: #f86c6b;
}
.danger:hover {
  color: #f64846;
}
.reduced-margin-table {
  margin-bottom: -20px;
  min-height: 20rem;
}
.table-nowrap th, .table-nowrap td {
  white-space: nowrap;
}
.mx-panel thead tr th, .mx-panel tbody tr td {
  padding: 0 !important;
}
.isDifferentNew {
  color: #009344;
  font-weight: bold;
}
.isDifferentOld {
  color: tomato;
  text-decoration: line-through; 
}
.mx-input { 
  max-height: 27px;
  border-radius: 0;
  color: #151b1e;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.mx-calendar-icon {
  height: 18px;
}
#percentage:invalid {
  color: #FFF;
  border-color: red;
  background-color: #f97776;
}
.red-text {
  color: red;
  font-size: small;
}
.small-input {
  width:70%;
}
.table th, .table td {
  vertical-align: middle;
}
</style>

