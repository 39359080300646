<template>
  <div class="sidebar no-print">
    <SidebarHeader/>
    <SidebarForm/>
    <nav class="sidebar-nav">
      <div class="header" slot="header">
        <img class="logo" src="/static/img/logo.png"/>
      </div>
      <ul class="nav">
        <template v-for="(item, index) in navItems">
          <template v-if="item.title">
            <SidebarNavTitle v-bind:key="index" :name="item.name" :classes="item.class" :wrapper="item.wrapper"/>
          </template>
          <template v-else-if="item.divider">
            <SidebarNavDivider v-bind:key="index" :classes="item.class"/>
          </template>
          <template v-else-if="item.label">
            <SidebarNavLabel v-bind:key="index" :name="item.name" :url="item.url" :icon="item.icon" :label="item.label" :classes="item.class"/>
          </template>
          <template v-else>
            <template v-if="item.children">
              <!-- First level dropdown -->
              <SidebarNavDropdown v-bind:key="index" :name="item.name" :url="item.url" :icon="item.icon">
                <template v-for="(childL1, cL1index) in item.children">
                  <template v-if="childL1.children">
                    <!-- Second level dropdown -->
                    <SidebarNavDropdown v-bind:key="cL1index" :name="childL1.name" :url="childL1.url" :icon="childL1.icon">
                      <template v-for="(childL2, cL2index) in childL1.children">
                        <li class="nav-item" v-bind:key="cL2index">
                          <SidebarNavLink:name="childL1.name" :url="childL1.url" :icon="childL1.icon" :badge="childL1.badge" :variant="item.variant"/>
                        </li>
                      </template>
                    </SidebarNavDropdown>
                  </template>
                  <template v-else>
                    <SidebarNavItem v-bind:key="cL1index" :classes="item.class">
                      <SidebarNavLink v-if="childL1.name === 'Waiting for admin'" :name="childL1.name" :url="childL1.url" :icon="childL1.icon" :badge="childL1.badge" :variant="item.variant" :noOfOrdersWaitingForAdminDeliveryDetails="noOfOrdersWaitingForAdminDeliveryDetails"/>
                      <SidebarNavLink v-else-if="childL1.name === 'Rejected by pharmacy'" :name="childL1.name" :url="childL1.url" :icon="childL1.icon" :badge="childL1.badge" :variant="item.variant" :noOfOrdersWaitingForAdminRejectedByPharmacy="noOfOrdersWaitingForAdminRejectedByPharmacy"/>
                      <SidebarNavLink v-else-if="childL1.name === 'Payment failed'" :name="childL1.name" :url="childL1.url" :icon="childL1.icon" :badge="childL1.badge" :variant="item.variant" :noOfOrdersPaymentFailed="noOfOrdersPaymentFailed"/>
                      <SidebarNavLink v-else :name="childL1.name" :url="childL1.url" :icon="childL1.icon" :badge="childL1.badge" :variant="item.variant"/>
                    </SidebarNavItem>
                  </template>
                </template>
              </SidebarNavDropdown>
            </template>
            <template v-else>
              <SidebarNavItem v-bind:key="index" :classes="item.class">
                <SidebarNavLink :name="item.name" :url="item.url" :icon="item.icon" :badge="item.badge" :variant="item.variant"/>
              </SidebarNavItem>
            </template>
          </template>
        </template>
      </ul>
      <slot></slot>
    </nav>
    <SidebarFooter/>
    <SidebarMinimizer/>
  </div>
</template>
<script>
import SidebarFooter from './SidebarFooter'
import SidebarForm from './SidebarForm'
import SidebarHeader from './SidebarHeader'
import SidebarMinimizer from './SidebarMinimizer'
import SidebarNavDivider from './SidebarNavDivider'
import SidebarNavDropdown from './SidebarNavDropdown'
import SidebarNavLink from './SidebarNavLink'
import SidebarNavTitle from './SidebarNavTitle'
import SidebarNavItem from './SidebarNavItem'
import SidebarNavLabel from './SidebarNavLabel'
export default {
  name: 'sidebar',
  props: {
    navItems: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data: function () {
    return {
      noOfOrdersWaitingForAdminDeliveryDetails: '',
      noOfOrdersWaitingForAdminRejectedByPharmacy: '',
      noOfOrdersPaymentFailed: ''
    }
  },
  created() {
    setTimeout(this.updateOrders, 1000);
  },
  components: {
    SidebarFooter,
    SidebarForm,
    SidebarHeader,
    SidebarMinimizer,
    SidebarNavDivider,
    SidebarNavDropdown,
    SidebarNavLink,
    SidebarNavTitle,
    SidebarNavItem,
    SidebarNavLabel
  },
  methods: {
    handleClick (e) {
      e.preventDefault()
      e.target.parentElement.classList.toggle('open')
    },
    updateOrders () {
      this.$orderService.getPendingAdminOrders().then(orders => {
        this.noOfOrdersWaitingForAdminDeliveryDetails = orders.content.filter(oi => oi.status == 'WAITING_FOR_ADMIN_DELIVERY_DETAILS').length;
        this.noOfOrdersWaitingForAdminRejectedByPharmacy = orders.content.filter(oi => oi.status == 'AWAITING_ADMIN_ACTION_REJECTED_BY_PHARMACY').length;
        this.noOfOrdersPaymentFailed = orders.content.filter(oi => oi.status == 'PAYMENT_FAILED').length;
        setTimeout(this.updateOrders, 300000);
      });
    }
  }
}
</script>

<style lang="scss">
  .sidebar .nav-link i, .sidebar .navbar .active.dropdown-toggle i, .navbar .sidebar .active.dropdown-toggle i {
    color: #fff;
  }
  .sidebar .nav-link.active i {
    color: #009344;
  }
  .header {
    float: right;
    margin: 5%;
  }
  .logo {
    display: block;
  }
  .nav-link {
    cursor: pointer;
  }
</style>
