<template>
  <b-card>
    <div slot="header">
      <i class="fa fa-align-justify"></i> Two Factor Authentication
    </div>

    <b-row>
      <b-col cols="8">
        <b-form-group
          id="marketingConsent"
          label="Two factor authentication enabled"
          label-for="marketingConsent"
        >
          <b-form-radio-group
            :block="false"
            button-variant="outline-primary"
            buttons
            name="authEnabled"
            v-model="profile.is2faEnabled"
            :options="truthyFalsy"
            v-on:change="openModal"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-modal
      v-model="credentialsModal"
      centered
      size="md"
      :title="
        `${
          !profile.is2faEnabled ? 'Disable' : 'Enable'
        } two factor authentication`
      "
      :ok-title="!profile.is2faEnabled ? 'Disable' : 'Enable'"
      ok-variant="primary"
      @ok="changeStatus"
      @hidden="onHideModal"
    >
      <div class="mb-2 text-center">
        Please enter password to confirm
      </div>
      <b-col cols="12">
        <b-form-input
          type="password"
          class="form-control"
          v-model="password"
          placeholder="Password"
        />
        <p class="text-danger">{{ this.errorMessage }}</p>
      </b-col>
    </b-modal>

    <b-modal
      v-model="qrModal"
      centered
      size="md"
      title="Scan QR"
      ok-title="Submit"
      ok-variant="primary"
      @ok="enable"
      @hidden="onHideModal"
    >
      <b-col cols="12">
        <b-row>
          <img :src="qrUrl" class="mx-auto" alt="" />
        </b-row>
        <b-row>
          <div class="mx-auto">
            {{ this.secretCode }}
          </div>
        </b-row>
        <b-row class="mt-3">
          <b-col class="mx-auto" cols="6">
            <b-form-input
              autofocus
              type="number"
              class="form-control text-center otp"
              v-model="otp"
              placeholder="OTP number"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="errorMessage">
          <p class="text-danger mx-auto mb-0">{{ this.errorMessage }}</p>
        </b-row>
        <b-row :class="errorMessage ? 'mt-2' : 'mt-3'">
          <center>
            Scan QR code or enter the code with the authenticator app to
            retrieve the OTP number. Save this code to use later if you lose
            your account on authenticator app to re-generate the OTP number.
          </center>
        </b-row>
      </b-col>
    </b-modal>
  </b-card>
</template>

<script>
export default {
  data: function() {
    return {
      password: "",
      credentialsModal: false,
      qrModal: false,
      status: "",
      profile: "",
      qrUrl: "",
      secretCode: "",
      otp: "",
      userKey: "",
      errorMessage: "",
      truthyFalsy: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
    };
  },
  created() {
    this.getProfile();
  },
  methods: {
    onHideModal() {
      this.getProfile();
      this.errorMessage = "";
    },
    getProfile() {
      this.$userService
        .getProfile()
        .then((profile) => {
          this.profile = profile;
        })
        .catch((error) => {
          this.$awn.alert(error, "An error occurred");
        });
    },
    changeStatus(bvModalEvent) {
      bvModalEvent.preventDefault();

      if (this.profile.is2faEnabled) {
        this.$twoFactorAuthService
          .register(this.profile.email, this.password)
          .then((res) => {
            this.qrUrl = res.qrUrl;
            this.secretCode = res.secretCode;
            this.userKey = res.key;

            this.credentialsModal = false;
            this.qrModal = true;
            this.otp = "";
          })
          .catch((error) => {
            this.errorMessage = error.response.data.message;
          });
      } else {
        this.$twoFactorAuthService
          .disable(this.profile.email, this.password)
          .then(() => {
            this.getProfile();
            this.password = "";
            this.otp = "";
            this.credentialsModal = false;
            this.$awn.success(
              "Two factor authentication disabled successfully!"
            );
          })
          .catch((error) => {
            this.errorMessage = error.response.data.message;
          });
      }
    },
    enable(bvModalEvent) {
      bvModalEvent.preventDefault();

      if (this.otp && this.password && this.userKey) {
        this.$twoFactorAuthService
          .enable(this.profile.email, this.password, this.otp, this.userKey)
          .then(() => {
            this.getProfile();
            this.password = "";
            this.otp = "";
            this.qrModal = false;
            this.$awn.success(
              "Two factor authentication enabled successfully!"
            );
            this.logout();
          })
          .catch((error) => {
            this.errorMessage = error.response.data.message;
          });
      }
    },
    openModal() {
      this.credentialsModal = true;
    },
    logout() {
      this.$userService.blackListToken().then(() => {
        this.$userService.logout().then(() => {
          console.log("You've been logged out successfully");
          this.$router.push("/login");
        });
      });
    },
  },
};
</script>

<style>
.otp::-webkit-outer-spin-button,
.otp::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp[type="number"] {
  -moz-appearance: textfield;
}
</style>
