<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="9" lg="6" class="login-position">
          <b-card-group>
            <b-card no-body class="p-4 dark-colored">
              <b-card-body>
                <img class="logo" src="/static/img/logo.png"/>
                <div v-if="!token">
                  <b-alert show variant="warning">
                    <span>
                      <strong>Please note: </strong>To access the system you will need to change your password. This change needs to happen every <strong>three months</strong> or those <strong>new</strong> to system use.
                    </span>
                  </b-alert>
                  <b-input-group class="mb-3">
                    <b-input-group-prepend><b-input-group-text><i class="icon-lock"></i></b-input-group-text></b-input-group-prepend>
                    <input type="password" class="form-control" placeholder="Current Password" v-model="currentPassword">
                  </b-input-group>
                </div>
                <b-input-group class="mb-4">
                  <b-input-group-prepend><b-input-group-text><i class="icon-lock"></i></b-input-group-text></b-input-group-prepend>
                  <input type="password" class="form-control" placeholder="New Password" v-model="newPassword">
                </b-input-group>
                <b-input-group class="mb-4">
                  <b-input-group-prepend><b-input-group-text><i class="icon-lock"></i></b-input-group-text></b-input-group-prepend>
                  <input type="password" class="form-control" placeholder="Confirm New Password" v-model="confirmNewPassword">
                </b-input-group>
                <b-alert show variant="warning">
                  <span>
                    <strong>Your password should meet the following rules:</strong>
                    <ul>
                      <li>It should contain at least 1 upper-case letter.</li>
                      <li>It should contain at least 1 lower-case letter.</li>
                      <li>It should contain at least 1 number.</li>
                      <li>It should contain at least 1 special character.</li>
                      <li>It should be at least 8 characters length.</li>
                    </ul>
                  </span>
                </b-alert>
                <b-row>
                  <div v-if="response" class="error-message"><p>{{response}}</p></div>
                  <b-button variant="primary" :disabled="(!token && !currentPassword) || !newPassword || !confirmNewPassword" v-on:click="resetPassword()" v-bind:class="'btn-lg btn-block px-4' + loading">Reset Password</b-button>
                </b-row>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Reset',
  data () {
    return {
      section: 'Reset Password',
      loading: '',
      response: '',
      username: '',
      token: '',
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      error: ''
    }
  },
  mounted () {
    // issue with template theme where the body's background color is not being updated
    document.body.style.backgroundColor = "#282B30";
    // check if there are url params and if username is there
    if (Object.keys(this.$router.currentRoute.query).length === 0 || !this.$router.currentRoute.query.username) {
      this.$router.push('/login');
    } else {
      this.username = this.$router.currentRoute.query.username;
      this.token = this.$router.currentRoute.query.token;
    }
  },
  methods: {
    async resetPassword () {
      this.response = '';
      if ((!this.token && !this.currentPassword) || !this.newPassword || !this.confirmNewPassword) {
        this.response = 'All fields are mandatory';
        return;
      }
      if (this.newPassword !== this.confirmNewPassword) {
        this.response = 'The passwords don\'t match';
        return;
      }
      // if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/.test(this.newPassword)) {
      //   this.response = 'The new password does not match the rules';
      //   return;
      // }
      if (this.newPassword?.length < 8) {
        this.response =  'Password must be at least 8 characters long.';
        return;
      }
      if (!(/[A-Z]+/.test(this.newPassword))){ // at least one upper-case char
        this.response =  'Password must contain at least 1 upper-case character.';
        return;
      }
      if (!(/[a-z]+/.test(this.newPassword))) { // at least one lower-case char
        this.response =  'Password must contain at least 1 lower-case character.';
        return;
      }
      if (!(/[0-9]+/.test(this.newPassword))){ // at least one number
        this.response =  'Password must contain at least 1 number.';
        return;
      }
      if (!(/[~`!@#$%^&\-*()_+=|:\\;"'[<\]>,.?{}/€£¥•=]+/.test(this.newPassword))) { // at least one special char
        this.response =  'Password must contain at least 1 special character.' ;
        return;
      }
      this.toggleLoading()
      if (this.token) {
        this.$userService.resetPassword(this.username, this.newPassword, this.token).then(result => {
          console.log(result);
          this.$awn.success('Your password was updated successfully.');
          this.$router.push({ path: '/dashboard' });
        }).catch((error) => {
          this.response = error.response && error.response.data ? error.response.data.message : 'The server is not available';
          this.toggleLoading();
        })
      } else {
        this.$userService.mandatoryChangePassword(this.username, this.currentPassword, this.newPassword).then((result) => {
          console.log(result);
          this.$awn.success('Your password was updated successfully.');
          this.$router.push({ path: '/dashboard' });
        }).catch((error) => {
          this.response = error.response && error.response.data ? error.response.data.message : 'The server is not available';
          this.toggleLoading();
        }) 
      }
    },
    toggleLoading () {
      this.loading = this.loading === '' ? 'loading' : '';
    }
  }
}
</script>

<style lang="scss">
#website-background{
  background-color: #282B30 !important;
}
.logo {
  margin: auto;
  display: block;
  padding-bottom: 1em;
}
.dark-colored {
  background-color: #292b30;
  border: none;
}
.loginForm .input-group {
  padding-bottom: 1em;
  height: 3em;
}
.input-group input {
  height: 3em;
}
.login-position{
  margin-top: -10%;
}
</style>