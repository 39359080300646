<template>
  <div v-if="prescription && prescription.id">
    <b-btn variant="primary" class="btn-block m-1" @click="showPrescription = !showPrescription"
      :class="showPrescription ? 'collapsed' : null" aria-controls="prescriptionCollapse"
      :aria-expanded="showPrescription ? 'true' : 'false'">
      <span v-if="!showPrescription">show </span>
      <span v-if="showPrescription">hide </span>prescription
    </b-btn>
    <br />
    <b-collapse id="prescriptionCollapse" v-model="showPrescription">
      <b-card>
        <div slot="header">
          <b-row>
            <b-col sm="12" md="9">
              <i class='fa fa-align-justify'></i><strong class='header-spacing'> Prescription details</strong>
            </b-col>

            <b-col v-if="prescription.orderItem" sm="12" md="3" style="text-align-last: right;">
              <b-button variant="warning" @click="printTable()" class="float-right">Print</b-button>
            </b-col>
          </b-row>
        </div>
        <div class="mt-2 mb-4">
          <b-form-group id="orderPlatformGroup" label="Authorization Code" label-for="orderPlatform" label-cols="3">
            <b-form-input name="orderPlatform" type="text" v-model="prescription.authorizationCode" disabled />
          </b-form-group>
        </div>
        <span v-if="order.type === 'FORM'">
          <h5 class="text-primary">Treatment prescribed</h5>

          <p v-if="!order.formType">{{ prescription.treatment }}</p>
          <p v-if="order.formType">{{ prescription.formulary.name }} - {{ prescription.formulary.strength }} -
            {{ prescription.formulary.tablets }} Tablets</p>

          <span v-if="prescription && prescription.dosageInstructions">
            <h5 class="text-primary">Dosage instructions</h5>
            <p>{{ prescription.dosageInstructions }}</p>
          </span>
        </span>
        <span ref="booking" v-if="(order.type === 'BOOKING' || order.type === 'PRESCRIPTION_ONLY' || order.type === 'CHAT_ASSESSMENT') && prescription">
          <b-table responsive :items="prescription.medication"
            :fields="['name', 'formulation', 'strength', 'dosage', 'quantity', 'unit']"></b-table>
        </span>
        <span v-if="order.type === 'TRAVEL'">
          <span v-if="order.proposedAntimalarial">
            <h4>Antimalarials</h4>
            <b-table responsive :items="[order.proposedAntimalarial]" :fields="antimalarialPrescriptionFields">
              <template v-slot:cell(price)="row">£{{ row.value }}</template>
            </b-table>
          </span>
          <span v-if="order.proposedVaccinations.length > 0">
            <h4>Vaccinations</h4>
            <b-table responsive :items="order.proposedVaccinations" :fields="vaccinationPrescriptionFields">
              <template v-slot:cell(price)="row">£{{ row.value }}</template>
            </b-table>
          </span>
        </span>
      </b-card>
    </b-collapse>

    <div ref="pdfHeader" class="print_only site-address">
      <div class="pull-right" style="display: flex; flex-direction: column;">
        <span>The GP Service (UK),</span>
        <span>Coventry University Technology Park,</span>
        <span>The TechnoCentre,</span>
        <span>Puma Way,</span>
        <span>CV1 2TT Coventry</span>
        <span>Company Number: 09359853</span>
        <span>Tel: 024 7745 0727</span>
        <span>Email: Medical@theGPservice.co.uk</span>
      </div>
      <img alt="The GP Service" :src="logo" class="site-logo" />
    </div>

    <div v-if="prescription.orderItem" ref="details">
      <div class="other_print_only_section" style="flex-direction: column;">
        <h1 style="color: #006d32; margin-bottom: 0;">Private prescription</h1>
        <h4 style="color: #006d32; margin-bottom: 0;" v-if="prescription.id">
          <strong>ID:&nbsp;{{prescription.id}}</strong>
        </h4>
      </div>
      <div class="other_print_only_section">
        <div style="width: 49%; background: #fff; border: 1px solid lightgray; padding: 1em;">
          <h4 style="color: #006d32; margin-bottom: 5px">Prescriber details</h4>
          <p style="color: #006d32; font-size: 1.1em; margin-bottom: 5px"><strong>{{prescription.orderItem.doctor.displayName}}</strong></p>
          <span v-if="prescription.orderItem.doctor.settings.QUALIFICATIONS">{{prescription.orderItem.doctor.settings.QUALIFICATIONS}}<br/></span>
          <span  v-if="prescription.orderItem.doctor.gmc"><strong>{{doctorType}} number:</strong> {{prescription.orderItem.doctor.gmc}}<br/></span>
        </div>
        <div style="width: 49%; background: #fff; border: 1px solid lightgray; padding: 1em;">
          <h4 style="color: #006d32; margin-bottom: 5px">Patient details</h4>
          <p style="color: #006d32; font-size: 1.1em; margin-bottom: 5px"><strong>{{prescription.orderItem.patient.displayName}}</strong></p>
          <span><strong>Date of birth:</strong> {{ formatDate(prescription.orderItem.patient.dateOfBirth) }}<br/></span>
          <div style="display: flex; flex-direction: row; justify-content: left;">
            <span v-if="prescription.orderItem.patient.address"><strong>Address:</strong> <br/></span>
            <div style="padding-left: 5px;">
              <span v-if="addressLine(prescription.orderItem.patient.address, 1)">{{addressLine(prescription.orderItem.patient.address, 1)}}<br/></span>
              <span v-if="addressLine(prescription.orderItem.patient.address, 2)">{{addressLine(prescription.orderItem.patient.address, 2)}}<br/></span>
              <span v-if="addressLine(prescription.orderItem.patient.address, 3)">{{addressLine(prescription.orderItem.patient.address, 3)}}<br/></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div ref="signature" v-if="prescription.orderItem" class="signature" style="background: #fff; border: 1px solid lightgray; padding: 1em; margin-top: 2%;">
      <div class="row">
        <div class="col col-md-6">
          <label><strong>Signature:</strong></label><br/>
          <img alt="The GP Service" v-bind:src="signatureImg" style="width: 250px; height: 90px;" />
        </div>

        <div class="col col-md-6">
          <label><strong>Date:</strong></label><br/>
          <label style="width: 100%; height: 90px; padding: 10px; background: lightgray; border: 1px solid lightgray;">{{ formatDate(prescription.creationDate) }} {{ formatTime(prescription.creationDate) }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'prescription-details',
  props: ['order', 'prescription'],
  data: function () {
    return {
      showPrescription: false,
      logo: "",
      signatureImg: "",
      doctorType: "",
      antimalarialPrescriptionFields: [
        { key: 'name', label: 'Name' },
        { key: 'dosageStartDate', label: 'Start Date', tdClass: 'text-nowrap' },
        { key: 'dosageEndDate', label: 'End Date', tdClass: 'text-nowrap' },
        { key: 'dosageInstructions', label: 'Instructions' },
        { key: 'dosageExtraInfo', label: 'Extra Information' },
        { key: 'price', label: 'Price', tdClass: 'text-nowrap' }
      ],
      vaccinationPrescriptionFields: [
        { key: 'name', label: 'Name' },
        { key: 'startDate', label: 'Start Date', tdClass: 'text-nowrap' },
        { key: 'dosageInstructions', label: 'Instructions' },
        { key: 'price', label: 'Price', tdClass: 'text-nowrap' }
      ]
    }
  },
  async mounted() {
    this.convertImageToBase64("/static/img/logo.png");
  },
  computed: {
    creationDate: function () {
      return moment(this.order.creationDate).local().format('Do MMMM YYYY, HH:mm');
    },
    price: function () {
      return '£' + this.order.price;
    }
  },
  watch: {
    prescription: {
      handler() {
        if(this.prescription?.orderItem){
          this.getIdentifier();
          this.$orderService.getDoctorSignature(this.prescription.orderItem.doctor.id).then((response) => {
            this.signatureImg = 'data:image/png;base64,' + response.data;
          })
          .catch((error) => {
            console.log("error: ", error);
          })
        }
      }
    }
  },
  methods: {
    printTable() {
      const pdfHeader = this.$refs.pdfHeader?.outerHTML || '';
      const bookingContent = this.$refs.booking?.outerHTML || '';
      const details = this.$refs.details?.outerHTML || '';
      const signature = this.$refs.signature?.outerHTML || '';

      // Combine all the content
      const printableContent = `
        <div style="background: #fff;">
          <style>
            body {
              font-family: Arial, sans-serif;
              background-color: #fff;
            }
            .text-primary {
              color: #007bff;
            }
            h4, h5 {
              margin: 10px 0;
            }
          </style>
          ${pdfHeader}
          ${details}
          <div style="background: #fff; border: 1px solid lightgray; padding: 1em;">
            <h4 style="color: #006d32; margin-bottom: 1em">Prescribed medication</h4>
            ${bookingContent}
          </div>
          ${signature}
          <p style="margin-top: 15px;">To verify this prescription you can call us on 024 7745 0727 or go to <a href="https://app.thegpservice.co.uk/prescription-checker">Our prescription checker</a></p>
        </div>
      `;

      const originalContent = document.body.innerHTML;
      document.body.innerHTML = printableContent;
      window.print();
      document.body.innerHTML = originalContent;

      this.$nextTick(() => {
        window.location.reload();
      });
    },

    async convertImageToBase64(url) {
      try {
        const imagePath = url;
        const response = await fetch(imagePath);
        const blob = await response.blob();
        
        const reader = new FileReader();
        reader.onload = (e) => {
          this.logo = e.target.result;
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Error converting image to Base64:", error);
      }
    },

    getIdentifier() {
      if (this.prescription.orderItem.doctor.settings.DOCTOR_TYPE == 'VET') {
          this.doctorType = 'RCV';
          return this.doctorType;
      }
      if (this.prescription.orderItem.doctor.settings.DOCTOR_TYPE == 'PRESCRIBING_PHARMACIST') {
        this.doctorType = 'GPhC';
          return this.doctorType;
      }
      this.doctorType = 'GMC';
      return this.doctorType;
    },

    formatDate(date) {
      if (!date) return "N/A";
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      return new Date(date).toLocaleDateString('en-GB', options);
    },

    formatTime(date) {
      if (!date) return '';
      const options = { hour: '2-digit', minute: '2-digit', hour12: true };
      return new Date(date).toLocaleTimeString('en-US', options);
    },

    addressLine(address, line) {
      switch (line) {
        case 1: return address.line1;
        case 2: return address.line2;
        case 3: return [address.city, address.county, address.postcode].filter(p => p).join(", ")
      }
    }
  }
}
</script>

<style lang="scss">
  .print_only{
    display:none;
  }

  .other_print_only_section {
    display:none;
  }

  .signature {
    display: none;
  }

  @media print{
    @page {
      size: landscape;
      margin: 20mm;
    }

    .print_only{
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    .other_print_only_section {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 2%;
    }

    .signature {
      display: block;
    }
  }

  .site-address{
    height: fit-content;
    div{
      color:#006d32; 
      max-width:50%;
      height: fit-content;
      margin-top:1em; 
      text-align:right; 
      font-size:0.9em;
    } 
    border-bottom:1px solid #006d32; 
    padding-bottom:0.5em;
    margin-bottom: 15px;
  }

  .site-logo{
    width:7em;
    height: fit-content;
    margin-top:1em;
  }

  h1 {
    font-weight: 300;
  }

  h4 {
    font-size: 18px;
  }
</style>
