<template>
  <div>
    <b-card>
      <div slot="header">
        <i class='fa fa-align-justify'></i><strong class='header-spacing'> Invoice items</strong>
      </div>
      <b-table responsive :items="invoiceItems" :fields="['type', 'quantity', 'price']">
        <template v-slot:cell(paymentDate)="row">{{row.value | dateTime}}</template>
        <template v-slot:cell(price)="row">£{{row.value}}</template>
      </b-table>
      <br />

      <b-button class="float-right" variant="primary" @click="confirmModal = true">Confirm Manual Payment</b-button>

      <b-modal title="Confirm manual payment" centered class="modal-success" v-model="confirmModal" @ok="confirmManualPayment()" ok-title="Yes" ok-variant="success" cancel-title="No" cancel-variant="outline-danger">
        <strong class="text-muted">PLEASE NOTE: This action is not reversible.</strong>
        <span class="text-danger">
          Are you sure you want to put this order through? This means you have already taken manual payment of this order and the order will move to the following status, which will vary depending on what status the order was when taking payment failed. 
        </span>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'invoice-details',
  props: ['invoiceItems', 'orderId'],
  filters: {
    dateTime(date) {
      return moment(date).local().format('Do MMMM YYYY, HH:mm');
    }
  },
  data: function () {
    return {
      confirmModal: false
    }
  },
  watch: {
    invoiceItems: {
      handler: function (_invoiceItems) { 
        if (_invoiceItems) {
          _invoiceItems.forEach(item => {
            if (item.type === 'TOTAL') {
              item._rowVariant = 'secondary';
            }
          });
        }
      }
    }
  },
  methods: {
    confirmManualPayment() {
      this.$orderService.processManualPayment(this.orderId).then((result) => {
        this.$router.push('/orders/payment-failed');
        this.$awn.success(`Order moved to ${result.status}`);
      });
    }
  }
}
</script>