<template>
  <div>
    <b-card>
      <div slot="header">
        <i class='fa fa-align-justify'></i><strong class='header-spacing'>Additional Info</strong>
      </div>
      <p v-if="order.workflow && order.workflow.PRESENTING_COMPLAINT">{{order.workflow.PRESENTING_COMPLAINT}}</p>
      <p v-if="!order.workflow || !order.workflow.PRESENTING_COMPLAINT">Not provided.</p>
    </b-card>
  </div>
</template>

<script>
  export default {
    name: "additional-order-info",
    props: ['order']
  }
</script>
