<template>
  <div>
    <b-row>
      <b-col md="12" lg="6">
        <order-details :order="order" />
      </b-col>
      <b-col>
        <patient-details :patient="order.patient" />
      </b-col>
    </b-row>
    <br/>

    <prescription-details :order="order" :prescription="prescription"/>

    <b-card v-if="prescription.id">
      <div slot="header">
        <i class='fa fa-align-justify'></i><strong class='header-spacing'> Delivery details</strong>
      </div>

    <b-row>
      <b-col md="12" xl="4">
        <br/>
        <b-form-group label="How would the patient like to receive his medication?" label-for="basicRadiosCustom" label-class="question-title">
          <b-form-radio-group v-model="deliveryOnly" stacked>
            <b-form-radio value="false" @click.native="findPharmacies()">Collected from local pharmacy</b-form-radio>
            <b-form-radio value="true" @click.native="findPharmacies()">Delivered from local pharmacy</b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <br/>

        <div class="form_group form_group--nested">
          <b-form-group label="Enter your postcode to select your pharmacy:" label-for="postcode" label-class="question-title">
            <b-form-group id="pharmacyIdDescription" description="This field has been pre-populated with the order's pharmacy postcode">
              <b-row class="form-group">
                <b-col cols="9">
                  <b-input-group class="no-padding">
                    <b-form-input type="search" placeholder="Enter your postcode" v-model="postcode" name="postcode" @search="findPharmacies()" style="min-width:35%"/>
                    <b-input-group-append>
                      <b-button variant="primary" class="btn btn--2" v-on:click="findPharmacies()" style="min-width:30%">Find pharmacies</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-form-group>
        </div>

        <br/>

        <div class="form_group form_group--nested">
          <b-form-group label="Enter a Pharmacy ID or pick a pharmacy using the map:" label-for="pharmacyId" label-class="question-title">
            <b-form-group id="pharmacyIdDescription" description="This field has been pre-populated with the order's pharmacy ID">
              <b-row class="form-group">
                <b-col cols="9">
                  <b-input-group class="no-padding" style="min-width:100%">
                    <b-form-input type="search" placeholder="Enter a Pharmacy ID" v-model="pharmacyId" name="pharmacyId" @search="findPharmacies()"/>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-form-group>
        </div>
      </b-col>

      <b-col>
        <p v-if="findPharmacyButtonClicked && !showMap">
          <br /><br /><br />
          Searching...
        </p>
        <div v-if="showMap" class="map_canvas">
            <gmap-map :center="postCodeLocation" ref="map" :zoom="9" style="width: 100%; height: 450px">
              <gmap-marker v-for="p in pharmacies" :key="p.id" @click="selectPharmacy(p)" :position="{lat: p.latitude, lng: p.longitude}" :icon="'https://cdn2.iconfinder.com/data/icons/color-svg-vector-icons-2/512/map_marker_base-48.png'" :opacity="p.memberType !== 'NONE' && p.active ? 1 : 0.3">
                <gmap-info-window :opened="pharmacyId === p.id">
                  <label class="mb-0 text-primary"><h5>{{p.name}}</h5></label> <br/>
                  <span v-if="p.active && p.memberType === 'GOLD'" class="text-primary"><label><strong>Gold Member Pharmacy</strong></label><br/></span>
                  <span v-if="p.active && p.memberType === 'SILVER'" class="text-primary"><label><strong>Silver Member Pharmacy</strong></label><br/></span>
                  <span v-if="p.memberType === 'NONE'" class="text-danger"><label><strong>Non-Member Pharmacy</strong></label><br/></span>
                  <label><strong>Pharmacy ID:</strong> {{p.id}}</label><br/> 
                  <label><strong>Email:</strong> {{p.email}}</label><br/> 
                  <label><strong>Phone:</strong> {{p.phone}}</label><br/> 
                  <b-row v-if="p.address">
                    <b-col cols="4" class="pr-0"><strong>Address:</strong></b-col>
                    <b-col class="pl-0" style="margin-left: -6%">
                      <span><label>{{p.address.line1}} {{p.address.line2}}</label><br/></span>
                      <span v-if="p.address.county"><label>{{p.address.county}}</label><br/></span>
                      <span><label>{{p.address.city}} - {{p.address.postcode}}</label></span>
                    </b-col>
                  </b-row>
                  <span v-if="p.preferences && p.preferences.openingTimes">
                    <label><strong>Opening times:</strong> {{p.preferences.openingTimes}} <br/></label>
                  </span>
                  <span v-if="deliveryOnly">
                    {{p.deliveryNotes}} <br/>
                    <label v-if="p.preferences && p.preferences.deliveryCharge"><strong>Delivery charge:</strong> {{p.preferences.deliveryCharge}}<br/></label> 
                  </span>
                  <span v-if="p.settings && p.settings.DOES_VACCINES">
                    <br/>
                    <label><strong>Does vaccines: </strong>Yes</label>
                  </span>
                </gmap-info-window>
              </gmap-marker>
            </gmap-map>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col xs="6" md="4" class="mb-1">
          <b-button type="button" variant="outline-danger" class="btn-block" @click="reset()">Reset</b-button>
        </b-col>
        <b-col xs="6" md="4">
          <b-button type="button" variant="primary" class="btn-block" @click="confirmModal = true">Confirm</b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-modal title="Confirm delivery details" centered class="modal-success" v-model="confirmModal" @ok="confirm()" ok-title="Yes" ok-variant="success" cancel-title="No" cancel-variant="outline-danger">
        <strong class="text-muted">PLEASE NOTE:</strong>
        <span class="text-danger">This action is not reversible, are you sure you want to confirm this order's delivery details?</span>
    </b-modal>

  </div>
  
</template>

<script>

import moment from 'moment'
import publicService from '../../services/PublicService'
import { gmapApi } from 'vue2-google-maps'

import PatientDetails from '../../components/PatientDetails'
import OrderDetails from '../../components/OrderDetails'
import PrescriptionDetails from '../../components/PrescriptionDetails'

export default {
  name: 'Orders',
  components: {
    PatientDetails,
    OrderDetails,
    PrescriptionDetails
  },
  data: function () {
    return {
      order: {},
      prescription: {},
      showPrescription: false,
      travelPicker: false,
      deliveryOnly: false,
      doesVaccines: false,
      memberType: 'NONE',
      postcode: '',
      findPharmacyButtonClicked: false,
      bounds: {},
      showMap: false,
      pharmacies: [],
      pharmacyId: 0,
      confirmModal: false,
      antimalarialPrescriptionFields: [
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'dosageStartDate',
          label: 'Start Date',
          tdClass: 'text-nowrap'
        },
        {
          key: 'dosageEndDate',
          label: 'End Date',
          tdClass: 'text-nowrap'
        },
        {
          key: 'dosageInstructions',
          label: 'Instructions'
        },
        {
          key: 'dosageExtraInfo',
          label: 'Extra Information',
        },
        {
          key: 'price',
          label: 'Price',
          tdClass: 'text-nowrap'
        }
      ],
      vaccinationPrescriptionFields: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'startDate',
          label: 'Start Date',
          tdClass: 'text-nowrap'
        },
        {
          key: 'dosageInstructions',
          label: 'Instructions',
        },
        {
          key: 'price',
          label: 'Price',
          tdClass: 'text-nowrap'
        }
      ]
    }
  },
  computed: {
    creationDate: function () {
      return moment(this.order.creationDate).local().format('Do MMMM YYYY, HH:mm');
    },
    dateOfBirth: function () {
      return moment(this.order.patient.dateOfBirth).format('DD-MM-YYYY');
    },
    price: function () {
      return '£' + this.order.price;
    },
    homePhone: function () {
      return this.order.patient.preferences.homePhone ? this.order.patient.preferences.homePhone : 'Not set';
    },
    google: function() {
      return gmapApi;
    }
  },
  mounted: function () {
    this.getOrderDetails()
  },
  methods: {
    getOrderDetails () {
      this.$orderService.getOrder(this.$route.params.orderId).then(result => {
        this.order = result;
        this.deliveryOnly = this.order.deliveryType == 'HOME' ? true : false;
        if (this.order.pharmacy) {
          this.postcode = this.order.pharmacy.address ? this.order.pharmacy.address.postcode : '';
          this.pharmacyId = this.order.pharmacy.id;
        }
        this.$orderService.getPrescription(this.$route.params.orderId).then(prescription => {
          if (prescription.medication) {
            prescription.medication.forEach(m => {
              for (var property in m.formulary) {
                if (Object.prototype.hasOwnProperty.call(m.formulary, property)) {
                  m[property] = m.formulary[property];
                }
              }
            })
          }
          this.prescription = prescription;
        });
        console.log(this.order)
        this.findPharmacies()
      }).catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
          console.log(error.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          this.$awn.alert(error.message);
          this.$router.push('/login')
        }
      });
    },
    dateTime(date) {
      return moment(date).local().format('Do MMMM YYYY, HH:mm');
    },
    findPharmacies() {
      this.showMap = false;
      this.findPharmacyButtonClicked = true;
      this.getLatLan(`${this.postcode},UK`).then(location => {
        this.postCodeLocation = location;
        if (location) {
          publicService.getNearestPharmacies(location.lat(), location.lng(), 10, this.deliveryOnly, this.memberType).then(pharmacies => {
            this.pharmacies = pharmacies;
            if (this.pharmacies.length > 2) {
              const maxLat = this.pharmacies.map(p => p.latitude).reduce((l1, l2) => Math.max(l1, l2));
              const maxLng = this.pharmacies.map(p => p.longitude).reduce((l1, l2) => Math.max(l1, l2));
              const minLat = this.pharmacies.map(p => p.latitude).reduce((l1, l2) => Math.min(l1, l2));
              const minLng = this.pharmacies.map(p => p.longitude).reduce((l1, l2) => Math.min(l1, l2));

              this.bounds = {
                  south: minLat,
                  west: minLng,
                  north: maxLat,
                  east: maxLng
              };
            } else {
              // if (this.pharmacies.length === 0) {
              //     this.showNoPharmaciesModal = true;
              // }

              const r = 0.5;
              this.bounds = {
                south: location.lat() - r,
                west: location.lng() - r,
                north: location.lat() + r,
                east: location.lng() + r
              };
            }
            this.showMap = true;
            this.$nextTick(() => {
              if(this.$refs.map) {
                this.$refs.map.$mapPromise.then((map) => {
                  map.fitBounds(this.bounds);
                });
              }
            });
          });
        } else {
          this.pharmacies = [];
        }
      });
    },
    async getLatLan(address) {
      await this.$gmapApiPromiseLazy();
      const google = this.google();
      return await new Promise((resolve, reject) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': address }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
              resolve(results[0].geometry.location);
          } else {
              reject(status);
          }
        });
      })
    },
    selectPharmacy(p) {
      this.pharmacyId = p.id;
    },
    reset() {
      this.getOrderDetails()
    },
    confirm() {
      this.confirmModal = false;
      var deliveryType = this.deliveryOnly ? 'HOME' : 'PHARMACY';
      this.$orderService.setDeliveryDetails(this.order.id, this.pharmacyId, deliveryType)
        .then(() => {
          this.$router.push('/orders/change-delivery')
          this.$awn.success('Delivery details changed successfully')
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.$awn.alert(error.response.data.message);
          } else {
            this.$awn.alert('There was a network error'); 
          }
        });
    }
  }
}
</script>

<style lang="scss">
  #pharmacyIdDescription small {
    margin-top: -1.8%;
  }
  .collapsed > .when-opened, :not(.collapsed) > .when-closed {
    display: none;
  }
</style>
