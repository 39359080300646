<template>
  <b-card>

    <div slot="header">
      <i class='fa fa-align-justify'></i> All Enterprises
    </div>

    <b-modal v-model="addEnterpriseModal" title="Add Enterprise" centered size="xl" ok-title="Confirm" ok-variant="primary" @ok="validateAndAddEnterprise()">
      <b-row>
      <b-col md="12" lg="8">
        <b-form-group id="newName" label="Name" label-for="new_name">
          <b-form-input data-vv-scope="addEp" type="text" v-validate="{ required: true }" v-model="newEp.name" name="new_name"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('addEp.new_name') }}&nbsp;</span>
        </b-form-group>
        <b-row>
        <b-col cols="6">
        <b-form-group id="newLogo" label="Logo" label-for="new_logoUrl">
          <div class="border mb-1" style="width: 100%; height: 150px; display: flex; justify-content: center; align-content: center;">
            <img v-if="Boolean(logoFile)" style="max-width: 100%; max-height: 100%; object-fit: contain;" :src="urlOf(logoFile)">
          </div>
          <b-form-file data-vv-scope="addEp" accept="image/png, image/jpeg" v-validate="{ required: true }" v-model="logoFile" :state="Boolean(logoFile)" name="new_logoUrl"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('addEp.new_logoUrl') }}&nbsp;</span>
        </b-form-group>
        </b-col>
        <b-col cols="6">
        <b-form-group id="newBackground" label="Background" label-for="new_backgroundUrl">
          <div class="border mb-1" style="width: 100%; height: 150px; display: flex; justify-content: center; align-content: center;">
            <img v-if="Boolean(bgFile)" style="max-width: 100%; max-height: 100%; object-fit: contain;" :src="urlOf(bgFile)">
          </div>
          <b-form-file data-vv-scope="addEp" accept="image/png, image/jpeg" v-validate="{ required: true }" v-model="bgFile" :state="Boolean(bgFile)" name="new_backgroundUrl"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('addEp.new_backgroundUrl') }}&nbsp;</span>
        </b-form-group>
        </b-col>
        </b-row>
        <b-form-group id="newSlug" label="URL Identifier" label-for="new_slug">
          <b-form-input data-vv-scope="addEp" type="text" v-validate="{ required: false }" v-model="newEp.slug" name="new_slug"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('addEp.new_slug') }}&nbsp;</span>
        </b-form-group>
      </b-col>
      <b-col md="12" lg="4">
        <b-form-group id="newPkgCode" label="Package Code" label-for="new_pkg_code">
          <b-form-input data-vv-scope="addEp" type="text" v-validate="{ required: true }" v-model="newEp.pkg.code" name="new_pkg_code"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('addEp.new_pkg_code') }}&nbsp;</span>
        </b-form-group>
        <b-form-group id="newPkgName" label="Package Name" label-for="new_pkg_name">
          <b-form-input data-vv-scope="addEp" type="text" v-validate="{ required: true }" v-model="newEp.pkg.name" name="new_pkg_name"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('addEp.new_pkg_name') }}&nbsp;</span>
        </b-form-group>
        <b-row>
        <b-col cols="6">
        <b-form-group id="newPkgCharge" label="Charge" label-for="new_pkg_charge">
          <b-form-input data-vv-scope="addEp" v-validate="{ required: true, min_value: 0 }" v-model="newEp.pkg.charge" name="new_pkg_charge" v-on:keypress="preventNumberExponential($event)" type="number" min="0"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('addEp.new_pkg_charge') }}&nbsp;</span>
        </b-form-group>
        </b-col>
        <b-col cols="6">
        <b-form-group id="newPkgVat" label="VAT" label-for="new_pkg_vat">
          <b-form-input data-vv-scope="addEp" v-validate="{ required: true, min_value: 0 }" v-model="newEp.pkg.vat" name="new_pkg_vat" v-on:keypress="preventNumberExponential($event)" type="number" min="0"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('addEp.new_pkg_vat') }}&nbsp;</span>
        </b-form-group>
        </b-col>
        </b-row>
        <b-row>
        <b-col cols="6">
        <b-form-group id="newPkgUsages" label="Usages" label-for="new_pkg_usages">
          <b-form-input data-vv-scope="addEp" v-validate="{ required: true, min_value: 1 }" v-model="newEp.pkg.usages" name="new_pkg_usages" v-on:keypress="preventNumberExponential($event)" type="number" min="0"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('addEp.new_pkg_usages') }}&nbsp;</span>
        </b-form-group>
        </b-col>
        <b-col cols="6">
        <b-form-group id="newPkgQuota" label="Quota" label-for="new_pkg_quota">
          <b-form-input data-vv-scope="addEp" v-validate="{ required: true, min_value: 1 }" v-model="newEp.pkg.quota" name="new_pkg_quota" v-on:keypress="preventNumberExponential($event)" type="number" min="0"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('addEp.new_pkg_quota') }}&nbsp;</span>
        </b-form-group>
        </b-col>
        </b-row>
        <b-form-group id="newPkgMonths" label="Months" label-for="new_pkg_months">
          <b-form-input data-vv-scope="addEp" v-validate="{ required: true, min_value: 1 }" v-model="newEp.pkg.months" name="new_pkg_months" v-on:keypress="preventNumberExponential($event)" type="number" min="0"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('addEp.new_pkg_months') }}&nbsp;</span>
        </b-form-group>
      </b-col>
      </b-row>
    </b-modal>

    <b-modal v-model="newImageModal" title="Update Image" size="md" ok-title="Confirm" ok-variant="primary" @show="resetImageModal($event)" @ok="putImage($event)">
      <b-row>
        <b-col>
          <b-form-group id="newImg" label="Select Image" label-for="new_image">
          <div class="border mb-1" style="display: flex; justify-content: center; align-items: center; height: 150px;">
            <img v-if="Boolean(newImage)" style="max-width: 100%; max-height: 100%; object-fit: contain;" :src="urlOf(newImage)">
          </div>
          <b-form-file data-vv-scope="img" accept="image/png, image/jpeg" v-validate="{ required: true }" v-model="newImage" :state="Boolean(newImage)" name="new_image"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('img.new_image') }}&nbsp;</span>
        </b-form-group>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal v-model="previewImageModal" hide-footer title="Preview" size="md">
      <b-row>
        <b-col>
          <div class="border" style="display: flex; justify-content: center; align-items: center; height: 150px;">
            <img v-if="Boolean(previewImage)" style="max-height: 100%; max-width: 100%; object-fit: contain;" :src="previewImage">
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal v-model="editEnterpriseModal" title="Edit Enterprise" centered size="lg" ok-title="Confirm" ok-variant="primary" @ok="validateAndEditEnterprise()">
      <b-row>
        <b-col md="12" lg="6">
          <b-form-group id="editName" label="Name" label-for="edit_name">
            <b-form-input data-vv-scope="editEp" type="text" v-validate="{ required: true }" v-model="editedEp.name" name="edit_name"/>
            <span class="red-text"><br/>{{ errors.first('editEp.edit_name') }}</span>
          </b-form-group>
          <b-form-group id="editSlug" label="URL Identifier" label-for="edit_slug">
            <b-form-input data-vv-scope="editEp" type="text" v-validate="{ required: false }" v-model="editedEp.slug" name="edit_slug"/>
            <span class="red-text"><br/>{{ errors.first('editEp.edit_slug') }}</span>
          </b-form-group>
          <b-form-group id="editPkgCode" label="Package Code" label-for="edit_pkg_code">
            <b-form-input data-vv-scope="editEp" type="text" v-validate="{ required: true }" v-model="editedEp.pkg.code" name="edit_pkg_code"/>
            <span class="red-text"><br/>{{ errors.first('editEp.edit_pkg_code') }}</span>
          </b-form-group>
          <b-form-group id="editPkgName" label="Package Name" label-for="edit_pkg_name">
            <b-form-input data-vv-scope="editEp" type="text" v-validate="{ required: true }" v-model="editedEp.pkg.name" name="edit_pkg_name"/>
            <span class="red-text"><br/>{{ errors.first('editEp.edit_pkg_name') }}</span>
          </b-form-group>
        </b-col>
        <b-col md="12" lg="6">
          <b-form-group id="editPkgCharge" label="Charge" label-for="edit_pkg_charge">
            <b-form-input data-vv-scope="editEp" v-validate="{ required: true, min_value: 0 }" v-model="editedEp.pkg.charge" name="edit_pkg_charge" v-on:keypress="preventNumberExponential($event)" type="number" min="0"/>
            <span class="red-text"><br/>{{ errors.first('editEp.edit_pkg_charge') }}</span>
          </b-form-group>
          <b-form-group id="editPkgVat" label="VAT" label-for="edit_pkg_vat">
            <b-form-input data-vv-scope="editEp" v-validate="{ required: true, min_value: 0 }" v-model="editedEp.pkg.vat" name="edit_pkg_vat" v-on:keypress="preventNumberExponential($event)" type="number" min="0"/>
            <span class="red-text"><br/>{{ errors.first('editEp.edit_pkg_vat') }}</span>
          </b-form-group>
          <b-form-group id="editPkgUsages" label="Usages" label-for="edit_pkg_usages">
            <b-form-input data-vv-scope="editEp" v-validate="{ required: true, min_value: 1 }" v-model="editedEp.pkg.usages" name="edit_pkg_usages" v-on:keypress="preventNumberExponential($event)" type="number" min="0"/>
            <span class="red-text"><br/>{{ errors.first('editEp.edit_pkg_usages') }}</span>
          </b-form-group>
          <b-form-group id="editPkgQuota" label="Quota" label-for="edit_pkg_quota">
            <b-form-input data-vv-scope="editEp" v-validate="{ required: true, min_value: 1 }" v-model="editedEp.pkg.quota" name="edit_pkg_quota" v-on:keypress="preventNumberExponential($event)" type="number" min="0"/>
            <span class="red-text"><br/>{{ errors.first('editEp.edit_pkg_quota') }}</span>
          </b-form-group>
          <b-form-group id="editPkgMonths" label="Months" label-for="edit_pkg_months">
            <b-form-input data-vv-scope="editEp" v-validate="{ required: true, min_value: 1 }" v-model="editedEp.pkg.months" name="edit_pkg_months" v-on:keypress="preventNumberExponential($event)" type="number" min="0"/>
            <span class="red-text"><br/>{{ errors.first('editEp.edit_pkg_months') }}</span>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    

    <b-row class="form-group">
      <b-col sm="12" md="2" offset-md="7">
        <b-button block variant="primary" class="btn--2" v-on:click="addEnterpriseModal = true">Add Enterprise</b-button>
      </b-col>
      <b-col sm="12" md="3">
        <b-input-group size="md">
          <input type="search" class="form-control" id="searchQuery" v-model="searchQuery" @search="search()">
          <b-input-group-append>
            <b-button block variant="primary" class="btn--2" :disabled="!searchQuery" v-on:click="search()"><i class="fa fa-search"></i></b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <b-pagination align="center" size="sm" :total-rows="enterprises.totalElements" v-model="currentPage" :per-page="perPage"></b-pagination>

    <b-table :items="enterprises.content" :fields="columnNames" :current-page="1" :per-page="perPage" hover outlined show-empty responsive>
      <template v-slot:cell(active)="row">
        <b-form-checkbox block variant="primary" v-model="row.value" :disabled="true" />
      </template>
      <template v-slot:cell(id)="row">
        <b-button variant="light">{{row.value}}</b-button>
      </template>
      <template v-slot:cell(pkg)="row">
        <b-button variant="secondary" style="width: 100%;">{{row.item.pkg.code}}</b-button>
      </template>
      <template v-slot:cell(name)="row">
        <span v-if="row.value">{{row.value}}</span>
        <span v-if="!row.value">N/A</span>
      </template>
      <template v-slot:cell(slug)="row">
        <span v-if="row.value">{{row.value}}</span>
        <span v-if="!row.value">N/A</span>
      </template>
      <template v-slot:cell(logoUrl)="row">
        <b-button variant="primary" size="sm" v-if="row.value" @click="previewImageModal = true; previewImage = row.value;">Preview</b-button>
        <b-button variant="dark" size="sm" @click="newImageModal = true; newImagePath = row.value;">{{row.value ? 'Change' : 'Add'}}</b-button>
      </template>
      <template v-slot:cell(backgroundUrl)="row">
        <b-button variant="primary" size="sm" v-if="row.value" @click="previewImageModal = true; previewImage = row.value;">Preview</b-button>
        <b-button variant="dark" size="sm" @click="newImageModal = true; newImagePath = row.value;">{{row.value ? 'Change' : 'Add'}}</b-button>
      </template>
      <template v-slot:cell(actions)="row">
        <b-button variant="dark" size="sm" center v-on:click="openEditEnterpriseModal(row.item)">Update</b-button>
        <b-button v-if="row.item.pkg.active" variant="primary" size="sm" center v-on:click="disableEnterprise(row.item)">Disable</b-button>
        <b-button v-if="!row.item.pkg.active" variant="danger" size="sm" center v-on:click="enableEnterprise(row.item)">Enable</b-button>
      </template>
    </b-table>

    <b-pagination align="center" size="sm" :total-rows="enterprises.totalElements" v-model="currentPage" :per-page="perPage"></b-pagination>

    <br>

  </b-card>
</template>

<script>

import moment from 'moment'

export default {
  data () {
    return {
      searchQuery: '',
      addEnterpriseModal: false,
      editEnterpriseModal: false,
      newImageModal: false,
      previewImageModal: false,
      previewImage: null,
      newImage: null,
      newImagePath: null,
      enterprises: [],
      newEp: {
        name: '',
        logoUrl: '',
        backgroundUrl: '',
        slug: '',
        pkg: {
          code: '',
          name: '',
          charge: 0.00,
          vat: 0.00,
          usages: 1,
          quota: 1000,
          months: 12
        }
      },
      editedEp: {
        name: '',
        logoUrl: '',
        backgroundUrl: '',
        slug: '',
        pkg: {
          code: '',
          name: '',
          charge: 0.00,
          vat: 0.00,
          usages: 1,
          quota: 1000,
          months: 12
        }
      },
      columnNames: [
        {key: 'id', label: 'ID'},
        'name',
        {key: 'logoUrl', label: 'Logo'},
        {key: 'backgroundUrl', label: 'Background'},
        {key: 'slug', label: 'URL Identifier'},
        {key: 'pkg.code', label: 'Package Code'},
        {key: 'pkg.name', label: 'Package Name'},
        {key: 'pkg.total', label: 'Total (£)'},
        {key: 'actions', label: 'Action'}
      ],
      currentPage: 1,
      perPage: 10,
      bgFile: null,
      logoFile: null
    }
  },
  filters: {
    dateTime: function (date) {
      return moment(date).local().format('HH:mm - DD/MM/YY')
    }
  },
  created () {
    this.search()
  },
  watch: {
    currentPage: function () {
      this.search()
    }
  },
  methods: {
    urlOf (file) {
      return URL.createObjectURL(file)
    },
    search () {
      return this.$enterpriseService.search(this.searchQuery, this.currentPage - 1, this.perPage).then(enterprises => {
        this.enterprises = enterprises
        console.log(enterprises)
      }).catch(error => {
        this.$awn.alert(error, 'An error occurred')
      })
    },
    resetImageModal (event) {
      console.log(event);
      this.newImage = null
      this.previewImage = null
    },
    validateAndAddEnterprise () {
      this.$validator.validateAll("addEp").then((success) => {
        if (success) {
          this.addEnterprise()
        } else {
          this.$awn.alert('Please check the form above for any missing fields or errors.');
        }
      })
    },
    getRandomString () {
      var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      var result = ''
      for (var i = 0; i < 4; i++) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length))
      }
      return result
    },
    putImage () {
      var key = this.newImagePath.match(/(.*?\/)(img\/.+)$/)[2]
      this.$enterpriseService.uploadFile(this.newImage, key).then(async (res) => {
        console.log(res);
        await this.search()
        this.$awn.success('Image Updated')
      }).finally(() => {
        this.resetImageModal();
      })
    },
    addEnterprise () {
      var randId = this.getRandomString()
      var logoPath = `img/enterprise-${randId}-logo`
      var bgPath = `img/enterprise-${randId}-bg`
      this.newEp.logoUrl = `https://static.thegpservice.co.uk/${logoPath}`
      this.newEp.backgroundUrl = `https://static.thegpservice.co.uk/${bgPath}`
      this.newEp.pkg.active = true
      this.$enterpriseService.createEnterprise(this.newEp).then(res => {
        console.log(res);
        this.$enterpriseService.uploadFile(this.logoFile, logoPath).then(res2 => {
          console.log(res2);
          this.$enterpriseService.uploadFile(this.bgFile, bgPath).then(res3 => {
            console.log(res3);
            this.searchQuery = ''
            this.logoFile = null
            this.bgFile = null
            this.search()
            this.$awn.success('Enterprise Added')
          })
        })
      }).catch(error => {
        console.error(error);
        this.$awn.alert(error, 'An error occurred')
      })
    },
    validateAndEditEnterprise () {
      this.$validator.validateAll("editEp").then((success) => {
        if (success) {
          this.editEnterprise()
        } else {
          this.$awn.alert('Please check the form above for any missing fields or errors.')
        }
      })
    },
    editEnterprise () {
      this.$enterpriseService.editEnterprise(this.editedEp).then(res => {
        console.log(res);
        this.search()
        this.$awn.success('Enterprise Updated')
      }).catch(error => {
        console.error(error);
        this.$awn.alert(error, 'An error occurred')
      })
    },
    preventNumberExponential (event) {
      var iKeyCode = (event.which) ? event.which : event.keyCode
      if (iKeyCode === 101 || iKeyCode === 43 || iKeyCode === 45) { event.preventDefault() }
    },
    openEditEnterpriseModal (enterprise) {
      this.editedEp = {...enterprise}
      this.editEnterpriseModal = true
    },
    enableEnterprise (enterprise) {
      this.editedEp = {...enterprise}
      this.editedEp.pkg.active = true
      this.editEnterprise()
    },
    disableEnterprise (enterprise) {
      this.editedEp = {...enterprise}
      this.editedEp.pkg.active = false
      this.editEnterprise()
    }
  }
}
</script>

<style lang="scss">
  .table td {
    vertical-align: middle;
  }
  .red-text {
    color: red;
    font-size: small;
  }
  .small-input {
    width: fit-content;
  }
  .form-group {
    margin-bottom: 0.75rem;
  }
</style>
