<template>
  <b-card>

    <div slot="header">
      <i class='fa fa-align-justify'></i> Coupons
    </div>

    <b-row class="form-group">
      <b-col sm="12" md="6">
        <b-pagination align="left" size="sm" :total-rows="coupons.totalElements" v-model="currentPage" :per-page="perPage"></b-pagination>
      </b-col>
      <b-col sm="12" md="5" offset-md="1">
        <b-input-group size="md">
          <input type="search" class="form-control" id="searchQuery" v-model="searchQuery" placeholder="Id" @search="getCoupons()">
          <b-input-group-append>
            <b-button block variant="primary" class="btn--2" :disabled="!searchQuery" v-on:click="getCoupons()"><i class="fa fa-search"></i></b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <b-table :items="coupons.content" :fields="columnNames" :current-page="1" :per-page="perPage" hover outlined show-empty responsive>
      <template v-slot:cell(id)="row">
        <span>
          <b-button variant="light" @click="copyToClipboard(row.value)" v-b-tooltip.hover title="Copy coupon">
            {{row.value}} <i class="fa fa-copy fa-copy-hover"></i>
          </b-button>
        </span>
      </template>
      <template v-slot:cell(expirationDate)="row">
        <span v-if="!row.item.toBeUpdated">{{row.value | date}}</span>
        <v-date-picker v-if="row.item.toBeUpdated" v-model="row.item.expirationDate" :lang="lang" type="date" :clearable="false" :editable="false" :format="'DD/MM/YYYY'"></v-date-picker>
      </template>
      <template v-slot:cell(percentage)="row">
        <span v-if="!row.item.toBeUpdated">{{row.value}}%</span>
        <input data-vv-scope="editCoupon" v-if="row.item.toBeUpdated" v-validate="{ required: true, max_value: 100, min_value: 1 }" v-model="row.item.percentage" v-bind:name="'percentage' + row.item.id" v-on:keypress="preventNumberExponential($event)" type="number"/>
        <span class="red-text"><br/>{{ errors.first('editCoupon.percentage' + row.item.id) }}</span>
      </template>
      <template v-slot:cell(usages)="row">
        <span v-if="!row.item.toBeUpdated">{{row.value}}</span>
        <input data-vv-scope="editCoupon" v-if="row.item.toBeUpdated" v-validate="{ required: true, min_value: 0 }" v-model="row.item.usages" v-bind:name="'usages' + row.item.id" v-on:keypress="preventNumberExponential($event)" type="number" min="0"/>
        <span class="red-text"><br/>{{ errors.first('editCoupon.usages' + row.item.id) }}</span>
      </template>
      <template v-slot:cell(reason)="row">
        <div class="d-inline-block text-truncate" style="max-width: 10rem;" :title="row.value">
          <span v-if="!row.item.toBeUpdated">{{row.value}}</span>
        </div>
        <input data-vv-scope="editCoupon" v-if="row.item.toBeUpdated" v-model="row.item.reason" v-bind:name="'reason' + row.item.id" type="text"/>
        <span class="red-text"><br/>{{ errors.first('editCoupon.reason' + row.item.id) }}</span>
      </template>
      <template v-slot:cell(creator)="row">
        <span v-if="!row.item.toBeUpdated">{{row.value}}</span>
        <span v-if="row.item.toBeUpdated">{{ profile.displayName }}</span>
        <span class="red-text"><br/>{{ errors.first('editCoupon.creator' + row.item.id) }}</span>
      </template>
      <template v-slot:head(actions)>
        <b-button variant="primary" @click="openAddCouponsModal()">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Add coupon&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </b-button>
      </template>
      <template v-slot:cell(actions)="data">
        <b-button v-if="!data.item.toBeUpdated" variant="primary" @click="editItem(data.item)">
          Update
        </b-button>
        <b-button v-if="data.item.toBeUpdated" variant="warning" @click="confirmCouponUpdate(data.item)">
          Confirm
        </b-button>
        <b-button v-if="data.item._rowVariant || data.item.toBeUpdated" variant="danger" @click="resetToOriginalState(data.item)">
          &nbsp;Reset
        </b-button>
        <b-button v-if="!data.item._rowVariant && !data.item.toBeUpdated" variant="danger" @click="openDeleteCouponModal(data.item)">
          Delete
        </b-button>
      </template>
    </b-table>

    <b-row class="form-group">
      <b-col sm="12" md="6">
        <b-pagination align="left" size="sm" :total-rows="coupons.totalElements" v-model="currentPage" :per-page="perPage"></b-pagination>
      </b-col>
      <b-col sm="12" md="4" offset-md="2">
        <b-button variant="primary" block @click="openConfirmAllChangesModal()" :disabled="couponsToUpdate.length < 1"> Confirm </b-button>
      </b-col>
    </b-row>

    <br>

    <b-modal v-model="addCouponsModal" centered size="lg" hide-header ok-title="Add coupon" ok-variant="primary" @ok="confirmAllChanges(true)">
      <div class="table-responsive reduced-margin-table">
        <table class="table table-striped table-nowrap">
          <thead>
            <tr>
              <th>ID</th>
              <th>Expiration Date</th>
              <th>Percentage</th>
              <th>Usages</th>
              <th>Reason</th>
              <th>Creator</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(coupon,index) in couponsToAdd" :key="index">
              <td>
                <input data-vv-scope="addCoupon" type="text" v-validate="{ required: true }" v-model="coupon.id" v-bind:name="'id' + index" v-on:keypress="preventNonNumbersOrLetters($event)"/>
                <span class="red-text"><br/>{{ errors.first('addCoupon.id' + index) }}</span>
              </td>
              <td>
                <v-date-picker data-vv-scope="addCoupon" class="small-input" v-validate="{ required: true }" v-bind:name="'expirationDate' + index" v-model="coupon.expirationDate" :lang="lang" type="date" :clearable="false" :editable="false" :format="'DD/MM/YYYY'"></v-date-picker>
                <span class="red-text"><br/>{{ errors.first('addCoupon.expirationDate' + index) }}</span>
              </td>
              <td>
                <input data-vv-scope="addCoupon" class="small-input" v-validate="{ required: true, max_value: 100, min_value: 1 }" v-model="coupon.percentage" v-bind:name="'percentage' + index" v-on:keypress="preventNumberExponential($event)" type="number"/>
                <span class="red-text"><br/>{{ errors.first('addCoupon.percentage' + index) }}</span>
              </td>
              <td>
                <input data-vv-scope="addCoupon" class="small-input" v-validate="{ required: true, min_value: 1 }" v-model="coupon.usages" v-bind:name="'usages' + index" v-on:keypress="preventNumberExponential($event)" type="number" min="0"/>
                <span class="red-text"><br/>{{ errors.first('addCoupon.usages' + index) }}</span>
              </td>
              <td>
                <input data-vv-scope="addCoupon" type="text" v-model="coupon.reason" v-bind:name="'reason' + index"/>
                <span class="red-text"><br/>{{ errors.first('addCoupon.reason' + index) }}</span>
              </td>
              <td>
                <span>{{ profile.displayName }}</span>
                <span class="red-text"><br/>{{ errors.first('addCoupon.creator' + index) }}</span>
              </td>
              <td><i class="huge-icon danger fa fa-times" @click="removeCoupon(coupon)"></i></td>
            </tr>
          </tbody>
          <tfoot align="center">
            <tr><td colspan="7">
             <i class="huge-icon success fa fa-plus-circle" @click="addCoupon()"></i>
            </td></tr>
          </tfoot>
        </table>
      </div>
    </b-modal>
  
    <b-modal v-if="couponToDelete" v-model="deleteCouponModal" hide-footer centered size="sm" title="Confirm deletion">
      <div>
        <b-row>
          <b-col cols="7"><strong><label label-for="couponId">ID</label></strong></b-col>
          <b-col><span name="couponId">{{couponToDelete.id}}</span></b-col>
        </b-row>
        <b-row>
          <b-col cols="7"><strong><label label-for="couponPercentage">Percentage</label></strong></b-col>
          <b-col><span name="couponPercentage">{{couponToDelete.percentage}}</span></b-col>
        </b-row>
        <b-row>
          <b-col cols="7"><strong><label label-for="couponExpirationDate">Expiration Date</label></strong></b-col>
          <b-col><span name="couponExpirationDate">{{couponToDelete.expirationDate | date}}</span></b-col>
        </b-row>
        <b-row>
          <b-col cols="7"><strong><label label-for="couponUsages">Usages</label></strong></b-col>
          <b-col><span name="couponUsages">{{couponToDelete.usages}}</span></b-col>
        </b-row>
        <b-row>
          <b-col cols="7"><strong><label label-for="couponReason">Reason</label></strong></b-col>
          <b-col><span name="couponReason">{{couponToDelete.reason}}</span></b-col>
        </b-row>
        <b-row>
          <b-col cols="7"><strong><label label-for="couponCreator">Creator</label></strong></b-col>
          <b-col><span name="couponCreator">{{couponToDelete.creator}}</span></b-col>
        </b-row>
      </div>
      <b-alert variant="danger" show>
        <p><strong>Please note</strong></p> This coupon will be deleted as soon as you click on the button to delete.
      </b-alert>
      <hr/>
      <div>
        <b-button variant="danger" @click="confirmCouponDeletion()">
          Delete coupon
        </b-button>
        <b-button variant="secondary" @click="deleteCouponModal = false">Cancel</b-button>
      </div>
    </b-modal>

    <b-modal v-model="confirmAllChangesModal" centered size="lg" title="Confirm coupon change" ok-title="Confirm" ok-variant="primary" @ok="confirmAllChanges(false)">
      <div class="table-responsive">
        <table class="table table-striped table-nowrap">
          <thead>
            <tr>
              <th colspan="2">ID</th>
              <th colspan="2">Expiration Date</th>
              <th colspan="2">Percentage</th>
              <th colspan="2">Usages</th>
              <th colspan="2">Reason</th>
              <th colspan="2">Creator</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="coupon in couponsToUpdate" :key="coupon.id" @click="searchForThisCoupon(coupon.id)">
              <td colspan="2">{{ coupon.id }}</td>
              <td v-bind:class="{ 'isDifferentNew': isDifferent(coupon, 'expirationDate') }">{{ coupon.expirationDate | date }}</td>
              <td v-bind:class="{ 'isDifferentOld': isDifferent(coupon, 'expirationDate') }">{{ isDifferent(coupon, 'expirationDate') ? coupon.originalExpirationDate : '' | date }}</td>

              <td v-bind:class="{ 'isDifferentNew': isDifferent(coupon, 'percentage') }">{{ coupon.percentage }}%</td>
              <td v-bind:class="{ 'isDifferentOld': isDifferent(coupon, 'percentage') }">{{ isDifferent(coupon, 'percentage') ? coupon.originalPercentage + '%' : '' }}</td>

              <td v-bind:class="{ 'isDifferentNew': isDifferent(coupon, 'usages') }">{{ coupon.usages }}</td>
              <td v-bind:class="{ 'isDifferentOld': isDifferent(coupon, 'usages') }">{{ isDifferent(coupon, 'usages') ? coupon.originalUsages : '' }}</td>
              <td v-bind:class="{ 'isDifferentNew': isDifferent(coupon, 'reason') }">{{ coupon.reason }}</td>
              <td v-bind:class="{ 'isDifferentOld': isDifferent(coupon, 'reason') }">{{ isDifferent(coupon, 'reason') ? coupon.originalReason : '' }}</td>
              <td v-bind:class="{ 'isDifferentNew': isDifferent(coupon, 'creator') }">{{ coupon.creator }}</td>
              <td v-bind:class="{ 'isDifferentOld': isDifferent(coupon, 'creator') }">{{ isDifferent(coupon, 'creator') ? coupon.originalCreator : '' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
    

  </b-card>
</template>

<script>

import moment from 'moment';

export default {
  components: { },
  data: function () {
    return {
      profile: {},
      searchQuery: '',
      coupons: [],
      columnNames: ['id', 'expirationDate', 'percentage', 'usages', 'reason', 'creator', 'actions'],
      currentPage: 1,
      perPage: 10,
      confirmAllChangesModal: false,
      couponsToUpdate: [],
      addCouponsModal: false,
      couponsToAdd: [],
      deleteCouponModal: false,
      couponToDelete: {},
      lang: {
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
        placeholder: {
          date: 'Select Date',
          dateRange: 'Select Date Range'
        }
      }
    }
  },
  filters: {
    dateTime: function (date) {
      return moment(date).local().format('HH:mm - DD/MM/YY');
    }
  },
  created () {
    this.getCoupons();
    this.getProfile();
  },
  watch: {
    currentPage: function () {
      this.getCoupons();
    }
  },
  methods: {
    getCoupons () {
      this.$couponService.search(this.searchQuery, this.currentPage - 1, this.perPage).then(coupons => {
        this.setupCoupons(coupons);
      }, error => {
        this.$awn.alert(error, 'An error occurred');
      })
    },
    getProfile() {
      this.$userService
        .getProfile()
        .then((profile) => {
          this.profile = profile;
        })
        .catch((error) => {
          this.$awn.alert(error, "An error occurred");
        });
    },
    setupCoupons(coupons) {
      coupons.content.forEach(element => {
        element.expirationDate = moment(element.expirationDate, 'YYYY-MM-DD');
        element.percentage = element.percentage * 100;
        this.$set(element, 'originalId', element.id);
        this.$set(element, 'originalExpirationDate', element.expirationDate);
        this.$set(element, 'originalPercentage', element.percentage);
        this.$set(element, 'originalUsages', element.usages);
        this.$set(element, 'originalReason', element.reason);
        this.$set(element, 'originalCreator', element.creator);
        this.couponsToUpdate.forEach(coupon => {
          if (element.id == coupon.id) {
            element._rowVariant = 'warning';
            element.id = coupon.id;
            element.expirationDate = moment(coupon.expirationDate, "DD/MM/YYYY");
            element.percentage = coupon.percentage;
            element.usages = coupon.usages;
          }
        });
      });
      this.coupons = coupons;
    },
    editItem (item) {
      this.$set(item, 'toBeUpdated', true);
    },
    resetToOriginalState(coupon) {
      coupon.id = coupon.originalId;
      coupon.expirationDate = coupon.originalExpirationDate;
      coupon.percentage = coupon.originalPercentage;
      coupon.usages = coupon.originalUsages;
      coupon.reason = coupon.originalReason;
      coupon.creator = coupon.originalCreator;
      this.$delete(coupon, 'toBeUpdated');
      this.$delete(coupon, '_rowVariant');
      this.$delete(coupon, 'invalid');
      this.couponsToUpdate = this.couponsToUpdate.filter(element => element.id !== coupon.id);
    },
    confirmCouponUpdate (coupon) {
      this.$validator.validateAll("editCoupon").then(result => {
        if (!result) {
          return;
        }
        coupon.creator = this.profile.displayName;
        this.$delete(coupon, 'invalid');
        this.$delete(coupon, 'toBeUpdated');
        if (coupon.originalId != coupon.id || 
            !moment(coupon.originalExpirationDate).isSame(coupon.expirationDate) || 
            coupon.originalPercentage != coupon.percentage ||
            coupon.originalUsages != coupon.usages ||
            coupon.originalReason != coupon.reason ||
            coupon.originalCreator != coupon.create) {
          if (this.couponsToUpdate.length === 0 || !this.couponsToUpdate.some(element => element.id === coupon.id)) {
            this.couponsToUpdate.push(coupon);
          }
          this.$set(coupon,'_rowVariant', 'warning');
        } else {
          this.$delete(coupon, '_rowVariant');
          this.couponsToUpdate = this.couponsToUpdate.filter(element => element.id !== coupon.id);
        }
      })
    },
    openDeleteCouponModal(coupon) {
      this.deleteCouponModal = true;
      this.couponToDelete = coupon;
    },
    confirmCouponDeletion() {
      this.$couponService.deleteCoupon(this.couponToDelete.id).then((result) => {
        console.log(result);
        this.$awn.success('Coupon with ID ' + this.couponToDelete.id + ' deleted successfully', 'Deletion successful');
        this.getCoupons();
      }, (error) => {
        console.error(error);
        this.$awn.alert(error, 'Error deleting');
      })
      this.deleteCouponModal = false;
    },
    searchForThisCoupon(couponId) {
      this.confirmAllChangesModal = false;
      this.searchQuery = couponId;
      this.getCoupons();
    },
    preventNumberExponential(event) {
      var iKeyCode = (event.which) ? event.which : event.keyCode;
      if (iKeyCode == 101 || iKeyCode == 43 || iKeyCode == 45) { event.preventDefault(); }
    },
    preventNonNumbersOrLetters(event) {
      const pattern = /^[a-z0-9]+$/i;
      let inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        event.preventDefault();
      }
    },
    isDifferent(element, property) {
      let originalProperty = "original" + property.replace(/^\w/, prop => prop.toUpperCase());
      if (element[property] != element[originalProperty]) {
        if (element[property] instanceof moment && element[property].isSame(element[originalProperty])) {
          return false;          
        }
        return true;
      }
      return false;
    },
    openConfirmAllChangesModal() {
      this.confirmAllChangesModal = true;
    },
    confirmAllChanges(create) {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return;
        }
        if (create) {
          this.finalList = this.couponsToAdd;
        } else {
          this.finalList = this.couponsToUpdate;
        }
        this.finalList.forEach(item => {
          item.creator = this.profile.displayName;
          item.percentage = (item.percentage / 100).toFixed(2);
          item.expirationDate = `${moment(item.expirationDate).local().format("YYYY-MM-DD")}T00:00:00.000Z`;
        })
        this.$couponService.updateCoupons(this.finalList).then(() => {
          this.$awn.success('Coupons updated', 'Coupons updated successfully');
          if (create) {
            this.couponsToAdd = [];
            this.addCouponsModal = false;
            // if (this.finalList.length === 1) {
            //   this.searchQuery = this.finalList[0].id;
            // }
          } else {
            this.couponsToUpdate = [];
            this.confirmAllChangesModal = false;
          }
          this.getCoupons();
          this.currentPage = 1;
        },(error) => {
          console.error(error);
          if (create) {
            this.confirmAllChangesModal = false;
          } else {
            this.addCouponsModal = false;
          }
          this.$awn.alert('Error', error);
          this.getCoupons();
          this.currentPage = 1;
        });
      });
    },
    openAddCouponsModal() {
      this.addCouponsModal = true;
      this.couponsToAdd = [];
      this.addCoupon();
    },
    addCoupon() {
      this.couponsToAdd.push({ id: '', expirationDate: moment().add(2, 'days'), percentage: 100, usages: 1});
      this.$validator.validateAll("addCoupon")
    },
    removeCoupon(coupon) {
      this.couponsToAdd.pop(coupon);
    },
    copyToClipboard(text) {
      if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
          var textarea = document.createElement("textarea");
          textarea.textContent = text;
          textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
          document.body.appendChild(textarea);
          textarea.select();
          try {
              return document.execCommand("copy");  // Security exception may be thrown by some browsers.
          } catch (ex) {
              console.warn("Copy to clipboard failed.", ex);
              return false;
          } finally {
              document.body.removeChild(textarea);
          }
      }
    }
    
  }
}
</script>

<style lang="scss">
.huge-icon {
  font-size:30px;
  cursor: pointer;
}
.success {
  color: #009344;
}
.success:hover {
  color: #006d32;
}
.danger {
  color: #f86c6b;
}
.danger:hover {
  color: #f64846;
}
.reduced-margin-table {
  margin-bottom: -20px;
  min-height: 20rem;
}
.table-nowrap th, .table-nowrap td {
  white-space: nowrap;
}
.mx-panel thead tr th, .mx-panel tbody tr td {
  padding: 0 !important;
}
.isDifferentNew {
  color: #009344;
  font-weight: bold;
}
.isDifferentOld {
  color: tomato;
  text-decoration: line-through; 
}
.mx-input { 
  max-height: 27px;
  border-radius: 0;
  color: #151b1e;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.mx-calendar-icon {
  height: 18px;
}
#percentage:invalid {
  color: #FFF;
  border-color: red;
  background-color: #f97776;
}
.red-text {
  color: red;
  font-size: small;
}
.small-input {
  width:70%;
}
.table th, .table td {
  vertical-align: middle;
}
</style>

