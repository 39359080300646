<template>
  <footer class="app-footer no-print">
    <span><a href="https://app.thegpservice.co.uk">The GP Service</a> &copy; 2018</span>
  </footer>
</template>
<script>
export default {
  name: 'c-footer'
}
</script>
