
<script>
    const OrderService = {}

    OrderService.install = function (Vue) {

        var orderService = new Object ();

        Object.defineProperty(Vue.prototype, '$orderService', { value: orderService });

        orderService.getOrdersAwaitingAdminDeliveryDetails = function (page = 0, size = 10) {
            var url = '/api/admin/order-items?status=WAITING_FOR_ADMIN_DELIVERY_DETAILS&page=' + page + '&size=' + size + '&sort=sortDate,DESC';
            return Vue.axios.get(url)
            .then(response => { return response.data; })
            .catch(error => {
                this.handleError(error)
                return Promise.reject(error);
            });
        };

        orderService.getPendingAdminOrders = function (page = 0, size = 100) {
            var url = '/api/admin/order-items?status=WAITING_FOR_ADMIN_DELIVERY_DETAILS,AWAITING_ADMIN_ACTION_REJECTED_BY_PHARMACY,PAYMENT_FAILED&page=' + page + '&size=' + size + '&sort=sortDate,DESC';
            return Vue.axios.get(url)
            .then(response => { return response.data; })
            .catch(error => {
                this.handleError(error)
                return Promise.reject(error);
            });
        };
        orderService.getAllowedPharmacyChangeOrders = function (page = 0, size = 10) {
            var allowedStatus = 'WAITING_FOR_DOCTOR_PRESCRIPTION_COMPLETION,WAITING_FOR_PHARMACY_APPROVAL';
            var url = '/api/admin/order-items?status=' + allowedStatus + '&page=' + page + '&size=' + size + '&sort=sortDate,DESC';
            return Vue.axios.get(url)
            .then(response => { return response.data; })
            .catch(error => {
                this.handleError(error)
                return Promise.reject(error);
            });
        };

        orderService.getRejectedByPharmacyOrders = function (page = 0, size = 10) {
            var allowedStatus = 'AWAITING_ADMIN_ACTION_REJECTED_BY_PHARMACY';
            var url = '/api/admin/order-items?status=' + allowedStatus + '&page=' + page + '&size=' + size + '&sort=sortDate,DESC';
            return Vue.axios.get(url)
            .then(response => { return response.data; })
            .catch(error => {
                this.handleError(error)
                return Promise.reject(error);
            });
        };

        orderService.getPaymentFailedOrders = function (page = 0, size = 10) {
            var allowedStatus = 'PAYMENT_FAILED';
            var url = '/api/admin/order-items?status=' + allowedStatus + '&page=' + page + '&size=' + size + '&sort=sortDate,DESC';
            return Vue.axios.get(url)
            .then(response => { return response.data; })
            .catch(error => {
                this.handleError(error)
                return Promise.reject(error);
            });
        };

        orderService.getAllOrders = function (page = 0, size = 10, types = [], prescriberId = null) {
            var url = '/api/admin/order-items?page=' + page + '&size=' + size + '&sort=sortDate,DESC';
            if (prescriberId) {
                url = `${url}&prescriberId=${prescriberId}`
            }
            if (types.length > 0) {
                url = `${url}&types=${types.join(',')}`
            }
            return Vue.axios.get(url)
            .then(response => { return response.data; })
            .catch(error => {
                this.handleError(error)
                return Promise.reject(error);
            });
        };

        orderService.searchOrder = function (orderId, types = [], prescriberId = null) {
            var url = '/api/admin/order-item/' + orderId;
            var d = '?';
            if (prescriberId) {
                url = `${url}${d}prescriberId=${prescriberId}`
                d = "&"
            }
            if (types.length > 0) {
                url = `${url}${d}types=${types.join(',')}`
                d = "&"
            }
            return Vue.axios.get(url)
            .then(response => { return response.data; })
            .catch(error => {
                this.handleError(error)
                return Promise.reject(error);
            });
        };

        orderService.getPatientOrders = function (patientId, page = 0, size = 10) {
            var url = `/api/admin/order-items-by-patient?patientId=${patientId}&page=${page}&size=${size}&sort=id,DESC`;
            return Vue.axios.get(url)
            .then(response => { return response.data; })
            .catch(error => {
                this.handleError(error)
                return Promise.reject(error);
            });
        };

        orderService.getOrder = function (orderId) {
            var url = '/api/admin/order-item/' + orderId;
            return Vue.axios.get(url)
            .then(response => { return response.data })
            .catch(error => {
                this.handleError(error)
                return Promise.reject(error);
            });
        };

        orderService.getPrescription = function (orderId) {
            var url = '/api/admin/prescription?order-item-id=' + orderId;
            return Vue.axios.get(url)
            .then(response => { return response.data; })
            .catch(error => {
                this.handleError(error)
                return Promise.reject(error);
            });
        };

        orderService.setDeliveryDetails = function (orderId, pharmacyId, deliveryType) {
            var url = '/api/admin/order/set-delivery-details';
            var body = {
                orderItemId: orderId,
                deliveryType: deliveryType
            }
            if (pharmacyId) {
                body.pharmacyId = pharmacyId;
            }

            return Vue.axios.put(url, body)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    this.handleError(error);
                    return Promise.reject(error);
                });
        };

        orderService.addDeliveryNotes = function (orderId, deliveryNotes) {
            var url = '/api/admin/order/set-delivery-notes';
            return Vue.axios.put(url, {
                    orderItemId: orderId,
                    notes: deliveryNotes ? deliveryNotes : ""
                }).then(response => {
                    return response;
                })
                .catch(error => {
                    this.handleError(error);
                    return Promise.reject(error);
                });
        };

        orderService.confirmOrderRejection = function (orderId) {
            var url = '/api/admin/order/' + orderId + '/confirm-rejection';

            return Vue.axios.put(url)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    this.handleError(error);
                    return Promise.reject(error);
                });
        };

        orderService.getInvoiceItems = function (orderId) {
            var url = `api/admin/document/${orderId}?type=invoice`;
            return Vue.axios.get(url)
                .then(response => { return response.data })
                .catch(error => {
                    this.handleError(error)
                    return Promise.reject(error);
                });
        };

        orderService.processManualPayment = function (orderId) {
            var url = '/api/admin/order/' + orderId + '/manual-payment';
            return Vue.axios.post(url)
                .then(response => { return response.data; })
                .catch(error => {
                    this.handleError(error);
                    return Promise.reject(error);
                });
        };

        orderService.getGPNotes = function (appointmentId) {
            var url = `api/admin/document/${appointmentId}?type=doctor-note`;
            return Vue.axios.get(url)
                .then(response => { return response.data })
                .catch(error => {
                    this.handleError(error)
                    return Promise.reject(error);
                });
        };

        orderService.getChatGPNotes = function (orderId) {
            var url = `api/admin/document/${orderId}?type=chat-doctor-note`;
            return Vue.axios.get(url)
                .then(response => { return response.data })
                .catch(error => {
                    this.handleError(error)
                    return Promise.reject(error);
                });
        };

        orderService.getSickNotes = function (appointmentId) {
            var url = `api/admin/document/${appointmentId}?type=sick-note`;
            return Vue.axios.get(url)
                .then(response => { return response.data })
                .catch(error => {
                    this.handleError(error)
                    return Promise.reject(error);
                });
        };

        orderService.getChatSickNotes = function (orderId) {
            var url = `api/admin/document/${orderId}?type=chat-sick-note`;
            return Vue.axios.get(url)
                .then(response => { return response.data })
                .catch(error => {
                    this.handleError(error)
                    return Promise.reject(error);
                });
        };

        orderService.getReferrelLetter = function (appointmentId) {
            var url = `api/admin/document/${appointmentId}?type=referral-letter`;
            return Vue.axios.get(url)
                .then(response => { return response.data })
                .catch(error => {
                    this.handleError(error)
                    return Promise.reject(error);
                });
        };

        orderService.getChatReferrelLetter = function (orderId) {
            var url = `api/admin/document/${orderId}?type=chat-referral-letter`;
            return Vue.axios.get(url)
                .then(response => { return response.data })
                .catch(error => {
                    this.handleError(error)
                    return Promise.reject(error);
                });
        };

        orderService.changeAppointment = function(orderId, newAppointmentId) {
          const url = `/api/admin/order-item/${orderId}?action=change-appointment`;
          return Vue.axios.put(url, { appointmentId: newAppointmentId })
              .then(response => ({
                success: true,
                data: response.data
              }))
              .catch(error => this.handleError(error));
        }

        orderService.cancelOrder = function (appointmentId, cancelPaid, cancelReason) {
            var url = '/api/admin/appointment/' + appointmentId + '?action=cancel';
            return Vue.axios.put(url, { additionalNotes: cancelReason, charge: cancelPaid })
                .then(response => { return response.data; })
                .catch(error => {
                    this.handleError(error);
                    return Promise.reject(error);
                });
        };

        orderService.cancelFormOrder = function (orderId, cancelReason) {
            var url = '/api/admin/form/' + orderId + '?action=cancel';
            return Vue.axios.put(url, { additionalNotes: cancelReason })
                .then(response => { return response.data; })
                .catch(error => {
                    this.handleError(error);
                    return Promise.reject(error);
                });
        };

        orderService.resendPrescriptionEmail = function (orderId, email) {
            var url = `api/admin/resend-prescription?email=${email}&orderId=${orderId}`;
            return Vue.axios.get(url)
                .then(response => { return response.data })
                .catch(error => {
                    this.handleError(error)
                    return Promise.reject(error);
                });
        };

        orderService.handleError = function(error) {
            if (error.response) {
              // The request was made and the server responded with a status code that falls out of the range of 2xx
              console.debug(error.response.data);
              console.debug(error.response.status);
              console.debug(error.response.headers);
              return {
                success: false,
                status: error.response.status,
                message: error.response.message,
              }
            } else if (error.request) {
              // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
              // Vue.$router.push('/login')
              console.debug('error.request ', error.request);
              return {
                success: false,
                message: 'Did not receive a response from the server.',
              }
            } else {
              // Something happened in setting up the request that triggered an Error
              // Vue.$router.push('/login')
              console.debug('error', error.request);
              return {
                success: false,
                message: 'Failed to make the request.',
              }
            }
        };

        orderService.getDoctorSignature = function (doctorId) {
            var url = 'api/admin/signature?doctorId=' + doctorId;
            return Vue.axios.get(url)
            .then(response => { return response.data })
            .catch(error => {
                this.handleError(error)
                return Promise.reject(error);
            });
        };
    }

    export default OrderService;
</script>
