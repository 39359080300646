<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6" lg="4" class="login-position">
          <b-card-group>
            <b-card no-body class="p-4 dark-colored">
              <b-card-body>
                <img class="logo" src="/static/img/logo.png" />
                <div v-if="!showOtpInput">
                  <b-input-group class="mb-3">
                    <b-input-group-prepend
                      ><b-input-group-text
                        ><i class="icon-user"></i></b-input-group-text
                    ></b-input-group-prepend>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Username"
                      v-model="username"
                      @keyup.enter="checkCreds()"
                    />
                  </b-input-group>
                  <b-input-group class="mb-4">
                    <b-input-group-prepend
                      ><b-input-group-text
                        ><i class="icon-lock"></i></b-input-group-text
                    ></b-input-group-prepend>
                    <input
                      type="password"
                      class="form-control"
                      placeholder="Password"
                      v-model="password"
                      @keyup.enter="checkCreds()"
                    />
                  </b-input-group>
                </div>
                <div v-if="showOtpInput">
                  <div class="my-2 text-white">
                    Provide the OTP number you generated using the authenticator
                    app
                  </div>
                  <b-input-group class="mt-3 mb-4">
                    <b-form-input
                      type="number"
                      class="form-control text-center otp"
                      placeholder="OTP number"
                      v-model="otp"
                    />
                  </b-input-group>
                </div>
                <div v-if="!showOtpInput">
                  <b-row>
                    <div v-if="response" class="error-message">
                      <p>{{ response }}</p>
                    </div>
                    <b-button
                      variant="primary"
                      :disabled="!username || !password"
                      v-on:click="checkCreds()"
                      v-bind:class="'btn-lg btn-block px-4' + loading"
                    >
                      Login
                    </b-button>
                  </b-row>
                  <router-link class="text-centered" to="/forget-password">
                    Forgot password
                  </router-link>
                </div>
                <div v-if="showOtpInput">
                  <b-row>
                    <div v-if="response" class="error-message">
                      <p>{{ response }}</p>
                    </div>
                    <b-button
                      variant="primary"
                      :disabled="!otp"
                      v-bind:class="'btn-lg btn-block px-4' + loading"
                      v-on:click="verifyOtp()"
                    >
                      Verify
                    </b-button>
                  </b-row>
                </div>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      section: "Login",
      loading: "",
      response: "",
      username: "",
      password: "",
      error: "",
      otp: "",
      userKey: "",
      showOtpInput: false,
    };
  },
  mounted() {
    // issue with template theme where the body's background color is not being updated
    document.body.style.backgroundColor = "#282B30";
  },
  methods: {
    async checkCreds() {
      if (!this.username || !this.password) {
        return;
      }
      this.toggleLoading();
      this.$userService
        .login(this.username, this.password)
        .then((res) => {
          this.response = "";
          if (res?.is2FAEnabled) {
            this.userKey = res.key;
            this.showOtpInput = true;
          } else {
            this.$router.push("/dashboard");
          }
        })
        .catch((error) => {
          if (error.request && error.request.status) {
            this.response = "Invalid credentials";
          }
          if (error.response) {
            if (error.response.data.status === 412) {
              this.$router.push("/reset-password?username=" + this.username);
            }
            if (error.response.data.status === 423) {
              this.response =
                "Your account has been locked due to too many failed login attempts";
            }
          }
          if (!this.response) {
            this.response = "The server is not available";
          }
          this.toggleLoading();
        });
    },
    verifyOtp() {
      if (!this.username || !this.password || !this.userKey) {
        return;
      }
      this.toggleLoading();
      this.$userService
        .verifyOtp(this.username, this.password, this.otp, this.userKey)
        .then(() => {
          this.$router.push("/dashboard");
        })
        .catch((error) => {
          if (error.request && error.request.status) {
            this.response = error.response.data.message || "Invalid OTP number";
          }
          if (error.response) {
            if (error.response.data.status === 412) {
              this.$router.push("/reset-password?username=" + this.username);
            }
            if (error.response.data.status === 423) {
              this.response =
                "Your account has been locked due to too many failed login attempts";
            }
          }
          if (!this.response) {
            this.response = "The server is not available";
          }
          this.toggleLoading();
        });
    },
    toggleLoading() {
      this.loading = this.loading === "" ? "loading" : "";
    },
  },
};
</script>

<style lang="scss">
#website-background {
  background-color: #282b30 !important;
}
.logo {
  margin: auto;
  display: block;
  padding-bottom: 1em;
}
.dark-colored {
  background-color: #292b30;
  border: none;
}
.loginForm .input-group {
  padding-bottom: 1em;
  height: 3em;
}
.input-group input {
  height: 3em;
}
.login-position {
  margin-top: -10%;
}
.text-centered {
  display: grid;
  justify-items: center;
}
</style>
