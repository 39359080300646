<template>
  <b-card>

    <div slot="header">
      <i class='fa fa-align-justify'></i> All Platforms
    </div>

    <b-modal v-model="previewImageModal" hide-footer title="Preview" size="md">
      <b-row>
        <b-col>
          <div class="border" style="display: flex; justify-content: center; align-items: center; height: 150px;">
            <img v-if="Boolean(previewImage)" style="max-height: 100%; max-width: 100%; object-fit: contain;" :src="previewImage">
            </div>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal v-model="newImageModal" title="Update Image" size="md" ok-title="Confirm" ok-variant="primary" @show="resetImageModal($event)" @ok="putImage($event)">
      <b-row>
        <b-col>
          <b-form-group id="newImg" label="Select Image" label-for="new_image">
          <div class="border mb-1" style="display: flex; justify-content: center; align-items: center; height: 150px;">
            <img v-if="Boolean(newImage)" style="max-width: 100%; max-height: 100%; object-fit: contain;" :src="urlOf(newImage)">
          </div>
          <b-form-file accept="image/png, image/jpeg" v-validate="{ required: true }" v-model="newImage" :state="Boolean(newImage)" name="new_image"/>
          <span class="red-text" style="width: 100%; display: block;">{{ errors.first('new_image') }}&nbsp;</span>
        </b-form-group>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal title="Add Platform" v-model="addPlatformModal" centered size="xl" @ok="validateAndSavePlatform($event)">
      <b-row>
        <!-- left panel -->
        <b-col md="12" lg="8">
          <b-row><b-col><h4>Platform Information</h4></b-col></b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group id="id" label="Platform ID" label-for="id">
                <b-form-input name="id" type="text" v-model="platform.id" v-validate="'required'"/>
                <span v-show="errors.has('id')" class="text-danger">{{ errors.first('name') }}</span>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group id="slug" label="URL Identifier" label-for="id">
                <b-form-input name="slug" type="text" v-model="platform.slug"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group id="name" label="Name" label-for="name">
            <b-form-input name="name" type="text" v-model="platform.name" v-validate="'required'"/>
            <span v-show="errors.has('name')" class="text-danger">{{ errors.first('name') }}</span>
          </b-form-group>
          <b-row>
          <b-col cols="6">
          <b-form-group id="logoUrl" label="Logo" label-for="logo_url">
            <div class="border mb-1" style="width: 100%; height: 150px; display: flex; justify-content: center; align-content: center;">
              <img v-if="Boolean(logoFile)" style="max-width: 100%; max-height: 100%; object-fit: contain;" :src="urlOf(logoFile)">
            </div>
            <b-form-file name="logo_url" v-model="logoFile" :state="Boolean(logoFile)" v-validate="'required'" accept="image/png, image/jpeg" />
            <span v-show="errors.has('logo_url')" class="text-danger">{{ errors.first('logo_url') }}</span>
          </b-form-group>
          </b-col>
          <b-col cols="6">
          <b-form-group id="backgroundUrl" label="Background" label-for="background_url">
            <div class="border mb-1" style="width: 100%; height: 150px; display: flex; justify-content: center; align-content: center;">
              <img v-if="Boolean(bgFile)" style="max-width: 100%; max-height: 100%; object-fit: contain;" :src="urlOf(bgFile)">
            </div>
            <b-form-file name="background_url" v-model="bgFile" :state="Boolean(bgFile)" v-validate="'required'" accept="image/png, image/jpeg"/>
            <span v-show="errors.has('background_url')" class="text-danger">{{ errors.first('background_url') }}</span>
          </b-form-group>
          </b-col>
          </b-row>
          <!-- select pharmacies for platform -->
          <b-row><b-col><h4>Select Pharmacies</h4></b-col></b-row>
          <b-row>
            <b-col cols="4">
              <b-form-group id="search_gphc" label="GPHC" label-for="search_gphc">
                <b-form-input name="search_gphc" type="search" @search="searchPharm()" v-model="allPharmGphc"/>
                <span v-show="errors.has('search_gphc')" class="text-danger">{{ errors.first('search_gphc') }}</span>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group id="search_trading_name" label="Trading Name / ID" label-for="search_trading_name">
                <b-form-input name="search_trading_name" @search="searchPharm()" type="search" v-model="allPharmTradingName"/>
                <span v-show="errors.has('search_trading_name')" class="text-danger">{{ errors.first('search_trading_name') }}</span>
              </b-form-group>
            </b-col>
            <b-col cols="2" style="display: flex; flex-direction: column; justify-content: flex-end;">
              <b-button variant="primary" style="margin: 0 0 1.2em;" @click="searchPharm()"><i class="fa fa-search"></i></b-button>
            </b-col>
          </b-row>
          <br>
          <b-pagination align="center" size="sm" :total-rows="allPharm.totalElements" v-model="allPharmCurrentPage" :per-page="allPharmPerPage"></b-pagination>
          <b-table size="md" :items="allPharm.content" :fields="allPharmColumnNames" :current-page="1" :per-page="allPharmPerPage" hover outlined small show-empty responsive>
            <template v-slot:head(action)>
              <b-form-checkbox block variant="primary" :checked="allPharm.content.every(p => selectedPharmIds.includes(p.id))" @change="selectAllPharm($event)" />
            </template>
            <template v-slot:cell(action)="row">
              <b-form-checkbox block variant="primary" :checked="selectedPharmIds.includes(row.item.id)" @change="selectPharm(row.item.id, $event)" />
            </template>
          </b-table>
          <b-pagination align="center" size="sm" :total-rows="allPharm.totalElements" v-model="allPharmCurrentPage" :per-page="allPharmPerPage"></b-pagination>
          <br>
          <span>You have <strong>{{selectedPharmIds.length}}</strong> pharmacies selected</span>
        </b-col>

        <!-- right panel -->
        <b-col md="12" lg="4">
          <!-- appointment prices -->
          <b-row><b-col><h4>Pricing Information</h4></b-col></b-row>
          <b-form-group id="formPrice" label="Form Order" label-for="formPrice">
            <b-form-input name="formPrice" type="text" v-model="platform.formPrice" v-validate="'required'"/>
            <span v-show="errors.has('formPrice')" class="text-danger">{{ errors.first('formPrice') }}</span>
          </b-form-group>
          <br>
          <b-form-group id="appointmentPrice" label="Appointment" label-for="appointmentPrice">
            <b-form-input name="appointmentPrice" type="text" v-model="platform.appointmentPrice" v-validate="'required'"/>
            <span v-show="errors.has('appointmentPrice')" class="text-danger">{{ errors.first('appointmentPrice') }}</span>
          </b-form-group>
          <b-form-group id="appointmentExtensionPrice" label="Appointment Extension" label-for="appointmentExtensionPrice">
            <b-form-input name="appointmentExtensionPrice" type="text" v-model="platform.appointmentExtensionPrice" v-validate="'required'"/>
            <span v-show="errors.has('appointmentExtensionPrice')" class="text-danger">{{ errors.first('appointmentExtensionPrice') }}</span>
          </b-form-group>
          <b-form-group id="appointmentPreAuthPrice" label="Appointment Pre-Auth" label-for="appointmentPreAuthPrice">
            <b-form-input name="appointmentPreAuthPrice" type="text" disabled :value="Number(platform.appointmentPrice) + Number(platform.prescriptionPrice)" v-validate="'required'"/>
            <span v-show="errors.has('appointmentPreAuthPrice')" class="text-danger">{{ errors.first('appointmentPreAuthPrice') }}</span>
          </b-form-group>
          <!-- deliverables -->
          <div>
            <br>
            <b-form-group id="prescriptionPrice" label="Prescription" label-for="prescriptionPrice">
              <b-form-input name="prescriptionPrice" type="text" v-model="platform.prescriptionPrice" v-validate="'required'"/>
              <span v-show="errors.has('prescriptionPrice')" class="text-danger">{{ errors.first('prescriptionPrice') }}</span>
            </b-form-group>
            <b-form-group id="sickNotePrice" label="Fit Note" label-for="sickNotePrice">
              <b-form-input name="sickNotePrice" type="text" v-model="platform.sickNotePrice" v-validate="'required'"/>
              <span v-show="errors.has('sickNotePrice')" class="text-danger">{{ errors.first('sickNotePrice') }}</span>
            </b-form-group>
            <b-form-group id="referralLetterPrice" label="Referral Letter" label-for="referralLetterPrice">
              <b-form-input name="referralLetterPrice" type="text" v-model="platform.referralLetterPrice" v-validate="'required'"/>
              <span v-show="errors.has('referralLetterPrice')" class="text-danger">{{ errors.first('referralLetterPrice') }}</span>
            </b-form-group>
          </div>
          <!-- drug prices -->
          <div>
            <br>
            <b-form-group id="drugMinimumCharge" label="Drug Minimum Charge" label-for="drugMinimumCharge">
              <b-form-input name="drugMinimumCharge" type="text" v-model="platform.drugMinimumCharge" v-validate="'required'" :disabled="true"/>
              <span v-show="errors.has('drugMinimumCharge')" class="text-danger">{{ errors.first('drugMinimumCharge') }}</span>
            </b-form-group>
            <b-form-group id="drugProfessionalFee" label="Drug Professional Fee" label-for="drugProfessionalFee">
              <b-form-input name="drugProfessionalFee" type="text" v-model="platform.drugProfessionalFee" v-validate="'required'" :disabled="true"/>
              <span v-show="errors.has('drugProfessionalFee')" class="text-danger">{{ errors.first('drugProfessionalFee') }}</span>
            </b-form-group>
            <b-form-group id="drugMarkupFactor" label="Drug Markup Factor" label-for="drugMarkupFactor">
              <b-form-input name="drugMarkupFactor" type="text" v-model="platform.drugMarkupFactor" v-validate="'required'" :disabled="true"/>
              <span v-show="errors.has('drugMarkupFactor')" class="text-danger">{{ errors.first('drugMarkupFactor') }}</span>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <b-row class="form-group">
      <b-col sm="12" md="2" offset-md="7">
        <b-button block variant="primary" class="btn--2" @click="addPlatformModal = true">Add Platform</b-button>
      </b-col>
      <b-col sm="12" md="3">
        <b-input-group size="md">
          <input type="search" class="form-control" id="searchQuery" v-model="searchQuery" @search="search()">
          <b-input-group-append>
            <b-button block variant="primary" class="btn--2" @click="search()"><i class="fa fa-search"></i></b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <b-pagination align="center" size="sm" :total-rows="platforms.totalElements" v-model="currentPage" :per-page="perPage"></b-pagination>

    <b-table :items="platforms.content" :fields="columnNames" :current-page="1" :per-page="perPage" hover outlined show-empty responsive>
      <template v-slot:cell(active)="row">
        <b-form-checkbox block variant="primary" v-model="row.value" :disabled="true" />
      </template>
      <template v-slot:cell(id)="row">
        <b-button variant="light">{{row.value}}</b-button>
      </template>
      <template v-slot:cell(name)="row">
        <span v-if="row.value">{{row.value}}</span>
        <span v-if="!row.value">N/A</span>
      </template>
      <template v-slot:cell(slug)="row">
        <span v-if="row.value">{{row.value}}</span>
        <span v-if="!row.value">N/A</span>
      </template>
      <template v-slot:cell(logoUrl)="row">
        <b-button variant="primary" size="sm" v-if="row.value" @click="previewImageModal = true; previewImage = row.value;">Preview</b-button>
        <b-button variant="dark" size="sm" @click="newImageModal = true; newImagePath = row.value;">{{row.value ? 'Change' : 'Add'}}</b-button>
      </template>
      <template v-slot:cell(backgroundUrl)="row">
        <b-button variant="primary" size="sm" v-if="row.value" @click="previewImageModal = true; previewImage = row.value;">Preview</b-button>
        <b-button variant="dark" size="sm" @click="newImageModal = true; newImagePath = row.value;">{{row.value ? 'Change' : 'Add'}}</b-button>
      </template>
      <template v-slot:cell(actions)="data">
        <b-button :to="{ path: '/platform/' + data.item.id}" variant="dark" size="sm" center>Manage</b-button>
      </template>
    </b-table>

    <b-pagination align="center" size="sm" :total-rows="platforms.totalElements" v-model="currentPage" :per-page="perPage"></b-pagination>

    <br>

  </b-card>
</template>

<script>

import moment from 'moment'

export default {
  data () {
    return {
      previewImageModal: false,
      previewImage: null,
      newImageModal: false,
      newImage: null,
      searchQuery: '',
      platforms: [],
      platform: {
        formPrice: 0,
        appointmentPrice: 0,
        appointmentExtensionPrice: 0,
        prescriptionPrice: 0,
        sickNotePrice: 0,
        referralLetterPrice: 0,
        drugMinimumCharge: 10,
        drugMarkupFactor: 0.2,
        drugProfessionalFee: 5
      },
      addPlatformModal: false,
      columnNames: [
        {key: 'id', label: 'ID'},
        'name',
        {key: 'logoUrl', label: 'Logo'},
        {key: 'backgroundUrl', label: 'Background'},
        {key: 'slug', label: 'URL Identifier'},
        {key: 'pharmacyCount', label: 'Pharmacies'},
        {key: 'actions', label: 'Action'}
      ],
      currentPage: 1,
      perPage: 10,
      selectedPharmIds: [],
      allPharm: {
        totalElements: 0,
        content: []
      },
      allPharmColumnNames: [
        { key: 'action' },
        { key: 'id', label: 'ID' },
        { key: 'gphc', label: 'GPhC' },
        { key: 'displayName', label: 'Name' }
      ],
      allPharmGphc: '',
      allPharmTradingName: '',
      allPharmCurrentPage: 1,
      allPharmPerPage: 20,
      bgFile: null,
      logoFile: null
    }
  },
  filters: {
    dateTime: function (date) {
      return moment(date).local().format('HH:mm - DD/MM/YY')
    }
  },
  created () {
    this.search()
  },
  watch: {
    currentPage: function () {
      this.search()
    },
    allPharmCurrentPage: function () {
      this.searchPharmacies()
    }
  },
  methods: {
    resetImageModal (event) {
      console.log(event)
      this.newImage = null
      this.previewImage = null
    },
    putImage (event) {
      console.log(event)
      var key = this.newImagePath.match(/(.*?\/)(img\/.+)$/)[2]
      this.$platformService.uploadFile(this.newImage, key).then(async (res) => {
        console.log(res);
        await this.search()
        this.$awn.success('Image Updated')
      }).finally(() => {
        this.resetImageModal();
      })
    },
    urlOf (file) {
      return URL.createObjectURL(file)
    },
    search () {
      return this.$platformService.search(this.searchQuery, this.currentPage - 1, this.perPage).then(platforms => {
        this.platforms = platforms
        console.log(platforms)
      }).catch(error => {
        this.$awn.alert(error, 'An error occurred')
      })
    },
    searchPharm () {
      this.searchPharmacies()
      this.allPharmCurrentPage = 1
    },
    searchPharmacies () {
      this.$platformService.queryPharmacies(this.allPharmGphc, this.allPharmTradingName, this.allPharmCurrentPage - 1, this.allPharmPerPage).then(res => {
        this.allPharm = res
      })
    },
    selectPharm (idx, event) {
      if (event === false) {
        this.selectedPharmIds = this.selectedPharmIds.filter(i => i !== idx)
      } else {
        this.selectedPharmIds = [...this.selectedPharmIds, idx]
      }
    },
    selectAllPharm (event) {
      var allPharmIds = this.allPharm.content.map(c => c.id)
      if (event === false) {
        this.selectedPharmIds = this.selectedPharmIds.filter(i => !allPharmIds.includes(i))
      } else {
        this.selectedPharmIds = [...this.selectedPharmIds, ...allPharmIds]
      }
    },
    getRandomString () {
      var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      var result = ''
      for (var i = 0; i < 4; i++) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length))
      }
      return result
    },
    validateAndSavePlatform (event) {
      event.preventDefault()
      this.$validator.validateAll().then((success) => {
        if (success) {
          this.platform.pharmacyIds = this.selectedPharmIds
          var randId = this.getRandomString()
          var logoPath = `img/platform-${randId}-logo`
          var bgPath = `img/platform-${randId}-bg`
          this.platform.logoUrl = `https://static.thegpservice.co.uk/${logoPath}`
          this.platform.backgroundUrl = `https://static.thegpservice.co.uk/${bgPath}`
          this.platform.appointmentPreAuthPrice = Number(this.platform.appointmentPrice) + Number(this.platform.prescriptionPrice)
          this.$platformService.createPlatform(this.platform).then(() => {
            this.$platformService.uploadFile(this.logoFile, logoPath).then(res2 => {
              console.log(res2);
              this.$platformService.uploadFile(this.bgFile, bgPath).then(res3 => {
                console.log(res3);
                this.$awn.success('Platform created successfully')
                this.addPlatformModal = false
                this.logoFile = null
                this.bgFile = null
                this.search()
              })
            })
          }).catch((error) => {
            console.log(error.response)
            if (error.response && error.response.data) {
              this.$awn.alert(this.concatErrorMessage(error.response.data))
            } else {
              this.$awn.alert('There was a network error')
            }
          })
        } else {
          this.$awn.alert('Please check the form above for any missing fields or errors.')
        }
      })
    }
  }
}
</script>
