// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import VueAWN from "vue-awesome-notifications";
import VeeValidate from "vee-validate";
import Multiselect from "vue-multiselect";
import VCalendar from "v-calendar";
import axios from "axios";
import * as VueGoogleMaps from "vue2-google-maps";
import moment from "moment";

import App from "./App";
import router from "./router";
import UserService from "./services/UserService";
import OrderService from "./services/OrderService";
import CouponService from "./services/CouponService";
import PlatformService from "./services/PlatformService";
import EnterpriseService from "./services/EnterpriseService";
import PackageService from "./services/PackageService";
import SubscriptionService from "./services/SubscriptionService";
import DoctorService from "./services/DoctorService";
import GPService from "./services/GPService";
import PatientService from "./services/PatientService";
import FormularyService from "./services/FormularyService";
import FormFormularyService from "./services/FormFormularyService";
import PharmacyService from "./services/PharmacyService";
import AdminService from "./services/AdminService";
import PrescriberService from "./services/PrescriberService";
import TwoFactorAuthService from "./services/TwoFactorAuthService";

import "./pipes/DatePipe";
import "./pipes/OrderStatusPipe";
import "./pipes/AppointmentTypePipe";

Vue.use(BootstrapVue);
Vue.use(VeeValidate, {
    fieldsBagName: "veeFields",
    dictionary: {
        en: {
            messages: {
                required: () => "This field is required",
                max_value: (x, y) => "This field has to be less than " + y,
                min_value: (x, y) => "This field has to be more than " + y,
            },
        },
    },
});
Vue.use(VueAWN);
Vue.component("multiselect", Multiselect);
Vue.use(VCalendar, {
    firstDayOfWeek: 2, // Monday
});

Vue.use(VueGoogleMaps, {
    load: {
        // GPS paid key
        key: "AIzaSyBKVd9JQlOKyNqVLcIi2ufHXhFivm3W9qs",
        // Backup key
        // key: "AIzaSyBBhaR4Ys1rjW8aqtNYEhV5UDfF-sXfe78",
        libraries: ["geometry"],
    },
});
Vue.use(UserService);
Vue.use(OrderService);
Vue.use(CouponService);
Vue.use(PlatformService);
Vue.use(EnterpriseService);
Vue.use(PackageService);
Vue.use(SubscriptionService);
Vue.use(DoctorService);
Vue.use(GPService);
Vue.use(PatientService);
Vue.use(FormularyService);
Vue.use(FormFormularyService);
Vue.use(PharmacyService);
Vue.use(AdminService);
Vue.use(PrescriberService);
Vue.use(TwoFactorAuthService);

Vue.mixin({
    data: function() {
        return {
            apiEndpoint: process.env.VUE_APP_GPS_API,
        };
    },
    created() {
        axios.defaults.baseURL = this.apiEndpoint;
    },
});

/* eslint-disable no-new */
var vueApp = new Vue({
    el: "#app",
    router,
    data: function() {
        return {
            refreshing: false,
        };
    },
    methods: {
        refreshToken() {
            this.$userService.refreshToken().then(
                (token) => {
                    axios.defaults.headers.common["Authorization"] =
                        "Bearer " + token;
                },
                (error) => {
                    console.error(error);
                    this.$router.push("/login");
                }
            );
        },
        logout() {
            if (
                this.$route.path !== "/login" &&
                this.$route.path !== "/reset-password" &&
                this.$route.path !== "/forget-password"
            ) {
                clearTimeout(t);
                this.$userService.logout().then(() => {
                    this.$router.push("/login");
                });
            }
        },
    },
    template: "<App/>",
    render: (h) => h(App),
});

router.beforeEach((to, from, next) => {
    var token = localStorage.getItem("id_token");
    var expirationDate = moment(localStorage.getItem("valid_until"));

    if (
        to.matched.some((record) => record.meta.requiresAuth) &&
        !(token && moment().isBefore(expirationDate))
    ) {
        vueApp.logout();
    } else {
        next();
    }
});
var t;
var inactivityHook = function() {
    window.onload = resetTimer;

    document.ontouchstart = resetTimer;
    document.onclick = resetTimer;
    document.onscroll = resetTimer;
    document.onkeypress = resetTimer;

    function resetTimer() {
        var now = moment();
        var token = localStorage.getItem("id_token");
        var expirationDate = moment(localStorage.getItem("valid_until"));

        if (token) {
            if (
                now.isBefore(expirationDate) &&
                now.add(10, "minutes").isAfter(expirationDate)
            ) {
                vueApp.refreshToken();
            }
        } else {
            vueApp.logout();
        }

        if (t) {
            clearTimeout(t);
        }
        t = setTimeout(vueApp.logout, 900000); // 15 minutes
    }
};
inactivityHook();

var resizeHook = function() {
    window.onresize = resize;
    window.onload = resize;

    function resize() {
        var w = Math.max(
            document.documentElement.clientWidth,
            window.innerWidth || 0
        );
        if (w < 992) {
            document.body.classList.add("sidebar-hidden");
        } else {
            document.body.classList.remove("sidebar-hidden");
        }
    }
};
resizeHook();
