<template>
  <div v-if="patient.id">

    <b-card no-title no-body>
      <div class="own-header">
        <b-row>
          <b-col class="display-name" sm="12" md="6">
            <i class="fa fa-align-justify"></i>
            <strong class="header-spacing"> {{patient.title}}. {{patient.firstName}} {{patient.lastName}} </strong>
          </b-col>
          <b-col sm="12" md="6">
            <b-button v-if="!editable" variant="warning" @click="allowChanges()" class="float-right">Update Patient</b-button>
            <b-button v-if="editable" variant="success" @click="updatePatient()" class="float-right">Confirm</b-button>
            <b-button v-if="editable" variant="outline-danger" @click="reset()" class="float-right margin-1-percent">Reset</b-button>
          </b-col>
        </b-row>
      </div>
      <b-card-body>
        <b-row>
          <b-col md="12" lg="6">
            <b-row>
              <b-col sm="12" md="4" lg="12">
                <b-form-group id="id" label="Patient ID" label-for="id">
                  <b-form-input name="id" type="text" v-model="patient.id" disabled />
                </b-form-group>
              </b-col>

            </b-row>
            <b-row>
              <b-col sm="6" md="4" lg="3">
                <b-form-group id="active" label="Active" label-for="active">
                  <b-form-radio-group v-if="editable" :block="true" button-variant="outline-primary" buttons v-model="patient.active" name="active" :options="truthyFalsy"/>
                  <b-button-toolbar v-if="!editable">
                    <b-button v-bind:variant="patient.active ? 'primary' : 'outline-primary'" name="active" type="text" disabled> Yes </b-button>
                    <b-button v-bind:variant="!patient.active ? 'primary' : 'outline-primary'" name="active" type="text" disabled> No </b-button>
                  </b-button-toolbar>
                </b-form-group>
              </b-col>

              <b-col sm="6" md="4" lg="3">
                <b-form-group id="marketingConsent" label="Marketing" label-for="marketingConsent">
                  <b-form-radio-group v-if="editable  && !patientManaged" :block="true" button-variant="outline-primary" buttons v-model="patient.marketingConsent" name="marketing" :options="truthyFalsy"/>
                  <b-button-toolbar v-if="!editable || patientManaged">
                    <b-button v-bind:variant="patient.marketingConsent ? 'primary' : 'outline-primary'" name="active" type="text" disabled> Yes </b-button>
                    <b-button v-bind:variant="!patient.marketingConsent ? 'primary' : 'outline-primary'" name="active" type="text" disabled> No </b-button>
                  </b-button-toolbar>
                </b-form-group>
              </b-col>

              <b-col sm="6" md="4" lg="4">
                <b-form-group id="gender" label="Gender" label-for="gender">
                  <b-form-radio-group v-if="editable" :block="true" button-variant="outline-primary" buttons v-model="patient.gender" name="gender" :options="genderOptions"/>
                  <b-button-toolbar v-if="!editable">
                    <b-button v-bind:variant="patient.gender === 'MALE' ? 'primary' : 'outline-primary'" name="active" type="text" disabled> Male </b-button>
                    <b-button v-bind:variant="patient.gender === 'FEMALE' ? 'primary' : 'outline-primary'" name="active" type="text" disabled> Female </b-button>
                  </b-button-toolbar>
                </b-form-group>
              </b-col>

              <b-col sm="6" md="4" lg="3">
                <b-form-group id="shareNotes" label="Share notes" label-for="shareNotes">
                  <b-form-radio-group v-if="editable && !patientManaged" :block="true" button-variant="outline-primary" buttons v-model="patient.preferences.shareDetailsWithGp" name="shareNotes" :options="truthyFalsy"/>
                  <b-button-toolbar v-if="!editable || patientManaged">
                    <b-button v-bind:variant="patient.preferences.shareDetailsWithGp == 'true' ? 'primary' : 'outline-primary'" name="shareNotes" type="text" disabled> Yes </b-button>
                    <b-button v-bind:variant="patient.preferences.shareDetailsWithGp == 'false' ? 'primary' : 'outline-primary'" name="shareNotes" type="text" disabled> No </b-button>
                  </b-button-toolbar>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col v-if="!isActivePatient && patient.active">
                <b-form-group id="PATIENT_ACCOUNT_ENABLED_REASON" label="Patient Activation Reason" label-for="PATIENT_ACCOUNT_ENABLED_REASON" label-cols="5">
                  <b-form-input name="PATIENT_ACCOUNT_ENABLED_REASON" type="text" v-model="patient.settings.PATIENT_ACCOUNT_ENABLED_REASON" v-validate="'required'" :disabled="!editable"/>
                  <span v-show="errors.has('PATIENT_ACCOUNT_ENABLED_REASON')" class="text-danger">{{ errors.first('PATIENT_ACCOUNT_ENABLED_REASON') }}</span>
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-select v-model="patient.title" :options="titleOptions" class="mb-3" :disabled="!editable"/>

            <b-row>
              <b-col md="12" lg="6">
                <b-form-group id="firstName" label="First Name" label-for="firstName">
                  <b-form-input name="firstName" type="text" v-model="patient.firstName" v-validate="'required'" :disabled="!editable"/>
                  <span v-show="errors.has('firstName')" class="text-danger">{{ errors.first('firstName') }}</span>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="lastName" label="Last Name" label-for="lastName">
                  <b-form-input name="lastName" type="text" v-model="patient.lastName" v-validate="'required'" :disabled="!editable"/>
                  <span v-show="errors.has('lastName')" class="text-danger">{{ errors.first('lastName') }}</span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group id="email" label="Email" label-for="email" label-cols="4">
              <b-form-input name="email" type="text" v-model="patient.email" v-validate="'required|email'" :disabled="!editable"/>
              <span v-show="errors.has('email')" class="text-danger">{{ errors.first('email') }}</span>
            </b-form-group>
            <b-form-group id="phone" label="Phone" label-for="phone" label-cols="4">
              <b-form-input name="phone" type="text" v-model="patient.phone" v-validate="'required'" :disabled="!editable"/>
              <span v-show="errors.has('phone')" class="text-danger">{{ errors.first('phone') }}</span>
            </b-form-group>
            <b-form-group id="dob" label="Date of Birth" label-for="dob" label-cols="4">
              <b-form-input name="dob" type="text" v-model="patient.dateOfBirth" v-validate="'required'" :disabled="!editable"/>
              <span v-show="errors.has('dob')" class="text-danger">{{ errors.first('dob') }}</span>
            </b-form-group>
            <b-form-group id="platform" label="Platform" label-for="platform" label-cols="4">
              <b-form-input name="platform" type="text" v-model="patient.platform" v-validate="'required'" :disabled="true"/>
              <span v-show="errors.has('platform')" class="text-danger">{{ errors.first('platform') }}</span>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group id="line1" label="Line 1" label-for="line1">
              <b-form-input name="line1" type="text" v-model="patient.address.line1" :disabled="!editable"/>
              <span v-show="errors.has('line1')" class="text-danger">{{ errors.first('line1') }}</span>
            </b-form-group>
            <b-form-group id="line2" label="Line 2" label-for="line2">
              <b-form-input name="line2" type="text" v-model="patient.address.line2" :disabled="!editable"/>
            </b-form-group>
            <b-form-group id="county" label="County" label-for="county">
              <b-form-input name="county" type="text" v-model="patient.address.county" :disabled="!editable"/>
            </b-form-group>
            <b-form-group id="city" label="City" label-for="city">
              <b-form-input name="city" type="text" v-model="patient.address.city" :disabled="!editable"/>
              <span v-show="errors.has('city')" class="text-danger">{{ errors.first('city') }}</span>
            </b-form-group>
            <b-form-group id="postcode" label="Postcode" label-for="postcode">
              <b-form-input name="postcode" type="text" v-model="patient.address.postcode" v-validate="'required'" :disabled="!editable"/>
              <span v-show="errors.has('postcode')" class="text-danger">{{ errors.first('postcode') }}</span>
            </b-form-group>
            </b-col>
          </b-row>

          <br/>
          <b-row>
            <b-col>
            <div v-if="patient.settings">
              <b-row>
                <b-col md="4" class="mb-1">
                  <b-form-group id="EXTERNALLY_IDENTIFIED" label="Externally Identified" label-for="EXTERNALLY_IDENTIFIED_BY" label-cols="6" >
                    <b-form-radio-group v-if="editable" button-variant="outline-primary" buttons v-model="patient.settings.EXTERNALLY_IDENTIFIED" name="EXTERNALLY_IDENTIFIED" :options="truthyFalsy"/>
                    <b-button-toolbar>
                      <b-button v-if="!editable" v-bind:variant="patient.settings.EXTERNALLY_IDENTIFIED ? 'primary' : 'outline-primary'" name="active" type="text" disabled> Yes </b-button>
                      <b-button v-if="!editable" v-bind:variant="!patient.settings.EXTERNALLY_IDENTIFIED ? 'primary' : 'outline-primary'" name="active" type="text" disabled> No </b-button>
                    </b-button-toolbar>
                  </b-form-group>
                </b-col>
                <b-col v-if="patient.settings.EXTERNALLY_IDENTIFIED">
                  <b-form-group id="EXTERNALLY_IDENTIFIED_BY" label="Externally Identified By" label-for="EXTERNALLY_IDENTIFIED_BY" label-cols="5">
                    <b-form-input name="EXTERNALLY_IDENTIFIED_BY" type="text" v-model="patient.settings.EXTERNALLY_IDENTIFIED_BY" v-validate="externalIdentificationRule" :disabled="!editable"/>
                    <span v-show="errors.has('EXTERNALLY_IDENTIFIED_BY')" class="text-danger">{{ errors.first('EXTERNALLY_IDENTIFIED_BY') }}</span>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group v-if="patient.settings.LOGIN_ATTEMPTS" id="LOGIN_ATTEMPTS" label="Login attempts" label-for="LOGIN_ATTEMPTS" label-cols="5">
                <b-row>
                  <b-col md="4" class="mb-1">
                    <b-form-input name="LOGIN_ATTEMPTS" type="text" v-model="patient.settings.LOGIN_ATTEMPTS" disabled/>
                  </b-col>
                  <b-col>
                    <b-button block type="button" variant="outline-danger" @click="patient.settings.LOGIN_ATTEMPTS = ''" :disabled="!editable">Clear login attempts</b-button>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group v-if="patient.settings.COUPON_ATTEMPTS" id="COUPON_ATTEMPTS" label="Coupon attempts" label-for="COUPON_ATTEMPTS" label-cols="5">
                <b-row>
                  <b-col md="4" class="mb-1">
                    <b-form-input name="COUPON_ATTEMPTS" type="text" v-model="patient.settings.COUPON_ATTEMPTS" disabled/>
                  </b-col>
                  <b-col>
                    <b-button block type="button" variant="outline-danger" @click="patient.settings.COUPON_ATTEMPTS=''" :disabled="!editable">Clear coupon attempts</b-button>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group id="associateWithAnEnterprise" label="Enterprise" label-for="associateWithAnEnterprise" label-cols="5">
                <b-button v-if="!associateWithAnEnterprise" type="button" block variant="outline-primary" @click="associateWithAnEnterprise = true" :disabled="!editable">Add enterprise</b-button>
                <b-row v-if="associateWithAnEnterprise">
                  <b-col md="4" class="mb-1">
                    <b-form-input name="ENTERPRISE" type="text" v-model="patient.settings.ENTERPRISE" v-validate="enterpriseRule" :disabled="!editable"/>
                    <span v-show="errors.has('ENTERPRISE')" class="text-danger">{{ errors.first('ENTERPRISE') }}</span>
                  </b-col>
                  <b-col>
                    <b-button block type="button" variant="outline-danger" @click="removeEnterprise()" :disabled="!editable">
                      Remove enterprise
                    </b-button>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Admin Key Alert -->
    <b-card no-title no-body>
      <div class="own-header">
        <b-row>
          <b-col class="display-name" sm="12" md="6">
            <i class="fa fa-align-justify"></i>
            <strong class="header-spacing">Admin Key Alert</strong>
          </b-col>
          <b-col sm="12" md="6">
            <b-button v-if="!adminAlertEditable" variant="warning" @click="toggleAdminAlertEditable()" class="float-right">Update Alert</b-button>
            <b-button v-if="adminAlertEditable" variant="success" @click="updateAdminAlert()" class="float-right">Confirm</b-button>
            <b-button v-if="adminAlertEditable" variant="outline-danger" @click="toggleAdminAlertEditable()" class="float-right margin-1-percent">Cancel</b-button>
          </b-col>
        </b-row>
      </div>
      <b-card-body>
        <b-row>
          <b-col sm="12">
            <b-form-group id="adminKeyAlert" label="Information about the patient that all doctors should be aware of:" label-for="adminKeyAlert">
              <b-form-textarea name="adminKeyAlert" type="text" class="text-left"
                               :maxlength="250"
                               v-model="tempAdminAlert" :disabled="!adminAlertEditable"/>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Orders of this patient-->
    <orders-table title="Orders" :orders="orders" :page="currentPage" :action="action" :click-event="clickEvent" :column-names="columnNames"></orders-table>
  </div>
</template>

<script>

import moment from 'moment'
import OrdersTable from '../../components/OrdersTable'
import OrderEventBus from '../../EventBus.js'

export default {
  name: 'Orders',
  components: {
    OrdersTable
  },
  data: function () {
    return {
      patient: {},
      editable: false,
      patientManaged: false,
      isActivePatient: false,
      adminAlertEditable: false,
      tempAdminAlert: '',
      postcodeLocation: {},
      showMap: false,
      truthyFalsy: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ],
      genderOptions: [
        { text: 'Male', value: 'MALE' },
        { text: 'Female', value: 'FEMALE' }
      ],
      titleOptions: [
        { text: 'Mr', value: 'Mr' },
        { text: 'Mrs', value: 'Mrs'},
        { text: 'Miss', value: 'Miss'},
        { text: 'Dr', value: 'Dr'},
        { text: 'Prof', value: 'Prof'},
        { text: 'Ms', value: 'Ms'}
      ],
      associateWithAnEnterprise: false,
      orders: {},
      currentPage: 1,
      action: "<button class='btn btn-sm btn-outline-primary'>Open Order</button>",
      columnNames: ['id', 'creationDate', 'status', 'name', 'doctor', 'pharmacy', 'actions'],
      clickEvent: 'open-order-click',
      newOrderSound: new Audio('/static/message.mp3'),
      timer: ''
    }
  },
  watch: {
    currentPage: function () {
      this.getPatientOrders();
    }
  },
  computed: {
    externalIdentificationRule () {
      return this.patient.settings.EXTERNALLY_IDENTIFIED ? 'required' : '';
    },
    enterpriseRule () {
      return this.associateWithAnEnterprise ? 'required' : '';
    }
  },
  mounted: function () {
    this.getPatient();
    OrderEventBus.$on('currentPage', (currentPage) => {
      this.currentPage = currentPage;
    })
    OrderEventBus.$on(this.clickEvent, (orderId) => {
      this.$router.push('/order/' + orderId)
    })
  },
  methods: {
    getPatient () {
      this.$patientService.getPatient(this.$route.params.patientId).then(patient => {
        this.patient = patient;

        if (this.patient.active) {
          this.isActivePatient = true;
        }

        // DoB conversion
        if(this.patient.dateOfBirth){
          this.patient.dateOfBirth = moment(this.patient.dateOfBirth).format('DD MMM YYYY')
        }

        this.convertSettings();
        this.getPatientOrders();

        this.patientManaged = this.patient.preferences.disabledByPatient === 'true';

        this.tempAdminAlert =  this.patient?.settings?.ADMIN_ALERTS || this.patient?.allAlerts?.admin || this.patient?.adminAlerts || '';
      })
    },
    convertSettings () {
      if (this.patient.settings.EXTERNALLY_IDENTIFIED === "true") {
        this.$set(this.patient.settings, 'EXTERNALLY_IDENTIFIED', true);
      } else {
        this.$set(this.patient.settings, 'EXTERNALLY_IDENTIFIED', false);
      }
      this.associateWithAnEnterprise = this.patient.settings.ENTERPRISE ? true : false;
    },
    allowChanges() {
      this.editable = true;
      this.truthyFalsy.forEach(option => {
        this.$delete(option, 'disabled');
      })
    },
    reset() {
      this.truthyFalsy.forEach(option => {
        this.$set(option, 'disabled');
      })
      this.editable = false;
      this.getPatient();
    },
    updatePatient () {
      this.prepareToUpdate()
      this.$validator.validateAll().then((success) => {
        if (success) {
          // fix dob for backend
          if(this.patient.dateOfBirth){
            this.patient.dateOfBirth = moment(this.patient.dateOfBirth).format('YYYY-MM-DD')
          }

          this.$patientService.updatePatient(this.patient).then(() => {
            this.$router.push('/patients');
            this.$awn.success('Patient updated successfully');
          }).catch((error) => {
            console.log(error.response)
            if (error.response && error.response.data) {
              this.$awn.alert(this.concatErrorMessage(error.response.data.message));
            } else {
              this.$awn.alert('There was a network error');
            }
          });
        } else {
          this.$awn.alert('Please check the form above for any missing fields or errors.');
        }
      })
    },
    prepareToUpdate () {
      this.patient.username = this.patient.email;
      this.patient.password = "qwerty";
      delete this.patient.displayName;
      if (this.patient.settings.EXTERNALLY_IDENTIFIED) {
        this.patient.settings.EXTERNALLY_IDENTIFIED = "true";
      } else {
        this.patient.settings.EXTERNALLY_IDENTIFIED = "false";
        this.patient.settings.EXTERNALLY_IDENTIFIED_BY = "";
      }
      if (this.patient.active && this.patientManaged) {
        this.$set(this.patient.settings, 'PATIENT_ACCOUNT_ENABLED_REASON', this.patient.settings.PATIENT_ACCOUNT_ENABLED_REASON);
      }
    },
    removeEnterprise () {
      this.associateWithAnEnterprise = false;
      this.patient.settings.ENTERPRISE = '';
    },
    concatErrorMessage (message) {
      let errorMessage = message + ". ";
      return errorMessage;
    },
    getPatientOrders () {
      this.$orderService.getPatientOrders(this.patient.id, this.currentPage - 1, 10).then(orders => {
        if (this.orders.totalElements < orders.totalElements) {
          this.newOrderSound.play();
        }
        this.orders = orders;
        this.timer = setTimeout(this.getPatientOrders, 60000);
      }).catch(error => {
        console.log(error);
        this.$awn.alert('There was an error getting orders');
      });
    },
    toggleAdminAlertEditable() {
      if (!this.adminAlertEditable) {
        this.adminAlertEditable = true;
      } else {
        this.tempAdminAlert =  this.patient?.settings?.ADMIN_ALERTS || this.patient?.allAlerts?.admin || this.patient?.adminAlerts || '';
        this.adminAlertEditable = false;
      }
    },
    updateAdminAlert() {
      const existingAdminAlert = this.patient?.settings?.ADMIN_ALERTS || this.patient?.allAlerts?.admin || this.patient?.adminAlerts || '';
      const newAdminAlert = this.tempAdminAlert?.trim();
      if (existingAdminAlert.length === 0 && newAdminAlert.length === 0) {
        return;
      }
      if (newAdminAlert.length > 250) {
        this.$awn.alert('Message too long. Please shorten to 250 characters.');
        return;
      }
      let _request = this.$patientService.updatePatientSetting;
      if (newAdminAlert.length === 0) {
        _request = this.$patientService.removePatientSetting;
      }
      _request(this.patient.id, 'ADMIN_ALERTS', newAdminAlert)
      .then(response => {
        if (response.success) {
          this.$awn.success('Admin alert updated successfully');
          this.adminAlertEditable = false;
        } else {
          this.$awn.alert(response.message);
        }
      }).catch(error => {
        this.$awn.alert(error.message);
      });
    },
  },
  beforeDestroy () {
    clearTimeout(this.timer);
    OrderEventBus.$off();
  },
  filters: {
    formatDoB: function(date){
      return moment(date).format('DD MMM YYYY');
    }
  }
}
</script>

<style lang="scss" scoped>
  .action-buttons {
    display: flex;
    justify-content: flex-end;
  }
  .margin-1-percent {
    margin-right:1%
  }
  #patientIdDescription small {
    margin-top: -1.8%;
  }
  .btn-group .btn + .btn {
    background-color: #009344 !important;
    border-color: #009344 !important;
  }
</style>
