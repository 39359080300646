<template>
    <b-card>
        <div slot="header"><i class="fa fa-align-justify"></i> All GPs</div>

        <b-row class="form-group">
            <b-col sm="12" md="3" offset-md="7">
                <b-input-group size="md">
                    <input
                        type="search"
                        class="form-control"
                        id="searchQuery"
                        v-model="searchQuery"
                        @search="search()"
                        placeholder="Search by ODS code"
                    />
                    <b-input-group-append>
                        <b-button
                            block
                            variant="primary"
                            class="btn--2"
                            :disabled="!searchQuery"
                            v-on:click="search()"
                            ><i class="fa fa-search"></i
                        ></b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>

            <b-col sm="12" md="2">
                <b-button
                    block
                    variant="primary px-2"
                    class="btn--2"
                    @click="openAddGPModal()"
                >
                    Add new GP
                </b-button>
            </b-col>
        </b-row>

        <b-pagination
            align="center"
            size="md"
            :total-rows="gps.totalElements"
            v-model="currentPage"
            :per-page="perPage"
        ></b-pagination>

        <b-table
            :items="gps.content"
            :fields="columnNames"
            :current-page="1"
            :per-page="perPage"
            hover
            outlined
            show-empty
            responsive
        >
            <template v-slot:cell(email)="row">
                <a :href="'mailto:' + row.value" target="_blank">{{
                    row.value
                }}</a>
            </template>
            <template v-slot:cell(active)="row">
                <b-button
                    block
                    :variant="
                        row.value
                            ? 'outline-primary px-0'
                            : 'outline-danger px-0'
                    "
                    class="btn--2"
                    disabled
                >
                    {{ row.value ? "Active" : "Inactive" }}
                </b-button>
            </template>
            <template v-slot:cell(actions)="data">
                <b-dropdown variant="primary" size="sm" center text="Action">
                    <b-dropdown-item @click="editGP(data.item.id)">
                        Edit
                    </b-dropdown-item>
                    <b-dropdown-item @click="promptDelete(data.item.id)">
                        Delete
                    </b-dropdown-item>
                </b-dropdown>
            </template>
        </b-table>

        <b-pagination
            align="center"
            size="md"
            :total-rows="gps.totalElements"
            v-model="currentPage"
            :per-page="perPage"
        ></b-pagination>

        <b-modal
            ref="add-gp-modal"
            :title="selectedGPID ? 'Update GP' : 'Add new GP'"
            @ok="addUpdateGP"
            :ok-title="selectedGPID ? 'Update GP' : 'Add GP'"
            @cancel="clearGP"
            @close="clearGP"
        >
            <b-container fluid>
                <div style="padding: 10px;">
                    <b-form-group
                        id="odsCodeGroup"
                        label="ODS Code"
                        label-for="odsCode"
                        label-cols="9"
                    >
                        <b-form-input
                            name="odsCode"
                            type="text"
                            v-model="gp.odsCode"
                        />
                    </b-form-group>
                    <b-form-group
                        id="organizationIDGroup"
                        label="Organization ID"
                        label-for="organizationID"
                        label-cols="9"
                    >
                        <b-form-input
                            name="organizationID"
                            type="text"
                            v-model="gp.organizationID"
                        />
                    </b-form-group>
                    <b-form-group
                        id="organizationNameGroup"
                        label="Organization Name"
                        label-for="organizationName"
                        label-cols="5"
                    >
                        <b-form-input
                            name="organizationName"
                            type="text"
                            v-model="gp.organizationName"
                        />
                    </b-form-group>
                    <b-form-group
                        id="organisationTypeGroup"
                        label="Organization Type"
                        label-for="organisationType"
                        label-cols="5"
                    >
                        <b-form-input
                            name="organisationType"
                            type="text"
                            v-model="gp.organisationType"
                        />
                    </b-form-group>
                    <b-form-group
                        id="organisationTypeIDGroup"
                        label="Organization Type ID"
                        label-for="organisationTypeID"
                        label-cols="9"
                    >
                        <b-form-input
                            name="organisationTypeID"
                            type="text"
                            v-model="gp.organisationTypeID"
                        />
                    </b-form-group>
                    <b-form-group
                        id="urlGroup"
                        label="URL"
                        label-for="url"
                        label-cols="4"
                    >
                        <b-form-input name="url" type="text" v-model="gp.url" />
                    </b-form-group>
                    <b-form-group
                        id="phoneGroup"
                        label="Phone"
                        label-for="phone"
                        label-cols="5"
                    >
                        <b-form-input
                            name="phone"
                            type="text"
                            v-model="gp.phone"
                        />
                    </b-form-group>
                    <b-form-group
                        id="faxGroup"
                        label="Fax"
                        label-for="fax"
                        label-cols="5"
                    >
                        <b-form-input
                            name="fax"
                            type="text"
                            v-model="gp.fax"
                        />
                    </b-form-group>
                    <b-form-group
                        id="emailGroup"
                        label="Email"
                        label-for="email"
                        label-cols="4"
                    >
                        <b-form-input
                            name="email"
                            type="email"
                            v-model="gp.email"
                        />
                    </b-form-group>
                    <b-form-group
                        id="addressLine1Group"
                        label="Address line 1"
                        label-for="addressLine1"
                        label-cols="3"
                    >
                        <b-form-input
                            name="addressLine1"
                            type="text"
                            v-model="gp.address.line1"
                        />
                    </b-form-group>
                    <b-form-group
                        id="addressLine2Group"
                        label="Address line 2"
                        label-for="addressLine2"
                        label-cols="3"
                    >
                        <b-form-input
                            name="addressLine2"
                            type="text"
                            v-model="gp.address.line2"
                        />
                    </b-form-group>
                    <b-form-group
                        id="addressCityGroup"
                        label="City"
                        label-for="addressCity"
                        label-cols="3"
                    >
                        <b-form-input
                            name="addressCity"
                            type="text"
                            v-model="gp.address.city"
                        />
                    </b-form-group>
                    <b-form-group
                        id="addressCountryGroup"
                        label="Country"
                        label-for="addressCountry"
                        label-cols="3"
                    >
                        <b-form-input
                            name="addressCountry"
                            type="text"
                            v-model="gp.address.county"
                        />
                    </b-form-group>
                    <b-form-group
                        id="addressPostcodeGroup"
                        label="Postcode"
                        label-for="addressPostcode"
                        label-cols="6"
                    >
                        <b-form-input
                            name="addressPostcode"
                            type="text"
                            v-model="gp.address.postcode"
                        />
                    </b-form-group>
                </div>
            </b-container>
        </b-modal>

        <b-modal
            ref="confirm-delete-modal"
            title="Confirm delete GP"
            centered
            class="modal-danger"
            @ok="deleteGP"
            ok-title="Yes"
            ok-variant="outline-danger"
            cancel-title="No"
            cancel-variant="primary"
        >
            <strong class="text-muted">PLEASE NOTE:</strong>
            <span class="text-danger">
                This action is not reversible, are you sure you want to delete
                this GP?
            </span>
        </b-modal>
    </b-card>
</template>

<script>
import moment from "moment";

export default {
    data: function() {
        return {
            searchQuery: "",
            gps: [],
            columnNames: [
                "id",
                "odsCode",
                "organizationName",
                "phone",
                "email",
                "fax",
                "active",
                "actions",
            ],
            currentPage: 1,
            perPage: 10,
            gp: {
                odsCode: "",
                organizationID: "",
                organizationName: "",
                organisationTypeID: "",
                organisationType: "",
                url: "",
                phone: "",
                fax: "",
                email: "",
                address: {
                    line1: "",
                    line2: "",
                    county: "",
                    city: "",
                    postcode: "",
                },
            },
            selectedGPID: "",
        };
    },
    filters: {
        dateTime: function(date) {
            return moment(date)
                .local()
                .format("HH:mm - DD/MM/YY");
        },
    },
    created() {
        this.search();
    },
    watch: {
        currentPage: function() {
            this.search();
        },
    },
    methods: {
        search() {
            this.$gpService
                .search(this.searchQuery, this.currentPage - 1, this.perPage)
                .then((gps) => {
                    this.gps = gps;
                })
                .catch((error) => {
                    this.$awn.alert(error, "An error occurred");
                });
        },
        getGP(id) {
            this.$gpService
                .getGP(id)
                .then((gp) => {
                    this.gp = gp;
                    this.selectedGPID = gp.id;
                })
                .catch((error) => {
                    this.$awn.alert(error, "An error occurred");
                });
        },
        addUpdateGP() {
            if (this.selectedGPID) {
                this.updateGP();
            } else {
                this.addGP();
            }
        },
        addGP() {
            this.$gpService
                .addGP(this.gp)
                .then(() => {
                    this.clearGP();
                    this.search();
                })
                .catch((error) => {
                    this.$awn.alert(error, "An error occurred");
                });
        },
        updateGP() {
            this.$gpService
                .updateGP(this.selectedGPID, this.gp)
                .then(() => {
                    this.selectedGPID = "";
                    this.clearGP();
                    this.search();
                })
                .catch((error) => {
                    this.$awn.alert(error.response.data.message, "An error occurred");
                });
        },
        promptDelete(id) {
            this.selectedGPID = id;
            this.$refs["confirm-delete-modal"].show();
        },
        deleteGP() {
            this.$gpService
                .deleteGP(this.selectedGPID)
                .then(() => {
                    this.selectedGPID = "";
                    this.search();
                })
                .catch((error) => {
                    this.$awn.alert(error, "An error occurred");
                });
        },
        openAddGPModal() {
            this.$refs["add-gp-modal"].show();
        },
        editGP(id) {
            this.getGP(id);
            this.openAddGPModal();
        },
        clearGP() {
            this.gp = {
                odsCode: "",
                organizationID: "",
                organizationName: "",
                organisationTypeID: "",
                organisationType: "",
                url: "",
                phone: "",
                fax: "",
                email: "",
                address: {
                    line1: "",
                    line2: "",
                    county: "",
                    city: "",
                    postcode: "",
                },
            };
        },
    },
};
</script>
