<template>
  <orders-table :title="title" :orders="orders" :page="currentPage" :action="action" :click-event="clickEvent"></orders-table>
</template>

<script>

import OrdersTable from '../../components/OrdersTable'
import OrderEventBus from '../../EventBus.js'

export default {
  components: {
    OrdersTable
  },
  data: function () {
    return {
      title: 'Confirm delivery details',
      orders: {},
      currentPage: 1,
      action: "<button class='btn btn-sm btn-outline-primary'>Confirm</button>",
      clickEvent: 'confirm-delivery-click'
    }
  },
  mounted () {
    this.getOrders();
    OrderEventBus.$on('currentPage', (currentPage) => {
      this.currentPage = currentPage;
    });
    OrderEventBus.$on(this.clickEvent, (orderId) => {
      this.$router.push('/order/confirm-delivery/' + orderId)
    });
  },
  watch: {
    currentPage: function () {
      this.getOrders();
    }
  },
  methods: {
    getOrders () {
      this.$orderService.getOrdersAwaitingAdminDeliveryDetails(this.currentPage - 1, 10).then(orders => {
        this.orders = orders;
        console.log(orders)
      }).catch(error => {
        console.log(error);
        this.$awn.alert('There was an error getting orders');
      });
    },
  },
  beforeDestroy () {
    OrderEventBus.$off();
  }
}
</script>