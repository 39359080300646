
<script>
    const PatientService = {}

    PatientService.install = function (Vue) {

        var patientService = new Object ();

        Object.defineProperty(Vue.prototype, '$patientService', { value: patientService });

        patientService.search = function (searchQuery, page = 0, size = 10) {
            var url;
            if (searchQuery) {
                url = '/api/admin/patient/search?q=' + searchQuery + '&page=' + page + '&size=' + size;
            } else {
                url = '/api/admin/patient/search?page=' + page + '&size=' + size;
            }
            return Vue.axios.get(url)
                .then(response => { return response.data; })
                .catch(error => {
                    this.handleError(error)
                    return Promise.reject(error);
            });
        };

        patientService.searchByEmail = function (searchQuery, page = 0, size = 10) {
            var url;
            if (searchQuery) {
                url = '/api/admin/patient/search?q=' + searchQuery + '&email=true&page=' + page + '&size=' + size;
            } else {
                url = '/api/admin/patient/search?page=' + page + '&size=' + size;
            }
            return Vue.axios.get(url)
                .then(response => { return response.data; })
                .catch(error => {
                    this.handleError(error)
                    return Promise.reject(error);
            });
        };

        patientService.getPatient = function (id) {
            var url = '/api/admin/patient/' + id;
            return Vue.axios.get(url)
                .then(response => { return response.data; })
                .catch(error => {
                    this.handleError(error);
                    return Promise.reject(error);
            });
        };

        patientService.updatePatient = function (patient) {
            var url = '/api/admin/edit-profile/patient';
            return Vue.axios.put(url, patient)
                .then(response => { return response.data; })
                .catch(error => {
                    this.handleError(error);
                    return Promise.reject(error);
            });
        };

        patientService.handleError = function(error) {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.debug(error.response.data);
                console.debug(error.response.status);
                console.debug(error.response.headers);
                return {
                  success: false,
                  message: error.response.message,
                  status: error.response?.status,
                };
            } else if (error.request) {
                // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
                // Vue.$router.push('/login')
                console.debug('error.request ', error.request);
              return {
                success: false,
                message: 'Did not receive a response from the server.',
              };
            } else {
                // Something happened in setting up the request that triggered an Error
                // Vue.$router.push('/login')
                console.debug('error', error.request);
              return {
                success: false,
                message: 'Failed to make the request.',
              }
            }
        };

        patientService.updatePatientSetting = function(patientId, settingKey, settingValue) {
          const url = `/api/admin/patient/${patientId}/setting/${settingKey}`;
          const encodedValue = encodeURI(settingValue);
          return Vue.axios.post(`${url}/${encodedValue}`)
          .then(response => ({
            success: true,
            data: response.data
          }))
          .catch(error => this.handleError(error))
        }

      patientService.removePatientSetting = function(patientId, settingKey) {
        const url = `/api/admin/patient/${patientId}/setting/${settingKey}`;
        return Vue.axios.delete(url)
            .then(response => ({
              success: true,
              data: response.data
            }))
            .catch(error => this.handleError(error))
      }
    }

    export default PatientService;
</script>
