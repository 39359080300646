<template>
    <div>
        <b-card>
            <div slot="header">
                <i class='fa fa-align-justify'></i> <strong class='header-spacing'>Order details</strong>
            </div>
            <b-form-group id="orderIdGroup" label="Order ID" label-for="orderId" label-cols="9">
                <b-form-input name="orderId" type="text" v-model="order.id" disabled/>
            </b-form-group>
            <b-form-group id="orderTypeGroup" label="Type" label-for="orderType" label-cols="8">
                <b-form-input name="orderType" type="text" :value="orderType" disabled/>
            </b-form-group>
            <b-form-group v-if="order.type=='BOOKING' && order.mentalHealthPlatform" id="appointmentTypeGroup" label="Consultation Type" label-for="consultationType" label-cols="8">
                <b-form-input name="consultationType" type="text" v-model="order.mentalHealthPlatform" disabled/>
            </b-form-group>
            <b-form-group id="orderCreationDateGroup" label="Creation Date" label-for="orderCreationDate" label-cols="6">
                <b-form-input name="orderCreationDate" type="text" :value="creationDate" disabled/>
            </b-form-group>
            <b-form-group id="orderName" label="Name" label-for="orderName" label-cols="4">
                <b-form-input name="orderName" type="text" :value="orderName" disabled/>
            </b-form-group>
            <b-form-group id="orderPriceGroup" label="Price" label-for="orderPrice" label-cols="9">
                <b-form-input name="orderPrice" type="text" :value="price" disabled/>
            </b-form-group>
            <b-form-group id="orderDeliveryGroup" label="Delivery Type" label-for="orderDelivery" label-cols="8">
                <b-form-input name="orderDelivery" type="text" v-model="order.deliveryType" disabled/>
            </b-form-group>
            <b-form-group id="orderPaymentGroup" label="Payment Type" label-for="orderPayment" label-cols="9">
                <b-form-input name="orderPayment" type="text" v-model="order.paymentType" disabled/>
            </b-form-group>
            <b-form-group id="orderStatusGroup" label="Status" label-for="orderStatus" label-cols="3">
                <b-form-input name="orderStatus" type="text" v-model="order.status" disabled/>
            </b-form-group>
            <b-form-group id="orderPlatformGroup" label="Platform" label-for="orderPlatform" label-cols="3">
                <b-form-input name="orderPlatform" type="text" v-model="order.platform" disabled/>
            </b-form-group>
            <b-form-group v-if="order.prescriber" id="orderPrescriberGroup" label="Prescriber" label-for="orderPrescriber" label-cols="3">
                <b-form-input name="orderPrescriber" type="text" :value="order.prescriber.name" disabled/>
            </b-form-group>
            <b-form-group v-if="order.workflow && order.workflow.PHARMACY_ASSIGN_TRACKING_NUMBER" id="orderTrackingNumberGroup" label="Tracking Number" label-for="trackingNumber" label-cols="3">
                <b-form-input name="trackingNumber" type="text" v-model="order.workflow.PHARMACY_ASSIGN_TRACKING_NUMBER" disabled/>
            </b-form-group>
            <b-form-group v-if="getCancellationDetails(order)" id="orderCancellationGroup" label="Canceled by" label-for="canceledBy" label-cols="3">
                <b-form-input name="canceledBy" type="text" v-model="getCancellationDetails(order).canceledBy" disabled/>
            </b-form-group>
            <b-form-group v-if="getCancellationDetails(order)" id="orderCancellationGroup" label="Canceled reason" label-for="canceledReason" label-cols="3">
                <b-form-input name="canceledReason" type="text" v-model="getCancellationDetails(order).reason" disabled/>
            </b-form-group>

            <b-row class="notes-btn-container">
              <b-button v-b-modal.gpNotesModal v-if="gpNote">
                GP Note
              </b-button>
              <b-button v-b-modal.sickNotesModal v-if="sickNote">
                Sick Note
              </b-button>
              <b-button v-b-modal.referralLetterModal v-if="refferalLetter">
                Referral Letter
              </b-button>
              <b-button v-b-modal.resendPrescriptionModal v-if="gpNote || gpNoteBase64" @click="openResendPrescriptionModal()">
                Resend Prescription
              </b-button>
            </b-row>

            <b-form-row v-if="order.status === 'BOOKED'" style="display: flex; flex-direction: row; justify-content: flex-end; margin-top: 20px;">
              <b-button v-if="getChangeAppointmentVisibility()" variant="warning"
                        style="margin-right: 20px; color: white;"
                        @click="showChangeAppointmentModal()">
                Change Appointment
              </b-button>
              <b-button variant="danger" style="margin-right: 5px;" @click="showCancelModal()">
                Cancel Order
              </b-button>
            </b-form-row>

             <b-form-row v-if="order.status === 'WAITING_FOR_DOCTOR_APPROVAL' || order.status === 'WAITING_FOR_PHARMACY_APPROVAL' || order.status === 'PROPOSED_BY_DOCTOR'" style="display: flex; flex-direction: row; justify-content: flex-end; margin-top: 20px;">
              <b-button variant="danger" style="margin-right: 5px;" @click="showCancelFormOrderModal()">
                Cancel Order
              </b-button>
            </b-form-row>

        </b-card>
      <b-modal ref="cancel-order-modal" title="Cancel Video Consultation" @ok="cancelOrder" >
        <div style="padding: 10px;">
          <b-form-group label="Please specify the reason why you would like to cancel this consultation:" label-class="cancel-input-label">
            <b-form-radio name="cancelReasonOption" v-model="cancelReason" value="Consultation no longer required">Consultation no longer required</b-form-radio>
            <b-form-radio name="cancelReasonOption" v-model="cancelReason" value="Consultation required at another time">Consultation required at another time</b-form-radio>
          </b-form-group>
          <b-form-group label="Should the patient be charged for this consultation?" label-class="cancel-input-label">
            <b-form-radio name="cancelCharge" v-model="cancelPaid" value="true">Yes</b-form-radio>
            <b-form-radio name="cancelCharge" v-model="cancelPaid" value="false">No</b-form-radio>
          </b-form-group>
          <b-form-group>
            <label for="cancelReason" class="cancel-input-label">Reason for cancellation</label>
            <b-form-textarea id="cancelReason" v-model="cancelNote" class="form-control" name="cancelReason" required></b-form-textarea>
          </b-form-group>
          <b-alert :show="hasFormError" variant="danger">
              <p>Please complete all above fields</p>
          </b-alert>
          <b-alert :show="hasProcessError" variant="danger">
              <p>Error occured in cancelling appointment! - {{processErrorMessage}}</p>
          </b-alert>
                      <!-- <b-form-row style="display: flex; flex-direction: row;">
            <b-button size="sm"  variant="success" @click="hideModal" style="margin-left: auto; margin-right: 5px;">
              Okay
            </b-button>
          </b-form-row> -->
        </div>
      </b-modal>

      <b-modal ref="cancel-form-order-modal" title="Cancel Form Order" @ok="cancelFormOrder" >
        <div style="padding: 10px;">   
          <b-form-group>
            <label for="cancelReason" class="cancel-input-label">Reason for cancellation</label>
            <b-form-textarea id="cancelReason" v-model="cancelNote" class="form-control" name="cancelReason" required></b-form-textarea>
          </b-form-group>
          <b-alert variant="danger" show  v-if="order.repeatFormOrderSubscription">Note: Cancelling this order will cancel the subscription as well</b-alert>
          <b-alert :show="hasFormError" variant="danger">
              <p>Please complete all above fields</p>
          </b-alert>
          <b-alert :show="hasProcessError" variant="danger">
              <p>Error occured in cancelling appointment! - {{processErrorMessage}}</p>
          </b-alert>
        </div>
      </b-modal>

      <b-modal ref="change-appointment-modal" title="Change Appointment Slot"
               @ok="changeAppointment" @cancel="resetChangeAppointmentData" @close="resetChangeAppointmentData">
        <b-container fluid>
          <div style="padding: 10px;">
            <p v-if="isLoadingAppointmentSlots">Loading...</p>
            <p v-if="!isLoadingAppointmentSlots && appointmentDates.length === 0">No slots are available.</p>
            <div v-if="!isLoadingAppointmentSlots && appointmentDates.length > 0">
              <b-form-group label="Please select new appointment slot:" label-class="cancel-input-label">
                <b-form-select v-model="selectedAppointmentDate" :options="appointmentDates"></b-form-select>
              </b-form-group>

              <div v-if="selectedAppointmentDate" style="margin-top: 20px;">
                <p>Please select an appointment time:</p>
                <div style="display: flex; max-height: 200px; overflow-y: scroll;">
                  <div v-for="slot in appointmentSlots[selectedAppointmentDate]" :key="slot.id"
                       v-bind:class="selectedAppointmentSlot === slot.id
                       ? 'new-appointment-slot-selected' : 'new-appointment-slot'"
                       @click="setSelectedAppointment(slot.id)">
                    {{ formatSlotTime(slot.datetime) }}
                  </div>
                </div>
              </div>
            </div>

            <b-alert :show="hasChangeAppointmentFormError" variant="danger">
              <p>Please complete all above fields</p>
            </b-alert>
            <b-alert :show="hasChangeAppointmentProcessError" variant="danger">
              <p>Error occured in changing appointment - {{changeAppointmentProcessErrorMessage}}</p>
            </b-alert>
          </div>
        </b-container>

      </b-modal>

      <b-modal id="gpNotesModal" size="lg" title="GP Note" hide-footer v-if="gpNote">
        <table class="gp-note-table">
          <tr>
            <th>Patient identity confirmed</th>
            <td>
              <b-badge pill variant="primary" v-if="gpNote.details.confirmedPatientId === 'true'">Yes</b-badge>
              <b-badge pill variant="danger" v-else>No</b-badge>
            </td>
          </tr>
          <tr>
            <th>Patient is at registered location</th>
            <td>
              <b-badge pill variant="primary" v-if="gpNote.details.patientAtRegLocation === 'true'">Yes</b-badge>
              <b-badge pill variant="danger" v-else>No</b-badge>
            </td>
          </tr>
          <tr>
            <th>NHS GP details</th>
            <td>
              {{gpNote.details.nhsGPDetails}}
            </td>
          </tr>
          <tr>
            <th>Share details with GP</th>
            <td>
              <b-badge pill variant="primary" v-if="gpNote.details.shareDetailsWithGp === 'true'">Yes</b-badge>
              <b-badge pill variant="danger" v-else>No</b-badge>
            </td>
          </tr>
          <tr>
            <th>Presenting complaint</th>
            <td v-if="gpNote.details.presentingComplaint">
              <div v-html="gpNote.details.presentingComplaint.replace(/\n/g, '<br>')"></div>
            </td>
          </tr>
          <tr>
            <th>Does the patient feel pain</th>
            <td>
              <b-badge pill variant="primary" v-if="gpNote.details.pain === 'true'">Yes</b-badge>
              <b-badge pill variant="danger" v-else>No</b-badge>
            </td>
          </tr>
          <tr v-if="gpNote.details.pain">
            <th>Pain level</th>
            <td>
              {{gpNote.details.painLevel}}
            </td>
          </tr>
          <tr>
            <th>Differential diagnosis</th>
            <td>
              <div v-html="gpNote.details.differentialDiagnosis.replace(/\n/g, '<br>')"></div>
            </td>
          </tr>
          <tr>
            <th>Differential diagnosis notes</th>
            <td>
              <div v-html="gpNote.details.differentialDiagnosisNotes.replace(/\n/g, '<br>')"></div>
            </td>
          </tr>
          <tr>
            <th>Patient referred details</th>
            <td>
              <div v-html="gpNote.details.patientReferredDetails.replace(/\n/g, '<br>')"></div>
            </td>
          </tr>
          <tr>
            <th>Additional Notes</th>
            <td>
              {{gpNote.details.additionNotes}}
            </td>
          </tr>
          <tr>
            <th>Recommended actions</th>
            <td>
              <div v-html="gpNote.details.recommendedActions.replace(/\n/g, '<br>')"></div>
            </td>
          </tr>
          <tr>
            <th>Follow-up consultation</th>
            <td>
              <b-badge pill variant="primary" v-if="gpNote.details.followUpConsultation === 'true'">Yes</b-badge>
              <b-badge pill variant="danger" v-else>No</b-badge>
            </td>
          </tr>
        </table>
      </b-modal>

      <b-modal id="sickNotesModal" title="Sick Note" hide-footer v-if="sickNote">
        <table>
          <tr>
            <th>Condition note</th>
            <td>: {{sickNote.conditionNotes}}</td>
          </tr>
          <tr>
            <th>Fit</th>
            <td>
              : 
              <b-badge pill variant="primary" v-if="sickNote.fit">Yes</b-badge>
              <b-badge pill variant="danger" v-else>No</b-badge>
            </td>
          </tr>
          <tr>
            <th>Re-assessment needed</th>
            <td>
              : 
              <b-badge pill variant="primary" v-if="sickNote.reassessmentNeeded">Yes</b-badge>
              <b-badge pill variant="danger" v-else>No</b-badge>
            </td>
          </tr>
          <tr>
            <th>Workplace adapt</th>
            <td>
              : 
              <b-badge pill variant="primary" v-if="sickNote.workplaceAdapt">Yes</b-badge>
              <b-badge pill variant="danger" v-else>No</b-badge>
            </td>
          </tr>
          <tr>
            <th>Hours altered</th>
            <td>
              : 
              <b-badge pill variant="primary" v-if="sickNote.alteredHours">Yes</b-badge>
              <b-badge pill variant="danger" v-else>No</b-badge>
            </td>
          </tr>
          <tr>
            <th>Duties amended</th>
            <td>
              : 
              <b-badge pill variant="primary" v-if="sickNote.amendedDuties">Yes</b-badge>
              <b-badge pill variant="danger" v-else>No</b-badge>
            </td>
          </tr>
          <tr>
            <th>Validity</th>
            <td>: <b>From</b> {{sickNote.fromDate}} <b>To</b> {{sickNote.expirationDate}}</td>
          </tr>
        </table>
      </b-modal>

      <b-modal id="referralLetterModal" size="lg" title="Refferal Letter" hide-footer v-if="refferalLetter">
        <div class="referrel-letter-content">
          <h6>Reason</h6>
          <p class="my-4">{{refferalLetter.reason}}</p>
          <h6>Speciality</h6>
          <p class="my-4">{{refferalLetter.speciality}}</p>
        </div>
      </b-modal>

      <b-modal id="resendPrescriptionModal" size="lg" title="Resend Pharmacy Prescription Link" hide-footer v-if="showResendPrescriptionModal">
        <div class="referrel-letter-content mb-4">
          <p class="mb-2">Please confirm the email address you want to send the prescription URL to</p>
          <b-input type="email" v-model="emailForPresctiptionUrl"></b-input>
          <p v-if="showInvalidEmailError" class="red-text">Invalid email</p>
        </div>

        <b-button style="margin-left: 5px; float: right;" @click="validateEmail()">
          Send
        </b-button>
        <b-button style="margin-left: 5px; float: right;" @click="changeResendPrescriptionModalStatus(false)">
          Cancel
        </b-button>
      </b-modal>
    </div>
</template>

<script>
import moment from 'moment';
import OrderEventBus from '../EventBus.js';
import publicService from '../services/PublicService';

export default {
  name: 'order-details',
  props: ['order', 'gpNote', 'sickNote', 'refferalLetter'],
  data: function () {
    return {
      cancelReason: null,
      cancelPaid: null,
      cancelNote: "",
      hasFormError: false,
      hasProcessError: false,
      processErrorMessage: "",
      hasChangeAppointmentFormError: false,
      hasChangeAppointmentProcessError: false,
      changeAppointmentProcessErrorMessage: '',
      isLoadingAppointmentSlots: false,
      appointmentDates: [],
      appointmentSlots: {},
      selectedAppointmentDate: null,
      selectedAppointmentSlot: null,
      showResendPrescriptionModal: false,
      showInvalidEmailError: false,
      emailForPresctiptionUrl: ''
    }
  },
  computed: {
    creationDate: function () {
      return moment(this.order.creationDate).local().format('Do MMMM YYYY, HH:mm');
    },
    price: function () {
      return '£' + this.order.price;
    },
    orderType: function () {
      return this.order.type == 'CHAT_ASSESSMENT' ? 'DocNow' : this.order.type;
    },
    orderName: function () {
      return this.order.type == 'CHAT_ASSESSMENT' ? (this.order.chiefComplaint ? this.order.chiefComplaint : 'DocNow') : this.order.name;
    }
  },
  mounted: function () {

  },
  methods: {
    showCancelModal() {
      this.$refs['cancel-order-modal'].show()
    },
    hideCancelModal() {
      this.$refs['cancel-order-modal'].hide()
    },
    showCancelFormOrderModal(){
      this.$refs['cancel-form-order-modal'].show()
    },
    hideCancelFormOrderModal(){
      this.$refs['cancel-form-order-modal'].hide()
    },
    cancelOrder(evt) {
      evt.preventDefault();
      if(this.cancelReason === null || this.cancelPaid === null || this.cancelNote === ""){
        this.hasFormError = true;
        return;
      }

      this.hasFormError = false;
      const cancelReason = this.cancelReason + " - " + this.cancelNote;
      this.$orderService.cancelOrder(this.order.appointment.id, this.cancelPaid, cancelReason)
          .then((response) => {
            console.log(response);
            this.hideCancelModal();
            OrderEventBus.$emit("getOrderDetails", this.order.id);
            this.$awn.success('Appointment cancelled successfully!');
          })
          .catch((error) => {

            this.hasProcessError = true;
            if(error.response){
              this.processErrorMessage = error.response.data.message;
            }

          })
    },
    cancelFormOrder(evt) {
      evt.preventDefault();
      if(this.cancelNote === ""){
        this.hasFormError = true;
        return;
      }

      this.hasFormError = false;
      const cancelReason = this.cancelNote;
      this.$orderService.cancelFormOrder(this.order.id, cancelReason)
          .then((response) => {
            console.log(response);
            this.hideCancelFormOrderModal();
            OrderEventBus.$emit("getOrderDetails", this.order.id);
            this.$awn.success('Form Order cancelled successfully!');
          })
          .catch((error) => {

            this.hasProcessError = true;
            if(error.response){
              this.processErrorMessage = error.response.data.message;
            }

          })
    },
    getCancellationDetails(order) {
      if(order) {
        const canceledTypes = ["CANCELED_BY_ADMIN", "CANCELED_BY_DOCTOR", "CANCELED_BY_PATIENT"];
        const canceled = canceledTypes.includes(order.status);

        if(canceled) {
          if(order.status.includes("DOCTOR")) {
            return {
              cancelled: true,
              canceledBy: `Doctor - ${order.doctor.displayName}`,
              reason: order.workflow.DOCTOR_CANCEL_REASON
            }
          } else if(order.status.includes("ADMIN")) {
            return {
              cancelled: true,
              canceledBy: `Admin - ${order.workflow.ADMIN_CANCEL_NAME}`,
              reason: order.workflow.ADMIN_CANCEL_REASON
            }
          } else if(order.status.includes("PATIENT")) {
            return {
              cancelled: true,
              canceledBy: `Patient - ${order.patient.displayName}`,
              reason: order.workflow.PATIENT_CANCEL_REASON
            }
          }
        }

        return null;
      }

      return null;
    },
    loadAvailableAppointmentSlots() {
      this.isLoadingAppointmentSlots = true;
      publicService.getAppointments()
      .then(response => {
        if (response.success) {
          if (response.data?.length > 0) {
            this.selectedAppointmentDate = null;
            let dates = [{ value: null, text: 'Please select a date', disabled: true }];
            let slots = {};
            for (let i = 0; i < response.data.length; i++) {
              let datetime = moment(response.data[i]['datetime']);
              let date = datetime.format('YYYY-MM-DD');
              if (!dates.some( _date => _date.value === date)) {
                dates.push({ value: date, text: date });
              }
              if (Object.keys(slots).includes(date)) {
                slots[date].push(response.data[i]);
              } else {
                slots[date] = [response.data[i]];
              }
            }
            this.appointmentDates = dates;
            this.appointmentSlots = slots;
          }
        } else {
          this.hasChangeAppointmentProcessError = true;
          this.changeAppointmentProcessErrorMessage = response.message;
        }
      }).catch(error => {
        this.hasChangeAppointmentProcessError = true;
        this.changeAppointmentProcessErrorMessage = error.message;
      }).finally(() => {
        this.isLoadingAppointmentSlots = false;
      });
    },
    getChangeAppointmentVisibility() {
      // only allow changing appointment for bookings, and only if they are in the future.
      return this.order.type === 'BOOKING' && moment(this.order?.appointment?.datetime).isAfter(moment());
    },
    showChangeAppointmentModal() {
      this.loadAvailableAppointmentSlots();
      this.$refs['change-appointment-modal'].show();
    },
    hideChangeAppointmentModal() {
      this.$refs['change-appointment-modal'].hide();
    },
    changeAppointment(event) {
      event.preventDefault(); // stop modal from closing automatically.
      this.changeAppointmentProcessErrorMessage = null;
      this.hasChangeAppointmentFormError =  false;
      this.hasChangeAppointmentProcessError =  false;

      if (!this.selectedAppointmentDate || !this.selectedAppointmentSlot) {
        this.hasChangeAppointmentFormError = true;
        return;
      }

      this.$orderService.changeAppointment(this.order.id, this.selectedAppointmentSlot)
      .then(response => {
        if (response.success) {
          this.$emit('change-appointment'); // notify parent component to reload the order.
          this.$awn.success('Appointment changed successfully.');
          this.resetChangeAppointmentData();
          this.hideChangeAppointmentModal();
        } else {
          this.hasChangeAppointmentProcessError = true;
          this.changeAppointmentProcessErrorMessage = response.message;
        }
      }).catch(error => {
        this.changeAppointmentProcessErrorMessage = error.message;
      });
    },
    setSelectedAppointment(slotId) {
      this.selectedAppointmentSlot = slotId;
      this.hasChangeAppointmentFormError = false;
    },
    resetChangeAppointmentData() {
      this.appointmentDates = [];
      this.appointmentSlots = {};
      this.selectedAppointmentDate = null;
      this.selectedAppointmentSlot = null;
      this.changeAppointmentProcessErrorMessage = null;
      this.hasChangeAppointmentFormError =  false;
      this.hasChangeAppointmentProcessError =  false;
    },
    formatSlotTime(dateTime) {
      return moment(dateTime).format('hh:mm a');
    },
    changeResendPrescriptionModalStatus(status) {
      this.showResendPrescriptionModal = status;
    },
    openResendPrescriptionModal() {
      this.emailForPresctiptionUrl = this.order?.patient?.email;
      this.changeResendPrescriptionModalStatus(true);
    },
    validateEmail() {
      this.showInvalidEmailError = false;
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.emailForPresctiptionUrl)) {
        this.resendPrescriptionEmail();
      } else {
        this.showInvalidEmailError = true;
      }
    },
    resendPrescriptionEmail() {
      this.$orderService.resendPrescriptionEmail(this.order.id, this.emailForPresctiptionUrl)
      .then(response => {
        if (response.status == 'success') {
          this.changeResendPrescriptionModalStatus(false);
          this.$awn.success('Send the prescription successfully.');
        } else {
          this.changeResendPrescriptionModalStatus(false);
          this.$awn.error('Error occurred in resending the prescription URL.', response.message);
        }
      }).catch(error => {
        this.changeResendPrescriptionModalStatus(false);
        this.$awn.error('Error occurred in resending the prescription URL.', error.message);
      });
    }
  }
}
</script>

<style lang="scss">
  .cancel-input-label {
    font-weight: bold;
  }

  .new-appointment-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 80px;
    height: 35px;
    border-radius: 5px;
    border: 2px solid gray;
    margin: 10px;
    cursor: pointer;
    min-height: unset;
  }

  .new-appointment-slot-selected {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 80px;
    height: 35px;
    background-color: #006d32;
    color: white;
    border-radius: 5px;
    border: 2px solid #006d32;
    margin: 10px;
  }

  .notes-btn-container {
    padding: 0 1rem;
    gap: 0.7rem;
    margin-top: 1rem;
  }

  .referrel-letter-content {
    p {
      margin: 0;
    }
  }

  .gp-note-table {
    tr {
      border-bottom: 1px solid #e3e3e3;
    }
  }
</style>
