
<script>
    import Vue from 'vue';

    export default {

        getNearestPharmacies (lat, lng, limit = 10, deliveryOnly = false, memberOnly = false, doesVaccines = 0) {
            var url = `/api/public/nearest-pharmacies?latitude=${lat}&longitude=${lng}`;

            var config = {
                headers: {
                    'X-Origin-Platform': 'ANY',
                }
            }

            if (deliveryOnly || memberOnly) {
                url = `${url}&limit=30`;
            } else {
                url = `${url}&limit=${limit}`;
            }

            if (memberOnly) {
                url = `${url}&member=true`;
            } else {
                url = `${url}&member=false`;
            }

            if (doesVaccines == 1) {
                url = `${url}&doesVaccines=1`;
            }

            return Vue.axios.get(url, config)
                .then(response => {return response.data;});
        },

        getAppointments(page = 0, size = 320) {
          const url = `/api/public/appointments?page=${page}&size=${size}&sort=datetime,asc`;
          return Vue.axios.get(url)
              .then(response => ({
                success: true,
                data: response.data.content,
              }))
              .catch(error => {
                if (error.response) {
                  // The request was made and the server responded with a status code that falls out of the range of 2xx
                  console.debug(error.response);
                  return {
                    success: false,
                    status: error.response.status,
                    message: error.response.message,
                  }
                } else if (error.request) {
                  // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
                  console.debug('error.request ', error.request);
                  return {
                    success: false,
                    message: 'Did not receive a response from the server.',
                  }
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.debug('error', error.request);
                  return {
                    success: false,
                    message: 'Failed to make the request.',
                  }
                }
              });
        },

    }
</script>
