<template>
  <div>
    <b-row>
      <b-col md="12" lg="6">
        <order-details 
          :order="order" 
          :gpNote="gpNote" 
          :sickNote="sickNote" 
          :refferalLetter="refferalLetter" 
          @change-appointment="handleAppointmentChange" 
        />
      </b-col>
      <b-col>
        <patient-details :patient="order.patient" />
      </b-col>
    </b-row>
    <br/>

    <b-card v-if="order && order.workflow">
      <div slot="header">
        <i class='fa fa-align-justify'></i><strong class='header-spacing'>GP Details</strong>
      </div>

      <template v-if="!!patient && !patient.gp">
        <h5>Setup GP to patient</h5>
        <b-form-group
          label="Search GP"
          label-for="gpSearchInput"
          description="Search GP by ODS code"
        >
          <b-form-input
            id="gpSearchInput"
            v-model="gpSearch"
            debounce="500"
            type="text"
            required
          ></b-form-input>
        </b-form-group>

        <div class="text-center" v-if="gpList === null">
          Please wait
        </div>

        <div class="d-flex justify-content-center" v-if="gpList !== null && gpList.length === 0">
          <div>
            <h6>Don't see options to choose?</h6>
            <ul>
              <li>Check if the ODS number is correct</li>
              <li>
                <b-link v-b-modal.addGPModal >Add a GP manually</b-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="text-center" v-if="gpList !== null && gpList.length > 0">
          <b-row :cols="2" class="gp-list-container">
            <b-col v-for="(item, index) in gpList" :key="index">
              <div class="gp-item" :class="{selected : isSelectedGP(item)}" @click="selectedGP = item">
                <p>
                  <b>{{item.organizationName}} {{item.odsCode ? ` (${item.odsCode})` : ''}}</b> <br/>
                  {{getGPContactNumber(item.phone)}} <br/>
                  <i>{{getGPAddress(item.address)}}</i>
                </p>
              </div>
            </b-col>
          </b-row>
          <b-button class="primary pull-right mt-3" @click="setupGPtoPatient" v-if="!!selectedGP">Setup GP to patient</b-button>
        </div>
      </template>

      <template v-if="!!patient && patient.gp">
        <h4>{{patient.gp.organizationName}}</h4>
        <b-row>
          <b-col>
            <h5><u>Details</u></h5>
            <table>
              <tr>
                <th>ODS number</th>
                <td>: {{patient.gp.odsCode}}</td>
              </tr>
              <tr>
                <th>Organization ID</th>
                <td>: {{patient.gp.organizationID}}</td>
              </tr>
              <tr>
                <th>Organization Type</th>
                <td>: {{patient.gp.organisationType}}</td>
              </tr>
              <tr>
                <th>Organization Type ID</th>
                <td>: {{patient.gp.organisationTypeID}}</td>
              </tr>
            </table>
          </b-col>
          <b-col>
            <h5><u>Contact</u></h5>
            <table>
              <tr>
                <th>Address</th>
                <td>: {{getGPAddress(patient.gp.address)}}</td>
              </tr>
              <tr>
                <th>Phone</th>
                <td>: {{getGPContactNumber(patient.gp.phone)}}</td>
              </tr>
              <tr>
                <th>Fax</th>
                <td>
                  : 
                  <template v-if="!!patient.gp.fax">
                    {{patient.gp.fax}}
                  </template>
                  <template v-if="!patient.gp.fax">
                    <b-button size="sm" @click="openUpdateGPModal('fax')">Add Fax</b-button>
                  </template>
                </td>
              </tr>
              <tr>
                <th>Email</th>
                <td>
                  : 
                  <template v-if="!!patient.gp.email">
                    {{patient.gp.email}}
                  </template>
                  <template v-if="!patient.gp.email">
                    <b-button size="sm" @click="openUpdateGPModal('email')">Add Email</b-button>
                  </template>
                </td>
              </tr>
              <tr>
                <th>Website</th>
                <td>: {{patient.gp.url}}</td>
              </tr>
            </table>
          </b-col>
        </b-row>

      </template>

      <template v-if="!!patient && patient.gp">
        <template v-if="order.workflow.GP_NOTE_ACTION_STATUS && order.workflow.GP_NOTE_ACTION_STATUS === 'SUCCESSFUL'">
          <h5><u>Successfully shared GP notes</u></h5>
          <table>
            <tr>
              <th>Shared on</th>
              <td>: {{order.workflow.GP_NOTE_ACTION_DATETIME}}</td>
            </tr>
            <tr>
              <th>Shared method</th>
              <td>: {{order.workflow.GP_NOTE_ACTION}}</td>
            </tr>
          </table>
        </template>
        <template v-else>
          <!-- TODO  v-if="order.status !== 'BOOKED' && (patient.gp.email || patient.gp.fax) && patient.preferences.shareDetailsWithGp === 'true'" -->
          <b-button 
            class="primary pull-right mt-3" 
            @click="onClickShareNotesWithGP" 
            v-if="order.status !== 'BOOKED' && (patient.gp.email) && patient.preferences.shareDetailsWithGp === 'true'"
          >
            Share notes with GP
          </b-button>
        </template>
      </template>

    </b-card>

    <voucher-info v-if="order.coupon" :order="order"/>

    <additional-order-info :order="order"/>

    <prescription-details :order="order" :prescription="prescription"/>

    <b-card v-if="order && order.workflow">
      <div slot="header">
        <i class='fa fa-align-justify'></i><strong class='header-spacing'> Order Delivery Notes</strong>
      </div>
      <b-form-group id="deliveryNotesLabel" label="Delivery Notes" label-for="deliveryNotes" label-cols="5">
        <b-form-textarea class="mb-1" name="deliveryNotes" v-model="order.workflow.PHARMACY_DELIVER_NOTES" :rows="3" :max-rows="3"/>
      </b-form-group>
      <b-button class="primary pull-right" @click="addDeliveryNotes()">Add delivery notes</b-button>
    </b-card>

    <b-card v-if="prescription && prescription.id">
      <div slot="header">
        <i class='fa fa-align-justify'></i><strong class='header-spacing'> Delivery details</strong>
      </div>

      <b-row>
        <b-col md="12" xl="4">
          <br/>
          <b-form-group label="How would the patient like to receive his medication?" label-for="basicRadiosCustom" label-class="question-title">
            <b-form-radio-group v-if="order.formType" v-model="deliveryOnly" stacked disabled>
              <b-form-radio value="true" @click.native="findPharmacies()">Collected from local pharmacy</b-form-radio>
              <b-form-radio value="false" @click.native="findPharmacies()">Delivered from local pharmacy</b-form-radio>
            </b-form-radio-group>
            <b-form-radio-group v-if="!order.formType" v-model="deliveryOnly" stacked>
              <b-form-radio value="false" @click.native="findPharmacies()">Collected from local pharmacy</b-form-radio>
              <b-form-radio value="true" @click.native="findPharmacies()">Delivered from local pharmacy</b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <br/>

          <div v-if="!order.formType" class="form_group form_group--nested">
            <b-form-group label="Enter your postcode to select your pharmacy:" label-for="postcode" label-class="question-title">
              <b-form-group id="pharmacyIdDescription" description="This field has been pre-populated with the order's pharmacy postcode">
                <b-row class="form-group">
                  <b-col cols="9">
                    <b-input-group class="no-padding">
                      <b-form-input type="search" placeholder="Enter your postcode" v-model="postcode" name="postcode" @search="findPharmacies()" style="min-width:35%"/>
                      <b-input-group-append>
                        <b-button variant="primary" class="btn btn--2" v-on:click="findPharmacies()" style="min-width:30%">Find pharmacies</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-form-group>
          </div>

          <div v-if="order.formType" class="form_group form_group--nested">
            <b-form-group label="Selected postcode for your pharmacy" label-for="postcode" label-class="question-title">
              <b-form-group id="pharmacyIdDescription" description="This field has been pre-populated with the order's pharmacy postcode">
                <b-row class="form-group">
                  <b-col cols="9">
                    <b-input-group class="no-padding">
                      <b-form-input type="text" v-model="postcode" name="postcode" @search="findPharmacies()" style="min-width:35%" disabled/>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-form-group>
          </div>

          <br/>

          <div class="form_group form_group--nested">
            <b-form-group v-if="!order.formType" label="Enter a Pharmacy ID or pick a pharmacy using the map:" label-for="pharmacyId" label-class="question-title">
              <b-form-group id="pharmacyIdDescription" description="This field has been pre-populated with the order's pharmacy ID">
                <b-row class="form-group">
                  <b-col cols="9">
                    <b-input-group class="no-padding" style="min-width:100%">
                      <b-form-input type="search" placeholder="Enter a Pharmacy ID" v-model="pharmacyId" name="pharmacyId" @search="findPharmacies()"/>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-form-group>

            <b-form-group v-if="order.formType" label="Selected Pharmacy ID" label-for="pharmacyId" label-class="question-title">
              <b-form-group id="pharmacyIdDescription" description="This field has been pre-populated with the order's pharmacy ID">
                <b-row class="form-group">
                  <b-col cols="9">
                    <b-input-group class="no-padding" style="min-width:100%">
                      <b-form-input type="text" v-model="pharmacyId" name="pharmacyId" @search="findPharmacies()" disabled/>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-form-group>
          </div>
        </b-col>

        <b-col v-if="!order.formType">
          <p v-if="findPharmacyButtonClicked && !showMap">
            <br /><br /><br />
            Searching...
          </p>
          <div v-if="showMap" class="map_canvas">
              <gmap-map :center="postCodeLocation" ref="map" :zoom="9" style="width: 100%; height: 450px">
                <gmap-marker v-for="p in pharmacies" :key="p.id" @click="selectPharmacy(p)" :position="{lat: p.latitude, lng: p.longitude}" :icon="'https://cdn2.iconfinder.com/data/icons/color-svg-vector-icons-2/512/map_marker_base-48.png'" :opacity="p.member && p.active ? 1 : 0.3">
                  <gmap-info-window :opened="pharmacyId === p.id">
                    <label class="mb-0 text-primary"><h5>{{p.name}}</h5></label> <br/>
                    <span v-if="p.active && p.memberType === 'GOLD'" class="text-primary"><label><strong>Gold Member Pharmacy</strong></label><br/></span>
                    <span v-if="p.active && p.memberType === 'SILVER'" class="text-primary"><label><strong>Silver Member Pharmacy</strong></label><br/></span>
                    <span v-if="p.memberType === 'NONE'" class="text-danger"><label><strong>Non-Member Pharmacy</strong></label><br/></span>
                    <label><strong>Pharmacy ID:</strong> {{p.id}}</label><br/>
                    <label><strong>Email:</strong> {{p.email}}</label><br/>
                    <label><strong>Phone:</strong> {{p.phone}}</label><br/>
                    <b-row v-if="p.address">
                      <b-col cols="4" class="pr-0"><strong>Address:</strong></b-col>
                      <b-col class="pl-0" style="margin-left: -6%">
                        <span><label>{{p.address.line1}} {{p.address.line2}}</label><br/></span>
                        <span v-if="p.address.county"><label>{{p.address.county}}</label><br/></span>
                        <span><label>{{p.address.city}} - {{p.address.postcode}}</label></span>
                      </b-col>
                    </b-row>
                    <span v-if="p.preferences && p.preferences.openingTimes">
                      <label><strong>Opening times:</strong> {{p.preferences.openingTimes}} <br/></label>
                    </span>
                    <span v-if="deliveryOnly">
                      {{p.deliveryNotes}} <br/>
                      <label v-if="p.preferences && p.preferences.deliveryCharge"><strong>Delivery charge:</strong> {{p.preferences.deliveryCharge}}<br/></label>
                    </span>
                    <span v-if="p.settings && p.settings.DOES_VACCINES">
                      <br/>
                      <label><strong>Does vaccines: </strong>Yes</label>
                    </span>
                  </gmap-info-window>
                </gmap-marker>
              </gmap-map>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-3" v-if="!order.formType">
          <b-col xs="6" md="4" class="mb-1">
            <b-button type="button" variant="outline-danger" class="btn-block" @click="reset()">Reset</b-button>
          </b-col>
          <b-col xs="6" md="4">
            <b-button type="button" variant="primary" class="btn-block" @click="confirmModal = true">Confirm</b-button>
          </b-col>
        </b-row>
    </b-card>

    <b-modal title="Confirm delivery details" centered class="modal-success" v-model="confirmModal" @ok="confirm()" ok-title="Yes" ok-variant="success" cancel-title="No" cancel-variant="outline-danger">
        <strong class="text-muted">PLEASE NOTE:</strong>
        <span class="text-danger">This action is not reversible, are you sure you want to confirm this order's delivery details?</span>
    </b-modal>

    <b-modal 
      id="addGPModal" 
      title="Add GP" 
      ok-title='Add GP'
      @ok="addGP"
      @cancel="clearGP" 
      @close="clearGP"
    >
      <b-container fluid>
        <div style="padding: 10px;">
          <b-form-group
              id="odsCodeGroup"
              label="ODS Code"
              label-for="odsCode"
              label-cols="9"
          >
              <b-form-input
                  name="odsCode"
                  type="text"
                  v-model="gp.odsCode"
              />
          </b-form-group>
          <b-form-group
              id="organizationIDGroup"
              label="Organization ID"
              label-for="organizationID"
              label-cols="9"
          >
              <b-form-input
                  name="organizationID"
                  type="text"
                  v-model="gp.organizationID"
              />
          </b-form-group>
          <b-form-group
              id="organizationNameGroup"
              label="Organization Name"
              label-for="organizationName"
              label-cols="5"
          >
              <b-form-input
                  name="organizationName"
                  type="text"
                  v-model="gp.organizationName"
              />
          </b-form-group>
          <b-form-group
              id="organizationTypeGroup"
              label="Organization Type"
              label-for="organizationType"
              label-cols="5"
          >
              <b-form-input
                  name="organizationType"
                  type="text"
                  v-model="gp.organisationType"
              />
          </b-form-group>
          <b-form-group
              id="organizationTypeIDGroup"
              label="Organization Type ID"
              label-for="organizationTypeID"
              label-cols="9"
          >
              <b-form-input
                  name="organizationTypeID"
                  type="text"
                  v-model="gp.organisationTypeID"
              />
          </b-form-group>
          <b-form-group
              id="urlGroup"
              label="URL"
              label-for="url"
              label-cols="4"
          >
              <b-form-input name="url" type="text" v-model="gp.url" />
          </b-form-group>
          <b-form-group
              id="phoneGroup"
              label="Phone"
              label-for="phone"
              label-cols="5"
          >
              <b-form-input
                  name="phone"
                  type="number"
                  v-model="gp.phone"
              />
          </b-form-group>
          <b-form-group
              id="faxGroup"
              label="Fax"
              label-for="fax"
              label-cols="5"
          >
              <b-form-input
                  name="fax"
                  type="number"
                  v-model="gp.fax"
              />
          </b-form-group>
          <b-form-group
              id="emailGroup"
              label="Email"
              label-for="email"
              label-cols="4"
          >
              <b-form-input
                  name="email"
                  type="email"
                  v-model="gp.email"
              />
          </b-form-group>
          <b-form-group
              id="addressLine1Group"
              label="Address line 1"
              label-for="addressLine1"
              label-cols="3"
          >
              <b-form-input
                  name="addressLine1"
                  type="text"
                  v-model="gp.address.line1"
              />
          </b-form-group>
          <b-form-group
              id="addressLine2Group"
              label="Address line 2"
              label-for="addressLine2"
              label-cols="3"
          >
              <b-form-input
                  name="addressLine2"
                  type="text"
                  v-model="gp.address.line2"
              />
          </b-form-group>
          <b-form-group
              id="addressCityGroup"
              label="City"
              label-for="addressCity"
              label-cols="3"
          >
              <b-form-input
                  name="addressCity"
                  type="text"
                  v-model="gp.address.city"
              />
          </b-form-group>
          <b-form-group
              id="addressCountryGroup"
              label="Country"
              label-for="addressCountry"
              label-cols="3"
          >
              <b-form-input
                  name="addressCountry"
                  type="text"
                  v-model="gp.address.county"
              />
          </b-form-group>
          <b-form-group
              id="addressPostcodeGroup"
              label="Postcode"
              label-for="addressPostcode"
              label-cols="6"
          >
              <b-form-input
                  name="addressPostcode"
                  type="text"
                  v-model="gp.address.postcode"
              />
          </b-form-group>
        </div>
      </b-container>
    </b-modal>

    <b-modal 
      id="updateGPRecord" 
      title="Update GP Record" 
      v-model="showGPUpdateModal"
      :ok-title="gpUpdateFieldType === 'email' ? 'Set email to GP' : 'Set fax to GP'"
      @ok="updateGPContact"
      @cancel="clearGPContact" 
      @close="clearGPContact"
    >
      <template v-if="gpUpdateFieldType === 'email'">
        <b-form-group
          label="Email address:"
        >
          <b-form-input
            v-model="gpField"
            type="email"
            placeholder="Enter email"
            required
          ></b-form-input>
        </b-form-group>
      </template>
      <template v-if="gpUpdateFieldType === 'fax'">
        <b-form-group
          label="Fax:"
        >
          <b-form-input
            v-model="gpField"
            type="text"
            placeholder="Enter fax"
            required
          ></b-form-input>
        </b-form-group>
      </template>
    </b-modal>
  </div>

</template>

<script>

import moment from 'moment'
import publicService from '../../services/PublicService'
import { gmapApi } from 'vue2-google-maps'
import OrderDetails from '../../components/OrderDetails'
import PatientDetails from '../../components/PatientDetails'
import PrescriptionDetails from '../../components/PrescriptionDetails'
import AdditionalOrderInfo from "../../components/AdditionalOrderInfo";
import VoucherInfo from "../../components/VoucherInfo";
import OrderEventBus from '../../EventBus.js'

export default {
  name: 'Orders',
  components: {
    OrderDetails,
    PatientDetails,
    PrescriptionDetails,
    AdditionalOrderInfo,
    VoucherInfo,
  },
  data: function () {
    return {
      order: {},
      prescription: {},
      patient: {},
      gpList: null,
      totalGPPages: 0,
      currentPatientPage: 0,
      gpSearch: '',
      selectedGP: null,
      gp: {
          odsCode: "",
          organizationID: "",
          organizationName: "",
          organisationTypeID: "",
          organisationType: "",
          url: "",
          phone: "",
          fax: "",
          email: "",
          address: {
              line1: "",
              line2: "",
              county: "",
              city: "",
              postcode: "",
          },
      },
      gpUpdateFieldType: null,
      gpField: '',
      showGPUpdateModal: false,
      gpNote: null,
      sickNote: null,
      refferalLetter: null,
      showPrescription: false,
      travelPicker: false,
      deliveryOnly: false,
      doesVaccines: false,
      memberType: 'NONE',
      postcode: '',
      findPharmacyButtonClicked: false,
      bounds: {},
      showMap: false,
      pharmacies: [],
      pharmacyId: 0,
      confirmModal: false,
      antimalarialPrescriptionFields: [
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'dosageStartDate',
          label: 'Start Date',
          tdClass: 'text-nowrap'
        },
        {
          key: 'dosageEndDate',
          label: 'End Date',
          tdClass: 'text-nowrap'
        },
        {
          key: 'dosageInstructions',
          label: 'Instructions'
        },
        {
          key: 'dosageExtraInfo',
          label: 'Extra Information',
        },
        {
          key: 'price',
          label: 'Price',
          tdClass: 'text-nowrap'
        }
      ],
      vaccinationPrescriptionFields: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'startDate',
          label: 'Start Date',
          tdClass: 'text-nowrap'
        },
        {
          key: 'dosageInstructions',
          label: 'Instructions',
        },
        {
          key: 'price',
          label: 'Price',
          tdClass: 'text-nowrap'
        }
      ]
    }
  },
  computed: {
    creationDate: function () {
      return moment(this.order.creationDate).local().format('Do MMMM YYYY, HH:mm');
    },
    dateOfBirth: function () {
      return moment(this.order.patient.dateOfBirth).format('DD MMM YYYY');
    },
    price: function () {
      return '£' + this.order.price;
    },
    homePhone: function () {
      return this.order.patient.preferences && this.order.patient.preferences.homePhone ? this.order.patient.preferences.homePhone : 'Not set';
    },
    google: function() {
      return gmapApi;
    }
  },
  mounted: function () {
    this.getOrderDetails();
    OrderEventBus.$on('getOrderDetails', () => {
      this.getOrderDetails();
    })
  },
  beforeDestroy () {
    OrderEventBus.$off();
  },
  watch: {
    gpSearch(query){
      this.getGPList(query);
    }
  },
  methods: {
    async setupGPtoPatient(){
      const patientPayload = {
        ...this.patient,
        gp: this.selectedGP,
        // The backend reqquires the password field to be present with a value.
        password: 'qwerty',
        username: this.patient.email,
        preferences: {
          ...this.patient.preferences,
          gpPractice: this.selectedGP.organizationName,
          gpAddress: JSON.stringify(this.selectedGP.address)
        },
      }

      delete patientPayload.displayName;
      await this.$patientService.updatePatient(patientPayload);
      this.getOrderDetails();
    },
    async addGP() {
      const gpAddResult = await this.$gpService.addGP(this.gp);
      this.selectedGP = gpAddResult;
      this.setupGPtoPatient();
      this.clearGP();
    },
    clearGP() {
        this.gp = {
            odsCode: "",
            organizationID: "",
            organizationName: "",
            organisationTypeID: "",
            organisationType: "",
            url: "",
            phone: "",
            fax: "",
            email: "",
            address: {
                line1: "",
                line2: "",
                county: "",
                city: "",
                postcode: "",
            },
        };
    },
    async updateGPContact(){
      const gpPayload = {
        ...this.patient.gp,
      }

      if (this.gpUpdateFieldType === 'email') {
        gpPayload.email = this.gpField;
      } else {
        gpPayload.fax = this.gpField;
      }

      try {
        await this.$gpService.updateGP(this.patient.gp?.id, gpPayload);
      } catch (error) {
        this.$awn.alert(error.response.data.message, 'Error');
      }

      this.clearGPContact();  
      this.getOrderDetails();
    },
    clearGPContact(){
      this.gpUpdateFieldType = null
      this.gpField = ''
      this.showGPUpdateModal = false
    },
    async onClickShareNotesWithGP(){
      try {
        this.$awn.tip(`Please wait. Sending ${this.patient.gp.email ? 'email' : 'fax'} to GP`);
        await this.$gpService.shareNotes(this.order?.id, this.patient.gp.email ? 'email' : 'fax');
        this.$awn.info('Operation finished.');
      } catch (error) {
        this.$awn.alert(error.response.data.message, 'Error');
      }
      await this.getOrderDetails();
    },
    async getOrderDetails () {
      const orderResult = await this.$orderService.getOrder(this.$route.params.orderId);
      this.order = orderResult;
      this.deliveryOnly = this.order.deliveryType == 'HOME' ? true : false;

			if (orderResult.type == 'CHAT_ASSESSMENT') {
				try {
					const gpNoteResult = await this.$orderService.getChatGPNotes(orderResult.id);
					this.gpNote = gpNoteResult;
				} catch (error) {
					this.handleError(error);
				}

				try {
					const sickNoteResult = await this.$orderService.getChatSickNotes(orderResult.id);
					this.sickNote = sickNoteResult;
				} catch (error) {
					this.handleError(error);
				}

				try {
					const refferalLetterResult = await this.$orderService.getChatReferrelLetter(orderResult.id);
					this.refferalLetter = JSON.parse(refferalLetterResult.text);
				} catch (error) {
					this.handleError(error);
				}
			} else {
				if (orderResult.appointment) {
					try {
						const gpNoteResult = await this.$orderService.getGPNotes(orderResult.appointment.id);
						this.gpNote = gpNoteResult;
					} catch (error) {
						this.handleError(error);
					}

					try {
						const sickNoteResult = await this.$orderService.getSickNotes(orderResult.appointment.id);
						this.sickNote = sickNoteResult;
					} catch (error) {
						this.handleError(error);
					}

					try {
						const refferalLetterResult = await this.$orderService.getReferrelLetter(orderResult.appointment.id);
						this.refferalLetter = JSON.parse(refferalLetterResult.text);
					} catch (error) {
						this.handleError(error);
					}
				}
			}
			
      const patientResult = await this.$patientService.getPatient(orderResult.patient?.id);
      this.patient = patientResult;

      if (!this.patient.gp) {
        await this.getGPList();
      }

      if (this.order.pharmacy) {
        this.postcode = this.order.pharmacy.address ? this.order.pharmacy.address.postcode : '';
        this.pharmacyId = this.order.pharmacy?.id;
      }

      if(this.$route.params.orderId) {
        const prescriptionResult = await this.$orderService.getPrescription(this.$route.params.orderId);
        if (prescriptionResult.medication) {
          prescriptionResult.medication.forEach(m => {
            for (var property in m.formulary) {
              if (Object.prototype.hasOwnProperty.call(m.formulary, property)) {
                m[property] = m.formulary[property];
              }
            }
          })
        }
        this.prescription = prescriptionResult;
      }

      this.findPharmacies()
    },
    async getGPList(query){
      const gpResult = await this.$gpService.search(query, this.currentPatientPage);
      this.gpList = gpResult.content;
      this.totalGPPages = gpResult.totalPages;
    },
    handleError(error){
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
      } else if (error.request) {
        // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
      } else {
        // Something happened in setting up the request that triggered an Error
        this.displayNotification('error', error.message)
      }
    },
    openUpdateGPModal(field){
      this.gpUpdateFieldType = field;
      this.showGPUpdateModal = true;
    },
    getGPContactNumber(phone){
      if (!phone) {
        return 'N/A'
      }
      return '+44' + phone.slice(1)
    },
    getGPAddress(address){
      const line1 = address.line1 ? `${address.line1},` : '';
      const line2 = address.line2 ? `${address.line2},` : '';
      const county = address.county ? `${address.county},` : '';
      const city = address.city ? `${address.city}` : '';
      return `${line1} ${line2} ${county} ${city}`;
    },
    isSelectedGP(item){
      return item?.id === this.selectedGP?.id;
    },
    displayNotification(type, message) {
      if(type === 'error') {
          this.$awn.alert(message, 'Error');
          this.$router.push('/login')
      }
    },
    dateTime(date) {
      return moment(date).local().format('Do MMMM YYYY, HH:mm');
    },
    findPharmacies() {
      this.showMap = false;
      this.findPharmacyButtonClicked = true;
      this.getLatLan(`${this.postcode},UK`).then(location => {
        this.postCodeLocation = location;
        if (location) {
          publicService.getNearestPharmacies(location.lat(), location.lng(), 5, this.deliveryOnly, this.memberType).then(pharmacies => {
            this.pharmacies = pharmacies;
            if (this.pharmacies.length > 2) {
              const maxLat = this.pharmacies.map(p => p.latitude).reduce((l1, l2) => Math.max(l1, l2));
              const maxLng = this.pharmacies.map(p => p.longitude).reduce((l1, l2) => Math.max(l1, l2));
              const minLat = this.pharmacies.map(p => p.latitude).reduce((l1, l2) => Math.min(l1, l2));
              const minLng = this.pharmacies.map(p => p.longitude).reduce((l1, l2) => Math.min(l1, l2));

              this.bounds = {
                  south: minLat,
                  west: minLng,
                  north: maxLat,
                  east: maxLng
              };
            } else {
              // if (this.pharmacies.length === 0) {
              //     this.showNoPharmaciesModal = true;
              // }

              const r = 0.5;
              this.bounds = {
                south: location.lat() - r,
                west: location.lng() - r,
                north: location.lat() + r,
                east: location.lng() + r
              };
            }
            this.showMap = true;
            this.$nextTick(() => {
              if(this.$refs.map) {
                this.$refs.map.$mapPromise.then((map) => {
                  map.fitBounds(this.bounds);
                });
              }
            });
          });
        } else {
          this.pharmacies = [];
        }
      });
    },
    async getLatLan(address) {
      await this.$gmapApiPromiseLazy();
      const google = this.google();
      return await new Promise((resolve, reject) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': address }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
              resolve(results[0].geometry.location);
          } else {
              reject(status);
          }
        });
      })
    },
    selectPharmacy(p) {
      this.pharmacyId = p?.id;
    },
    reset() {
      this.getOrderDetails()
    },
    confirm() {
      this.confirmModal = false;
      let deliveryType = this.deliveryOnly ? 'HOME' : 'PHARMACY';
      this.$orderService.setDeliveryDetails(this.order?.id, this.pharmacyId, deliveryType)
        .then(() => {
          this.$router.push('/orders/waiting-for-admin')
          this.$awn.success('Delivery details confirmed successfully')
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.$awn.alert(error.response.data.message);
          } else {
            this.$awn.alert('There was a network error');
          }
        });
    },
    addDeliveryNotes() {
      this.$orderService.addDeliveryNotes(this.order?.id, this.order.workflow.PHARMACY_DELIVER_NOTES).then(() => {
        this.$awn.success("Delivery notes added successfully to order " + this.order?.id);
        this.$router.push('/orders/all');
      }).catch(error => {
        this.$awn.alert(error, "Oops");
      });
    },
    handleAppointmentChange() {
      this.getOrderDetails();
    }
  }
}
</script>

<style lang="scss">
  #pharmacyIdDescription small {
    margin-top: -1.8%;
  }
  .collapsed > .when-opened, :not(.collapsed) > .when-closed {
    display: none;
  }

  .gp-list-container {
    row-gap: 1rem;

    .gp-item {
      padding: 0.5rem;
      border: 1px solid #d8d8d8;
      border-radius: 5px;
      text-align: left;
      cursor: pointer;

      &.selected {
        border: 2px solid #6eb65e !important;
      }

      p {
        margin: 0;
      }
    }
  }
</style>
