<template>
  <b-card>
    <div slot="header">
      <i class='fa fa-align-justify'></i> All Patients
    </div>
    <b-row>
      <b-col sm="12" md="6">
        <b-pagination size="md" :total-rows="patients.totalElements" v-model="currentPage" :per-page="perPage"></b-pagination>
      </b-col>
      <b-col sm="12" md="6" >
        <b-input-group size="md"  >

          <vue-bootstrap-typeahead
            :data="patientsList"
            v-model="searchQuery"
            placeholder="Type patient name"
            @hit="selectedQuery = $event"
          />

           <vue-bootstrap-typeahead
            :data="patientEmailList"
            v-model="emailSearchQuery"
            placeholder="Type patient email or Id"
            @hit="selectedEmailQuery = $event"
          />

          <b-input-group-append>
            <b-button block variant="primary" class="btn--2" :disabled="!(searchQuery || emailSearchQuery)" v-on:click="searchButton()"><i class="fa fa-search"></i></b-button>
          </b-input-group-append>

        </b-input-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-table :items="patients.content" :fields="columnNames" :current-page="1" :per-page="perPage" hover outlined show-empty responsive>
          <template v-slot:cell(externallyIdentified)="row">
            <b-form-checkbox block variant="primary" v-model="row.value" :disabled="true" />
          </template>
          <template v-slot:cell(email)="row">
            <a :href="'mailto:' + row.value" target="_blank">{{row.value}}</a>
          </template>
          <template v-slot:cell(actions)="data">
            <b-dropdown variant="primary" size="sm" center text="Action">
              <b-dropdown-item :to="{ path: '/patient/' + data.item.id}">View / Edit</b-dropdown-item>
              <b-dropdown-item :to="{ path: '/change-password/patient/' + data.item.id}">Change Password</b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" md="8">
        <b-pagination size="md" :total-rows="patients.totalElements" v-model="currentPage" :per-page="perPage"></b-pagination>
      </b-col>
      <b-col sm="12" md="4">
        <b-input-group size="md">
          <input type="search" class="form-control" id="searchQuery" v-model="searchQuery" @search="search()">
          <b-input-group-append>
            <b-button block variant="primary" class="btn--2" :disabled="!searchQuery" v-on:click="search()"><i class="fa fa-search"></i></b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <br>

  </b-card>
</template>

<script>

import moment from 'moment';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import * as _ from 'lodash';

export default {
  data: function () {
    return {
      searchQuery: '',
      selectedQuery: '',
      emailSearchQuery: '',
      selectedEmailQuery: '',
      patients: [],
      columnNames: ['id', { key: 'externallyIdentified', 'class': 'text-center' } , 'gender', 'displayName', 'email', 'phone', 'dateOfBirth', 'actions'],
      currentPage: 1,
      perPage: 10,
      patientsList: [],
      patientEmailList: [],
      selectedAddress: null
    }
  },
  filters: {
			dateTime: function (date) {
				return moment(date).local().format('HH:mm - DD/MM/YY');
      }
  },
  created () {
    this.search();
  },
  watch: {
    currentPage: function () {
      this.search();
    },
    searchQuery: _.debounce(function() { this.autocompleteSearch() }, 500),
    emailSearchQuery: _.debounce(function() { this.autocompleteEmailSearch() }, 500),
    selectedQuery: function() {
      this.search();
    },
    selectedEmailQuery: function() {
      this.searchByEmail();
    }

  },
  methods: {
    search () {
      this.$patientService.search(this.selectedQuery, this.currentPage - 1, this.perPage).then(patients => {
        patients.content = patients.content.map((patient) => {
          patient.dateOfBirth = moment(patient.dateOfBirth).format('DD MMM YYYY');
          return patient;
        });
        this.patients = patients;
        this.emailSearchQuery = '';
      }).catch(error => {
        this.$awn.alert(error, 'An error occurred')
      });
    },
    searchByEmail () {
      this.$patientService.searchByEmail(this.selectedEmailQuery, this.currentPage - 1, this.perPage).then(patients => {
        patients.content = patients.content.map((patient) => {
          patient.dateOfBirth = moment(patient.dateOfBirth).format('DD MMM YYYY');
          return patient;
        });
        this.patients = patients;
        this.searchQuery = '';
      }).catch(error => {
        this.$awn.alert(error, 'An error occurred')
      });
    },
    autocompleteSearch () {
      this.$patientService.search(this.searchQuery).then(patients => {
        this.patientsList = patients.content.map(p => `${p.firstName} ${p.lastName}`);
      }).catch(error => {
        this.$awn.alert(error, 'An error occurred')
      });
    },
    autocompleteEmailSearch () {
      this.$patientService.searchByEmail(this.emailSearchQuery).then(patients => {
        this.patientEmailList = patients.content.map(p => `${p.email}`);
      }).catch(error => {
        this.$awn.alert(error, 'An error occurred')
      });
    },
    clearSearch () {
      this.searchQuery = "";
      this.selectedQuery = "";
      this.selectedEmailQuery = "";
      this.emailSearchQuery = "";
      this.search();
    },
    searchButton(){
      if (this.searchQuery) {
        this.selectedQuery = this.searchQuery;
        this.search();
      } else if (this.emailSearchQuery) {
        this.selectedEmailQuery = this.emailSearchQuery;
        this.searchByEmail();
      }
    }
  },
  components: {
    VueBootstrapTypeahead
  }
}
</script>