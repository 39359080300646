
<script>

    const DoctorService = {}

    DoctorService.install = function (Vue) {
        
        var doctorService = new Object ();

        Object.defineProperty(Vue.prototype, '$doctorService', { value: doctorService });

        doctorService.search = function (searchQuery, page = 0, size = 10) {
            var url;
            if (searchQuery) {
                url = `/api/admin/doctor/search?q=${searchQuery}&page=${page}&size=${size}`;
            } else {
                url = `/api/admin/doctor/search?page=${page}&size=${size}`;
            }
            return Vue.axios.get(url)
            .then(response => { return response.data; })
            .catch(error => {
                this.handleError(error)
                return Promise.reject(error);
            });
        };

        doctorService.getAvailabilities = function (from, to) {
            var url = `/api/admin/doctor/appointment/list?from=${from}&to=${to}`;
            // var res = []
            return Vue.axios.get(url)
            .then(response => {
                // var appList = response.data[0].appointmentList;
                // appList.forEach(app => {
                //     if ((app.status === 'SCHEDULED') || (app.status === 'APPOINTMENT_COMPLETED') || (app.status === 'BOOKED') || (app.status === 'TREAT_IT_BOOKING')) {
                //         res.push(app)
                //     }
                // });
                // response.data[0].appointmentList = res;
                return response.data; 
            })
            .catch(error => {
                this.handleError(error)
                return Promise.reject(error);
            });
        };

        doctorService.updateAvailabilities = function (availabilities) {
            var url = `/api/admin/doctor/appointment/patch`;
            return Vue.axios.patch(url, availabilities)
            .then(response => { return response.data; })
            .catch(error => {
                this.handleError(error)
                return Promise.reject(error);
            });
        };

        doctorService.handleError = function(error) {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.log(error.response.data)
                console.log(error.response.status)
                console.log(error.response.headers)
            } else if (error.request) {
                // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
                // Vue.$router.push('/login')
                console.log('error.request ', error.request)
            } else {
                // Something happened in setting up the request that triggered an Error
                // Vue.$router.push('/login')
                console.log('error', error.request)
            }
        };
    }

    export default DoctorService;
</script>