<template>
  <div class="animated fadeIn common-color">
    <!-- <b-row>
      <b-col sm="6" lg="3">
        <b-card no-body style="background-color: #20a8d8; color: white">
          <b-card-body class="pb-0">
            <h4 class="mb-0">1.087</h4>
            <p>Total orders</p>
          </b-card-body>
          <single-line-chart class="chart-wrapper px-3" style="height:100px;" height="100" color="#20a8d8" :values="totalOrdersValues" :labels="totalOrdersLabels"/>
        </b-card>
      </b-col>
      <b-col sm="6" lg="3">
        <b-card no-body class="bg-info">
          <b-card-body class="pb-0">
            <h4 class="mb-0">2.191</h4>
            <p>Total Patients</p>
          </b-card-body>
          <single-line-chart class="chart-wrapper px-3" style="height:100px;" height="100" color="#63c2de" :values="totalPatientsValues" :labels="totalPatientsLabels"/>
        </b-card>
      </b-col>
      <b-col sm="6" lg="3">
        <b-card no-body class="bg-warning">
          <b-card-body class="pb-0">
            <h4 class="mb-0">1.087</h4>
            <p style="white-space: nowrap">Member vs Non-member</p>
          </b-card-body>
          <multiple-line-chart class="chart-wrapper px-3" style="height:100px;" :height="100"/>
        </b-card>
      </b-col>
      <b-col sm="6" lg="3">
        <b-card no-body class="bg-danger">
          <b-card-body class="pb-0">
            <h4 class="mb-0">£22.455,46</h4>
            <p>Total Revenue</p>
          </b-card-body>
          <single-line-chart class="chart-wrapper px-3" style="height:100px;" height="100" color="#f86c6b" :values="totalRevenueValues" :labels="totalRevenueLabels"/>
        </b-card>
      </b-col>
    </b-row> -->
  </div>
</template>

<script>

export default {
  name: 'dashboard',
  components: {
  },
  data: function () {
    return {
      totalOrdersValues: [17, 86, 125, 191, 198, 240, 98, 0],
      totalOrdersLabels: ['September', 'October', 'November', 'January', 'February', 'March', 'April'],
      totalPatientsValues: [35, 149, 264, 416, 393, 508, 189, 0],
      totalPatientsLabels: ['September', 'October', 'November', 'January', 'February', 'March', 'April'],
      totalRevenueValues: [407.32, 1755.1, 2221.55, 3672.14, 4127.24, 4905.67, 2068.19],
      totalRevenueLabels: ['September', 'October', 'November', 'January', 'February', 'March', 'April'],
    }
  }
}
</script>

<style lang="scss">
html body {
  background-color:#e4e5e6;
}
</style>
