
<script>
    const PrescriberService = {}

    PrescriberService.install = function (Vue) {

        var prescriberService = new Object ();

        Object.defineProperty(Vue.prototype, '$prescriberService', { value: prescriberService });

        prescriberService.search = function (searchQuery, page = 0, size = 10) {
            var url = `/api/admin/company/search?q=${searchQuery}&page=${page}&size=${size}&sort=id,DESC`
            return Vue.axios.get(url)
                .then(response => { return response.data; })
                .catch(error => {
                    this.handleError(error)
                    return Promise.reject(error);
            });
        };

        prescriberService.getPrescriber = function (id) {
            var url = `/api/admin/company/${id}`;
            return Vue.axios.get(url)
                .then(response => { return response.data; })
                .catch(error => {
                    this.handleError(error);
                    return Promise.reject(error);
            });
        };

        prescriberService.getPrescriberDoctors = function (id, page = 0, size = 10) {
            var url = `/api/admin/doctor/search/by-company?company=${id}&page=${page}&size=${size}&sort=id,DESC`;
            return Vue.axios.get(url)
                .then(response => { return response.data; })
                .catch(error => {
                    this.handleError(error);
                    return Promise.reject(error);
            });
        };

        prescriberService.createPrescriber = function (prescriber) {
            var url = `/api/admin/company`;
            delete prescriber.id;
            return Vue.axios.post(url, prescriber)
                .then(response => { return response.data; })
                .catch(error => {
                    this.handleError(error);
                    return Promise.reject(error);
            });
        };

        prescriberService.createPrescriberDoctor = function (doctor) {
            var url = `/api/admin/doctor`;
            delete doctor.id;
            return Vue.axios.post(url, doctor)
                .then(response => { return response.data; })
                .catch(error => {
                    this.handleError(error);
                    return Promise.reject(error);
            });
        };

        prescriberService.updatePrescriberDoctor = function (doctor) {
            var url = `/api/admin/doctor`;
            return Vue.axios.put(url, doctor)
                .then(response => { return response.data; })
                .catch(error => {
                    this.handleError(error);
                    return Promise.reject(error);
            });
        };

        prescriberService.updatePrescriber = function (prescriber) {
            var url = `/api/admin/company/change`;
            return Vue.axios.put(url, prescriber)
                .then(response => { return response.data; })
                .catch(error => {
                    this.handleError(error);
                    return Promise.reject(error);
            });
        };

        prescriberService.handleError = function(error) {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.log(error.response.data)
                console.log(error.response.status)
                console.log(error.response.headers)
            } else if (error.request) {
                // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
                // Vue.$router.push('/login')
                console.log('error.request ', error.request)
            } else {
                // Something happened in setting up the request that triggered an Error
                // Vue.$router.push('/login')
                console.log('error', error.request)
            }
        };

        prescriberService.uploadFile = function (file, path) {
            const formData = new FormData()
            formData.append('file', file)
            formData.append('path', path)
            var url = '/api/admin/files/upload'
            return Vue.axios.post(url, formData)
            .catch(error => {
                this.handleError(error)
                return Promise.reject(error)
            })
        }
    }

    export default PrescriberService;
</script>