<template>
  <b-card>

    <div slot="header">
      <i class='fa fa-align-justify'></i> {{title}}
    </div>
    
    <div v-if="orders && orders.content">
      <b-row>
        <b-col sm="12" md="8">
          <b-pagination v-if="orders.content.length" align="left" size="md" :total-rows="orders.totalElements" v-model="currentPage" :per-page="orders.size" :first-text="'First'" :prev-text="'Prev'" :next-text="'Next'" :last-text="'Last'"></b-pagination>
        </b-col>
        <b-col sm="12" md="4">
          <div style="display: flex; flex-direction: row; justify-content: flex-end;">
            <vue-bootstrap-typeahead v-if="prescriberSearch" :data="prescribers" :serializer="s => s.name" placeholder="Filter by prescriber" :minMatchingChars="1" v-model="prescriberName" @hit="prescriberChanged($event)" :style="{width: prescriberSearch? '49%' : '100%', 'margin-right': '2%'}"/>
              <div class="input-group" :style="{width: prescriberSearch? '49%' : '100%'}">
                <input type="search" placeholder="Enter Order ID" class="form-control" id="orderIdInput" v-model="orderIdInput" @search="orderSearch()">
              </div>
          </div>
        </b-col>
      </b-row>
      <b-table hover :items="orders.content" :fields="columnNames" :current-page="1" :per-page="orders.size" outlined show-empty responsive>
        <template v-slot:cell(status)="row"><span class="smaller">{{row.value | orderStatus}}</span></template>
        <template v-slot:cell(consultationType)="row">
          <span v-if="row.item.type != 'BOOKING'">-</span>
          <span v-else> {{row.item.mentalHealthPlatform | appointmentType }}</span>
        </template>
        <template v-slot:cell(name)="row">
          <span v-if="row.item.type !== 'BOOKING'">{{row.value}}</span>
          <span v-else>Consultation {{row.item.appointment ? row.item.appointment.datetime : consultationDatetime | datetime }}</span></template>
        <template v-slot:cell(patient)="row">
          <router-link :to="{path: '/patient/' + row.value.id }">
              {{row.value.id}} - {{row.value.displayName}} 
          </router-link>
        </template>
        <template v-slot:cell(platform)="row">{{row.value}} </template>
        <template v-slot:cell(doctor)="row">
          <span v-if="row.value">{{row.value.id}} - {{row.value.displayName}}</span>
          <span v-else>N/A</span>
        </template>
        <template v-slot:cell(pharmacy)="row">
          <router-link v-if="row.value" :to="{path: '/pharmacy/' + row.value.id }">
              {{row.value.id}} - {{row.value.displayName}} 
          </router-link>
          <span v-else>N/A</span>
        </template>
        <template v-slot:cell(creationDate)="row">{{row.value | datetime}} </template>
        <template v-slot:cell(actions)="row">
          <span v-html="action" @click="sendToParent(row.item.id)"></span>
        </template>
      </b-table>
      <b-pagination v-if="orders.content.length"  align="center" size="md" :total-rows="orders.totalElements" v-model="currentPage" :per-page="orders.size" :first-text="'First'" :prev-text="'Prev'" :next-text="'Next'" :last-text="'Last'"></b-pagination>
      <br>
    </div>
    <div v-else>
      <div class="loadingOrders">Loading...</div>
      </div>
  </b-card>
</template>

<script>
import moment from 'moment'
import OrderEventBus from '../EventBus.js'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';

export default {
  name: 'orders-table',
  components: {
    VueBootstrapTypeahead
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    prescriberSearch: {
      type: Boolean,
      default: false
    },
    prescribers: {
      default: () => []
    },
    orders: {
      required: true,
      default: () => {}
    },
    columnNames: {
      default: () => ['id', 'creationDate', 'status', 'consultationType', 'name', 'patient', 'platform', 'doctor', 'pharmacy', 'actions']
    },
    page: {
      type: Number,
      default: 1,
    },
    action: {
      type: String,
      default: '',
    },
    clickEvent: {
      type: String,
      default: ''
    },
    searchAction: {
      type: String,
      default: ''
    },
    redStyled: {
      default: function () {
        return ['PAYMENT_FAILED', 'CANCELED_BY_DOCTOR', 'CANCELED_BY_PHARMACY', 'REJECTED_BY_PHARMACY', 'REJECTED_BY_DOCTOR', 'CANCELED_BY_ADMIN']
      }
    }
  },
  watch: {
    orders: {
      handler: function (_orders) { 
        // style rows
        if (_orders) {
          _orders.content.forEach(order => {
            if (order.status === 'DELIVERED') {
              order._rowVariant = 'success';
            }
            if (this.redStyled.indexOf(order.status) !== -1) {
              order._rowVariant = 'danger';
            }
          });
        }
      }
    },
    currentPage: function () {
      OrderEventBus.$emit('currentPage', this.currentPage);
    },
    selectedPrescriber: function(prescriber) {
      this.$emit("prescriber-changed", prescriber);
    },
    prescriberName: function(name) {
      if (this.selectedPrescriber && !name) {
        this.selectedPrescriber = null;
      }
    }
  },
  data: function () {
    return {
      currentPage: this.page,
      orderIdInput: "",
      prescriberName: '',
      selectedPrescriber: null
    }
  },
  filters: {
    datetime: function (date) {
      return moment(date).local().format('HH:mm - DD/MM/YY');
    },
    consultationDatetime: function (date) {
      return moment(date).local().format('(DD/MM/YY HH:mm)');
    }
  },
  methods: {
    sendToParent(orderId) {
      OrderEventBus.$emit(this.clickEvent, orderId);
    },
    orderSearch(){
      OrderEventBus.$emit(this.searchAction, this.orderIdInput);
    },
    prescriberChanged(prescriber) {
      this.selectedPrescriber = prescriber;
    }
  }
}
</script>

<style lang="scss">

.smaller{
    font-size: x-small;
}

.loadingOrders,
.loadingOrders:before,
.loadingOrders:after {
  border-radius: 100%;
  width: 1.5em;
  height: 1.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 2.2s infinite ease-in-out;
  animation: load7 2.2s infinite ease-in-out;
}
.loadingOrders {
  color: #009344;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loadingOrders:before,
.loadingOrders:after {
  content: '';
  position: absolute;
  top: 0;
}
.loadingOrders:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loadingOrders:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

</style>
