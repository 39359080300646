import Vue from 'vue';

Vue.filter('orderStatus', value => {
  switch (value) {
    case 'WAITING_FOR_PHARMACY_APPROVAL':
      return 'Sent to Pharmacy';
    case 'REJECTED_BY_PHARMACY':
      return 'Rejected by Pharmacy';
    case 'CANCELED_BY_PHARMACY':
      return 'Canceled by Pharmacy';
    case 'PAYMENT_FAILED':
      return 'Payment Failed';
    case 'APPROVED_BY_PHARMACY':
      return 'Dispensed';
    case 'PROPOSED_BY_DOCTOR':
      return 'Awaiting Patient Approval';
    case 'DELIVERED':
      return 'Complete';
    case 'BOOKED':
      return 'Booked';
    case 'WAITING_FOR_DOCTOR_APPROVAL':
      return 'Awaiting Doctor Approval';
    case 'WAITING_FOR_PATIENT_DELIVERY_DETAILS':
      return 'Awaiting Patient Delivery Details';
    case 'WAITING_FOR_DOCTOR_PRESCRIPTION_COMPLETION':
      return 'Awaiting Doctor Prescription Completion';
    case 'WAITING_FOR_ADMIN_DELIVERY_DETAILS':
      return 'Awaiting Admin Delivery Details';
    case 'AWAITING_ADMIN_ACTION_REJECTED_BY_PHARMACY':
      return 'Rejected by pharmacy - Awaiting Admin Action'
    case 'CANCELED_BY_PATIENT':
      return 'Cancelled by Patient';
    case 'DRAFT_CANCELLED_BY_PATIENT':
      return 'Draft Cancelled by Patient';
    case 'DRAFT':
      return 'Draft';
    case 'RESERVED_BY_DOCTOR':
      return 'In Progress';
    case 'REJECTED_BY_DOCTOR':
      return 'Rejected by Doctor';
    case 'REJECTED_BY_PATIENT':
      return 'Rejected by Patient';
    case 'ONGOING':
      return 'Ongoing';
    case 'APPOINTMENT_COMPLETED':
      return 'Appointment Completed';
    case 'CANCELED_BY_DOCTOR':
      return 'Cancelled by Doctor';
    case 'CANCELED_BY_ADMIN':
      return 'Cancelled by Admin'
    default:
      return value;
  }
});