<script>
const GPService = {};

GPService.install = function(Vue) {
    var gpService = new Object();

    Object.defineProperty(Vue.prototype, "$gpService", { value: gpService });

    gpService.search = function(searchQuery, page = 0, size = 10) {
        var url;
        if (searchQuery) {
            url = `/api/admin/gp?q=${searchQuery}&page=${page}&size=${size}`;
        } else {
            url = `/api/admin/gp?page=${page}&size=${size}`;
        }
        return Vue.axios
            .get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    gpService.getGP = function(id) {
        const url = `/api/admin/gp/${id}`;
        return Vue.axios
            .get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    gpService.addGP = function(formData) {
        var url = `/api/admin/gp`;
        return Vue.axios
            .post(url, formData)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    gpService.updateGP = function(id, formData) {
        var url = `/api/admin/gp/${id}`;
        return Vue.axios
            .put(url, formData)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    gpService.deleteGP = function(id) {
        var url = `/api/admin/gp/${id}`;
        return Vue.axios
            .delete(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    gpService.shareNotes = function(orderId, type) {
        const method = type === 'email' ? "EMAIL" : "FAX";
        var url = `/api/admin/order-item/${orderId}/doctor-note?method=${method}`;
        return Vue.axios
            .post(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                this.handleError(error);
                return Promise.reject(error);
            });
    };

    gpService.handleError = function(error) {
        if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
            console.log("error.request ", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("error", error.request);
        }
    };
};

export default GPService;
</script>
