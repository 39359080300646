export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer'
    },
    {
      title: true,
      name: 'Entities',
      class: '',
      wrapper: {
        element: '',
        attributes: {}
      }
    },
    {
      name: 'Admins',
      url: '/admins',
      icon: 'fa fa-shield'
    },
    {
      name: 'Appointments',
      url: '/appointments',
      icon: 'fa fa-calendar'
    },
    {
      name: 'Patients',
      url: '/patients',
      icon: 'fa fa-users',
    },
    {
      name: 'Orders',
      icon: 'fa fa-shopping-cart',
      children: [
        {
          name: 'All orders',
          url: '/orders/all',
          icon: 'fa fa-tasks'
        },
        {
          name: 'GPS orders',
          url: '/orders/gps',
          icon: 'fa fa-tasks'
        },
        {
          name: 'DocNow orders',
          url: '/orders/docnow',
          icon: 'fa fa-tasks'
        },
        {
          name: 'Prescriber orders',
          url: '/orders/prescriber',
          icon: 'fa fa-tasks'
        },
        {
          name: 'Waiting for admin',
          url: '/orders/waiting-for-admin',
          icon: 'fa fa-pause'
        },
        {
          name: 'Change delivery',
          url: '/orders/change-delivery',
          icon: 'fa fa-truck'
        },
        {
          name: 'Rejected by pharmacy',
          url: '/orders/rejected-by-pharmacy',
          icon: 'fa fa-exclamation-triangle'
        },
        {
          name: 'Payment failed',
          url: '/orders/payment-failed',
          icon: 'fa fa-times-circle'
        },
      ]
    },
    {
      name: 'Coupons',
      icon: 'fa fa-percent',
      url: '/coupons'
    },
    {
      name: 'Enterprises',
      icon: 'fa fa-percent',
      url: '/enterprises'
    },
    {
      name: 'Platforms',
      icon: 'fa fa-percent',
      url: '/platforms'
    },
    {
      name: 'Subscriptions',
      icon: 'fa fa-credit-card',
      url: '/subscriptions'
    },
    {
      name: 'Packages',
      icon: 'fa fa-archive',
      url: '/packages'
    },
    {
      name: 'GP Notes',
      icon: 'fa fa-address-card',
      url: '/gp-notes'
    },
    {
      name: 'Pharmacies',
      url: '/pharmacies',
      icon: 'fa fa-ambulance'
    },
    {
      name: 'Prescribers',
      icon: 'fa fa-medkit',
      url: '/prescribers'
    },
    {
      name: 'Doctors',
      url: '/doctors',
      icon: 'fa fa-heartbeat',
    },
    {
      name: "GPs",
      url: "/gps",
      icon: "fa fa-heartbeat",
    },
    {
      name: 'Formulary',
      icon: 'fa fa-medkit',
      url: '/formulary'
    },
    {
      name: 'Form Formulary',
      icon: 'fa fa-medkit',
      url: '/form-formulary'
    },
  ]
}
